import { AxiosResponse } from "axios";

const getCountedData = <T>(
  response: AxiosResponse<T>,
): {
  data: T;
  parcelsCount: number;
  seasonsCount: number;
} => ({
  data: response.data,
  parcelsCount: response.headers ? response.headers["x-total-count"] : 0,
  seasonsCount: response.headers
    ? response.headers["x-seasons-total-count"]
    : 0,
});

export { getCountedData };
