import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { getMonitoringCropIntervalsIsFetching } from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getCropIntervals,
  getIndex,
  getSelectedIntervalId,
} from "../../selectors/monitoring.selectors";

const useLocking = (isInitCustomCondition: boolean) => {
  const cropIntervals = useSelector(getCropIntervals);
  const selectedIntervalId = useSelector(getSelectedIntervalId);
  const index = useSelector(getIndex);

  const [isInit, setIsInit] = useState(false);
  const [cropsHasBeenCalled, setCropsHasBeenCalled] = useState(false);
  const isFetchingCropIntervals = useSelector(
    getMonitoringCropIntervalsIsFetching,
  );

  useEffect(() => {
    if (isFetchingCropIntervals && !cropsHasBeenCalled) {
      setCropsHasBeenCalled(true);
    }
  }, [cropsHasBeenCalled, isFetchingCropIntervals]);

  useEffect(() => {
    const cropsAreInit =
      cropsHasBeenCalled && !isFetchingCropIntervals && cropIntervals.length;

    if (!isInit && cropsAreInit && isInitCustomCondition) {
      setIsInit(true);
    }
  }, [
    cropIntervals,
    isInit,
    isFetchingCropIntervals,
    index,
    selectedIntervalId,
    isInitCustomCondition,
    cropsHasBeenCalled,
  ]);

  return {
    isInit,
  };
};

export { useLocking };
