import React, { useEffect, useState, useMemo } from "react";

import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Field, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import * as validators from "../../../../../shared/misc/validators";
import Localization from "../../../../../shared/services/Localization.service";
import UnitService from "../../../../../shared/services/Unit.service";

import {
  ActionOtherFormValues,
  AmountSelectorUnit,
} from "../../actionOther.types";

interface Props {
  actionTotalArea: number;
  isEditing: boolean;
}

const SowingAmountSelector = ({ actionTotalArea, isEditing }: Props) => {
  const [dirtyInput, setDirtyInput] = useState("");
  const {
    setFieldValue,
    values: { totalAmount, unitId },
  } = useFormikContext<ActionOtherFormValues>();
  const { locale } = useTypedIntl();

  const updateFormValues = (value: string) => {
    // this is triggered on value change.
    // Computation is skipped for all values that cannot be properly converted to numbers.
    const isValidValue = Localization.checkValidStr(value, locale);

    if (!isValidValue) {
      setFieldValue(dirtyInput, value);
      return;
    }
    // convert localization-formatted value strings to numbers
    const normalizedVal = Localization.str2numNonFixed(value, locale);
    const normalizedTotalArea = Localization.str2numNonFixed(
      actionTotalArea,
      locale,
    );

    if (dirtyInput === "totalAmount") {
      const hectarAmount =
        (normalizedVal as number) / (normalizedTotalArea as number);
      setFieldValue(
        "totalAmount",
        Localization.num2strNonFixed(normalizedVal, locale),
      );
      setFieldValue(
        "hectarAmount",
        Localization.num2str(hectarAmount, locale, 3),
      );
    } else if (dirtyInput === "hectarAmount") {
      const totalAmount =
        (normalizedVal as number) * (normalizedTotalArea as number);
      setFieldValue(
        "hectarAmount",
        Localization.num2strNonFixed(normalizedVal, locale),
      );
      setFieldValue(
        "totalAmount",
        Localization.num2str(totalAmount, locale, 3),
      );
    }
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateFormValues(value);
  };

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyInput(e.currentTarget.name);
  };

  useEffect(() => {
    if (actionTotalArea && totalAmount) {
      const normalizedTotalAmount = Localization.str2numNonFixed(
        totalAmount,
        locale,
      );
      const normalizedTotalArea = Localization.str2numNonFixed(
        actionTotalArea,
        locale,
      );
      const hectarAmount =
        (normalizedTotalAmount as number) / (normalizedTotalArea as number);
      setFieldValue(
        "hectarAmount",
        Localization.num2str(hectarAmount, locale, 3),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, actionTotalArea, locale]);

  const localizedNumberValidator = useMemo(
    () => validators.localizedNumber(locale),
    [locale],
  );

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={1}
    >
      <Grid item xs={9}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!actionTotalArea || !isEditing}
            label={<FormattedMessage id="action.total-amount" />}
            name="totalAmount"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!actionTotalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={3}>
        {units.length > 0 && (
          <CfFormControl>
            <Field
              component={CfFormikTextField}
              disabled={!isEditing}
              label=" "
              name="unitId"
              select
              validate={validators.required}
              validateOnBlur
            >
              {units.map((unit: AmountSelectorUnit) => (
                <MenuItem key={unit.id} value={unit.name}>
                  {unit.name}
                </MenuItem>
              ))}
            </Field>
          </CfFormControl>
        )}
      </Grid>
      <Grid item xs={9}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!actionTotalArea || !isEditing}
            label={<FormattedMessage id="action.amount-per-hectare" />}
            name="hectarAmount"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!actionTotalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={3}>
        <span>{unitId}/ha</span>
      </Grid>
    </Grid>
  );
};

export { SowingAmountSelector };

const units = UnitService.getUnitsForSowing().filter(
  (item: { id: string; name: string }) => item.id !== "g",
);
