import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./plantProtection.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { PlantProtectionApplicationCreateTo } from "../../../../../generated/api/agroevidence";

import { getPlantProtectionParams } from "./plantProtection.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";
import {
  PlantProtectionPatchTo,
  PlantProtectionCreateUpdateTo,
} from "../../agroevidence.types";

export const getPlantProtectionsApi = (params: getPlantProtectionParams) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/overview?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_REQUEST,
      types.GET_PLANT_PROTECTIONS_SUCCESS,
      types.GET_PLANT_PROTECTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionsSuggestionsApi = (
  params: getPlantProtectionParams,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/suggestions?${queryString.stringify(
      params,
    )}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_REQUEST,
      types.GET_PLANT_PROTECTIONS_SUCCESS,
      types.GET_PLANT_PROTECTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionsApi = () => ({
  type: types.RESET_PLANT_PROTECTIONS,
});

export const getPlantProtectionDetailApi = (plantProtectionId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/detail?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_REQUEST,
      types.GET_PLANT_PROTECTION_SUCCESS,
      types.GET_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionApi = () => ({
  type: types.RESET_PLANT_PROTECTION,
});

export const copyPlantProtectionApi = (
  plantProtectionId: string,
  registrationsId?: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/copy?${
      registrationsId ? `registration-ids=${registrationsId}&` : ""
    }`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.COPY_PLANT_PROTECTION_REQUEST,
      types.COPY_PLANT_PROTECTION_SUCCESS,
      types.COPY_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const patchPlantProtectionApi = (
  plantProtectionId: string,
  params: PlantProtectionPatchTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const updatePlantProtectionApi = (
  plantProtectionId: string,
  params: PlantProtectionCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionApi = (
  params: PlantProtectionCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: "catalogues/plant-protections?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_REQUEST,
      types.CREATE_PLANT_PROTECTION_SUCCESS,
      types.CREATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionDetailApplicationsApi = (
  plantProtectionId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/detail/applications?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_APPLICATIONS_REQUEST,
      types.GET_PLANT_PROTECTION_APPLICATIONS_SUCCESS,
      types.GET_PLANT_PROTECTION_APPLICATIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionDetailApplicationApi = (
  plantProtectionId: string,
  params: PlantProtectionApplicationCreateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/applications?`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.CREATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const updatePlantProtectionDetailApplicationApi = (
  plantProtectionId: string,
  applicationId: string,
  params: PlantProtectionApplicationCreateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const deletePlantProtectionDetailApplicationApi = (
  plantProtectionId: string,
  applicationId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.DELETE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.DELETE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const getActiveSubstancesApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/active-substances?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ACTIVE_SUBSTANCES_REQUEST,
      types.GET_ACTIVE_SUBSTANCES_SUCCESS,
      types.GET_ACTIVE_SUBSTANCES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetActiveSubstancesApi = () => ({
  type: types.RESET_ACTIVE_SUBSTANCES,
});

export interface ParcelsApiParams {
  search: string;
  "is-used"?: boolean;
  "valid-only"?: boolean;
}

export const getOrganismsApi = (params: ParcelsApiParams) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/organisms?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ORGANISMS_REQUEST,
      types.GET_ORGANISMS_SUCCESS,
      types.GET_ORGANISMS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetOrganismsApi = () => ({
  type: types.RESET_ORGANISMS,
});

export const getBioFunctionsApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/bio-function?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_BIO_FUNCTION_REQUEST,
      types.GET_BIO_FUNCTION_SUCCESS,
      types.GET_BIO_FUNCTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetBioFunctionApi = () => ({
  type: types.RESET_BIO_FUNCTION,
});

export const getPlantProtectionApplicationApi = (
  plantProtectionId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protections/${plantProtectionId}/applications?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_POR_APPLICATIONS_REQUEST,
      {
        type: types.GET_POR_APPLICATIONS_SUCCESS,
        meta: { plantProtectionId },
      },
      types.GET_POR_APPLICATIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const removeApplication = (id?: string) => ({
  type: types.REMOVE_POR_APPLICATION,
  meta: {
    id,
  },
});
