import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getSelectedIntervalId } from "../../selectors/monitoring.selectors";

import CfSwitcher from "../../../../shared/components/common/CfSwitcher/CfSwitcher";
import { MonitoringCropIntervals } from "../../containers/BioMonitoring";

interface Props {
  cropIntervals: MonitoringCropIntervals[];
  onMenuItemClick: (
    dateFrom: string,
    dateTo: string,
    selectedId: string,
  ) => void;
}

const CropIntervalSwitcher = ({ cropIntervals, onMenuItemClick }: Props) => {
  const classes = useStyles();
  const selectedIntervalId = useSelector(getSelectedIntervalId);

  return (
    <CfSwitcher
      arrowsShift={true}
      disabled={!cropIntervals.length}
      getItemId={(item) => item.id}
      getItemValue={(item) => item.id}
      isEmptyPlaceholder={<FormattedMessage id="common.noCrop" />}
      items={cropIntervals}
      onMenuItemClick={(item) => onMenuItemClick(item.from, item.to, item.id)}
      selectedItemId={selectedIntervalId}
      testId="crop-interval-switcher"
      getItemViewValue={(item) => (
        <span>
          <span className={classes.dateInterval}>
            <FormattedDate month="short" value={item.from} year="numeric" />
            {" \u2013 "}
            {item.to ? (
              <FormattedDate month="short" value={item.to} year="numeric" />
            ) : (
              <FormattedMessage id="common.now" />
            )}
          </span>{" "}
          <span className={classes.cropName}>
            {item.crop?.name ?? <FormattedMessage id="common.noCrop" />}
          </span>
        </span>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cropName: {
    color: theme.palette.grey[500],
    display: "inline-block",
  },
  dateInterval: {
    textTransform: "lowercase",
    marginRight: 6,
  },
}));

export default CropIntervalSwitcher;
