import React, { useEffect, useState } from "react";

import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Formik, Form, FieldArray } from "formik";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getErrorDetailedMessage,
  getIsFetchingReport,
  getPorErrorDetailMessage,
} from "../../../../shared/api/agroevidence/reports/reports.selectors";
import { getNoContentReports } from "../../selectors/reports.selectors";

import {
  clearNoContentReport,
  getReports,
  exportSowingPlan,
  sendFertilizerUsageReport,
  sendPorUsageReport,
} from "../../actions/reports.actions";

import {
  FertilizerUsageReportParams,
  PorUsageReportParams,
} from "../../../../shared/api/agroevidence/reports/reports.api";
import { DateRangeSelectorReports } from "../../../../shared/components/common/DateRangeSelector/DateRangeSelectorReports";
import PageHeader from "../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import FormSwitch from "../../../../shared/components/form/FormSwitch/FormSwitch";
import { useToggle } from "../../../../shared/hooks/useToggle";
import FertilizerUsageReport from "../../components/FertilizerUsageReport/FertilizerUsageReport";
import NoContentReportMessage from "../../components/NoContentReportMessage/NoContentReportMessage";
import { PorUsageReport } from "../../components/PorUsageReport";
import { ReportOrganicFertilizer } from "../../components/ReportOrganicFertilizer.";
import ReportOrganicNitrogen from "../../components/ReportOrganicNitrogen/ReportOrganicNitrogen";
import { ReportsHistoryDialog } from "../../components/ReportsHistory";
import ReportTypesTs from "../../components/ReportTypes/ReportTypes";
import SowingPlanCsvExport from "../../components/SowingPlanCsvExport/SowingPlanCsvExport";
import ReportsParcelsControl from "../ReportsParcelsControl/ReportsParcelsControl";

import { useReportsStyles } from "./style";
import { ReportsFormValues } from "./types";

import { DeprecatedFarmTo } from "../../../../shared/api/agroevidence/agroevidence.types";

const PDF = "pdf";
const XLS = "xls";

type Props = {
  farm: DeprecatedFarmTo;
};

export const Reports = ({ farm }: Props) => {
  const classes = useReportsStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isFetchingReports = useSelector(getIsFetchingReport);
  const noContentReports = useSelector(getNoContentReports);
  const errorDetailedMessage = useSelector(getErrorDetailedMessage);
  const porErrorDetailedMessage = useSelector(getPorErrorDetailMessage);

  const [exportedType, setExportedType] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const {
    on: isReportDialogOpen,
    setOff: handleReportDialogClose,
    setOn: handleReportDialogOpen,
  } = useToggle();

  const exportLabel = formatMessage({ id: "common.export" });

  const initialValue: ReportsFormValues = {
    from: moment().startOf("year"),
    to: moment(),
    parcels: [],
    categories: {},
    allParcels: false,
  };

  useEffect(() => {
    dispatch(clearNoContentReport());

    return () => {
      dispatch(clearNoContentReport());
    };
  }, [dispatch]);

  const onSubmit = (values: typeof initialValue) => {
    setExportedState(exportedType);
    return getReports(values, exportedType)(dispatch);
  };

  const setExportedState = (type: string) => {
    setExportedType(type);
  };

  const validate = (values: typeof initialValue) => {
    const errors: Record<string, string | React.ReactNode> = {};

    if (
      !values.parcels ||
      (values.parcels?.length === 0 && !values.allParcels)
    ) {
      errors.parcels = <div />;
    }

    if (
      !values.categories ||
      Object.keys(values.categories).length === 0 ||
      !Object.values(values.categories)
        .map((cat) => Object.values(cat))
        .reduce((acc, val) => acc.concat(val), [])
        .find((val) => val === true)
    ) {
      errors.categories = <div />;
    }

    if (!values.from) {
      errors.from = <FormattedMessage id="validation.required" />;
    }

    return errors;
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          className={classes.reportButton}
          id="reports-history"
          onClick={handleReportDialogOpen}
          startIcon={<TextSnippetOutlinedIcon />}
          variant="contained"
        >
          <FormattedMessage id="button.reportHistory" />
        </Button>
        <div className={classes.content}>
          <PageHeader
            classes={{ header: classes.header }}
            heading={
              <PageHeading
                dataTest="reports-heading"
                translationId="common.reports"
              />
            }
          />
          <div>
            <Formik
              initialValues={initialValue}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <FormLabel
                        className={classes.labelDateRangeSelector}
                        component="legend"
                      >
                        <FormattedMessage id="Reports.dateRangeSelector.label" />
                      </FormLabel>
                    </Grid>
                    <Grid item>
                      <CfFormControl>
                        <DateRangeSelectorReports
                          dateFrom={values.from}
                          dateTo={values.to}
                          setDate={(dateFrom, dateTo) => {
                            setFieldValue("from", dateFrom);
                            setFieldValue("to", dateTo);
                          }}
                        />
                      </CfFormControl>
                    </Grid>
                  </Grid>
                  <FormSwitch
                    customClasses={classes}
                    id="allParcels"
                    label={<FormattedMessage id="Reports.all" />}
                    labelPlacement="start"
                    name="allParcels"
                  />
                  {!values.allParcels && (
                    <FieldArray
                      name="parcels"
                      render={(arrayHelpers) => (
                        <ReportsParcelsControl
                          arrayHelpers={arrayHelpers}
                          filter={undefined}
                          hasSubmitted={hasSubmitted}
                          name={arrayHelpers.name}
                        />
                      )}
                    />
                  )}
                  <ReportTypesTs
                    hasSubmitted={hasSubmitted}
                    name="categories"
                  />
                  <Grid
                    alignItems="center"
                    className={classes.buttons}
                    container
                    justifyContent="center"
                    spacing={0}
                  >
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={exportedType === PDF && isFetchingReports}
                      id="pdf"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        setExportedState(PDF);
                        setHasSubmitted(true);
                      }}
                    >
                      {`${exportLabel} ${PDF}`}
                      {isFetchingReports && exportedType === PDF && (
                        <CircularProgress
                          className={classes.progress}
                          size={21}
                        />
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={exportedType === XLS && isFetchingReports}
                      id="xls"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        setExportedState(XLS);
                        setHasSubmitted(true);
                      }}
                    >
                      {`${exportLabel} ${XLS}`}
                      {isFetchingReports && exportedType === XLS && (
                        <CircularProgress
                          className={classes.progress}
                          size={21}
                        />
                      )}
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
            {farm.customer.countryCode === "CZ" && (
              <>
                <Divider variant="fullWidth" />
                <ReportOrganicNitrogen />
                <Divider variant="fullWidth" />
                <SowingPlanCsvExport
                  isFetching={isFetchingReports}
                  onCsvExport={() => dispatch(exportSowingPlan())}
                />
                <Divider variant="fullWidth" />
                <FertilizerUsageReport
                  errorDetailedMessage={errorDetailedMessage}
                  farmId={farm.id}
                  sendFertilizerUsageReportToEagri={(
                    params: FertilizerUsageReportParams,
                  ) => dispatch(sendFertilizerUsageReport(params))}
                />
                <Divider variant="fullWidth" />
                <PorUsageReport
                  errorDetailedMessage={porErrorDetailedMessage}
                  sendPorUsageReportToEagri={(params: PorUsageReportParams) =>
                    dispatch(sendPorUsageReport(params))
                  }
                />
                <Divider variant="fullWidth" />
                <ReportOrganicFertilizer />
              </>
            )}
          </div>
        </div>
        {noContentReports.length > 0 && (
          <NoContentReportMessage
            clearNoContentReports={() => dispatch(clearNoContentReport())}
            noContentReports={noContentReports}
          />
        )}
      </div>
      {isReportDialogOpen && (
        <ReportsHistoryDialog
          isOpen={isReportDialogOpen}
          onClose={handleReportDialogClose}
        />
      )}
    </>
  );
};
