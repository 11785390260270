import React from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";

import {
  EquipmentSortColumn,
  EquipmentTo,
} from "../../../generated/api/telematics";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { equipmentsQuery } from "../TelematicsAdvancedFilter/EquipmentSelector/EquipmentSelector.api";

type Props = {
  customClasses?: Record<string, string>;
  dateFrom: string;
  dateTo: string;
  disabled: boolean;
  helperText?: React.ReactElement | string;
  label?: React.ReactNode;
  onChange(item: EquipmentTo): void;
  placeholder?: string;
  selected: { name?: string; code: string };
  hasError?: boolean;
};

const TelematicsDetailEquipmentSelector = ({
  customClasses,
  dateFrom,
  dateTo,
  disabled,
  hasError,
  helperText,
  label,
  onChange,
  placeholder,
  selected,
}: Props) => {
  const classes = useStyles();
  const equipments = useSuspenseQuery(
    equipmentsQuery({
      ...useFarmIds(),
      dateTo,
      dateFrom,
      "sort-col": EquipmentSortColumn.NAME,
      "sort-dir": "asc",
    }),
  );

  let selectedEquipment = { code: "", name: "" };

  if (selected) {
    const option = equipments.data.data.find(
      (option) => option.code === selected.code,
    );

    selectedEquipment = {
      code: option?.code ?? selected.code,
      name: option?.name ?? selected.name ?? "",
    };
  }

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={selectedEquipment}
      disabled={disabled}
      error={hasError}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-equipment-selector"
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      suggestions={equipments.data.data}
      testId="telematics-detail-equipment-selector"
      renderOption={(option) => (
        <div>
          <div>{option.name ?? option.code}</div>
          <div className={classes.id}>{option.code}</div>
        </div>
      )}
    />
  );
};

const handleGetSelected = (option: EquipmentTo, value?: EquipmentTo | null) =>
  option.code === value?.code;

const handleGetLabel = (option?: EquipmentTo | null) => {
  if (!option) return "";
  if (option.name) return `${option.name} (${option.code})`;
  return option.code;
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

export { TelematicsDetailEquipmentSelector };
