import React, { useCallback } from "react";

import TableBody from "@mui/material/TableBody";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getTelematicsMachinesOrder,
  getTelematicsMachinesOrderBy,
  getTelematicsMachinesPage,
  getTelematicsMachinesRowsPerPage,
  getTelematicsMachinesTextFilter,
} from "../../../../selectors/telematicsMachines.selectors";
import { selectDateRange } from "../../../../selectors/telematicsTabs.selectors";

import { setAdvancedFilter } from "../../../../../shared/actions/filter.actions";
import { setDateRange } from "../../../../actions/telematicsTabs.actions";

import { NAMESPACE as telListNamespace } from "../../../../reducer/telematicsList.reducer";
import { NAMESPACE } from "../../../../reducer/telematicsMachines.reducer";

import DoubleLinedHeader from "../../../../../catalogues/components/telematics/shared/TCDoubleLinedHeader";
import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import {
  LogbookAggregatedSortColumn,
  MachineCategoryCode,
  MachineGroupCode,
} from "../../../../../generated/api/telematics";
import { useFarmIds } from "../../../../../shared/api/client.utils";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../shared/misc/helper";
import { TelematicsNgProps } from "../../../../containers/Telematics/Telematics";
import { machinesQuery } from "../../Machines.api";

import MachineRow from "./MachineRow";

const columns = {
  [LogbookAggregatedSortColumn.DURATION]: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsMachines.list.date"
      secondaryId="TelematicsMachines.list.duration"
    />,
  ),
  "machine.name": getColDesc(
    true,
    <FormattedMessage id="TelematicsAggregations.list.machine" />,
  ),
  "machine.operation": getColDesc(
    false,
    <FormattedMessage id="TelematicsMachines.list.cultivated" />,
    { width: 100 },
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsMachines.list.distance" />,
    { width: 100 },
  ),
  time: getColDesc(
    false,
    <FormattedMessage id="TelematicsMachines.list.time" />,
  ),
};

const Table = ({ ngRedirectToMainMapWithFilters }: TelematicsNgProps) => {
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(NAMESPACE);
  const { onSelect, onSort } = useCfTableHead(NAMESPACE);
  const dispatch = useDispatch();
  const order = useSelector(getTelematicsMachinesOrder);
  const orderBy = useSelector(getTelematicsMachinesOrderBy);
  const dateFilter = useSelector(selectDateRange);
  const page = useSelector(getTelematicsMachinesPage);
  const rowsPerPage = useSelector(getTelematicsMachinesRowsPerPage);
  const textFilter = useSelector(getTelematicsMachinesTextFilter);
  const machines = useQuery(
    machinesQuery({
      page: page + 1,
      "per-page": rowsPerPage,
      "sort-col": orderBy,
      "sort-dir": order,
      machine: textFilter,
      ...dateFilter,
      ...useFarmIds(),
    }),
  );

  const logbookFilterHandler = useCallback(
    (
      dateFrom: string,
      dateTo: string,
      machine: Record<"name" | "gpsUnit", string>,
    ) => {
      dispatch(setDateRange(dateFrom, dateTo));
      dispatch(
        setAdvancedFilter(
          {
            machine: [
              {
                name: machine.name,
                validFrom: "",
                gpsUnit: machine.gpsUnit,
                category: MachineCategoryCode.AJE,
                group: MachineGroupCode.TRUCK,
              },
            ],
          },
          telListNamespace,
        ),
      );
    },
    [dispatch],
  );

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {machines.status === "pending" || machines.fetchStatus === "fetching" ? (
        <CfTableBodyLoader columns={columns} />
      ) : null}
      {machines.status === "success" && machines.data.data.length > 0 ? (
        <>
          <TableBody>
            {machines.data.data.map((m, i) => (
              <MachineRow
                data={m}
                dateFilter={dateFilter}
                key={i}
                logbookFilterHandler={logbookFilterHandler}
                ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
                rowId={i}
              />
            ))}
          </TableBody>
          <CfTableFooter
            count={machines.data?.count}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </>
      ) : null}
      {machines.status === "success" && machines.data.data.length === 0 ? (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      ) : null}
    </CfTableWrapper>
  );
};

export { Table };
