import React from "react";

import { Theme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { ExportButton } from "../../../../../shared/components/common/ExportButton/ExportButton";
import usePopover from "../../../../../shared/hooks/usePopover";

const formats = ["pdf", "xlsx"];

interface Props {
  handleExport: (format: string, storeId: number) => void;
  storeId: number;
}

const StoreExport = ({ handleExport, storeId }: Props) => {
  const classes = useStyles();
  const popover = usePopover();
  return (
    <div className={classes.storeExport}>
      <ExportButton onClick={popover.handlePopoverOpen} />
      <Menu
        anchorEl={popover.anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={popover.handlePopoverClose}
        open={popover.isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {formats.map((format) => (
          <MenuItem
            key={format}
            onClick={() => {
              handleExport(format, storeId);
              popover.handlePopoverClose();
            }}
          >
            {format.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  storeExport: {
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    marginLeft: 15,
  },
}));

export { StoreExport };
