import React, { useState } from "react";

import { Theme, Button, IconButton, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { FormattedMessage } from "react-intl";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { NewSeasonForm } from "./NewSeasonForm";
import { parcelDashboardQuery } from "./ParcelDashboard.api";

import NoPrecision from "../../../core/precision/containers/NoPrecision";
import { useFarmIds } from "../../../shared/api/client.utils";

import classNames from "classnames";
import { useTablePaging } from "./components/table/useTablePaging";
import { Table } from "./components/table/Table";
import { TableFooter } from "./components/table/TableFooter";

const hasPages = (totalCount: number, perPageCount: number) =>
  Math.ceil(totalCount / perPageCount) > 1;

const isOffsetEnd = (
  totalCount: number,
  currentOffset: number,
  perPageCount: number,
): boolean => {
  const currentIsLast = currentOffset + perPageCount >= totalCount;
  return currentIsLast;
};

type Props = {
  langId: LANGUAGE_ID;
  showListing: () => void;
};

const Dashboard = ({ langId, showListing }: Props) => {
  const { farmIds } = useFarmIds();
  const classes = useStyles();
  const toolbarClasses = useToolbarStyles();

  const hasPrecision = true;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [textFilter, setTextFilter] = useState("");

  const {
    seasonsPerPage,
    seasonsOffset,
    setSeasonsOffset,
    parcelsPage,
    parcelsPerPage,
    setParcelsPage,
    setParcelsPerPage,
  } = useTablePaging();

  const {
    data: dashboard,
    isFetching,
    isLoading,
    error,
  } = useQuery(
    parcelDashboardQuery({
      farmIds,
      "per-page": parcelsPerPage,
      "sort-dir": "asc",
      page: parcelsPage + 1,
      search: textFilter,
      "seasons-offset": seasonsOffset,
      "seasons-per-page": seasonsPerPage,
    }),
  );

  const inProgress = isFetching || isLoading;
  const dashboardData = dashboard?.data;
  const parcelsCount = dashboard?.parcelsCount ?? 0;
  const seasonsCount = dashboard?.seasonsCount ?? 0;

  const toggleDrawer =
    (open: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setParcelsPerPage(newRowsPerPage);
    setParcelsPage(0);
  };

  let Jsx = null;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  } else if (inProgress) {
    Jsx = <CfLoader />;
  } else {
    const hasColumnPages =
      !isFetching && hasPages(seasonsCount, seasonsPerPage);
    const isLastSeasonPage = isOffsetEnd(
      seasonsCount,
      seasonsOffset,
      seasonsPerPage,
    );

    const dashboardParcels = dashboardData?.parcels ?? [];
    const dashboardSeasons = dashboardData?.seasons ?? [];
    const noParcelsForSeasons = !!(
      dashboardSeasons.length && !dashboardParcels.length
    );

    Jsx = (
      <div>
        <div className={toolbarClasses.header}>
          <Button
            onClick={showListing}
            size="large"
            style={{ fontSize: 24, paddingLeft: 0 }}
            variant="text"
          >
            <span className={toolbarClasses.button}>
              <KeyboardArrowLeftIcon sx={{ mr: 1 }} />
              <FormattedMessage id="PrecisionFarming.seasons.backToList" />
            </span>
          </Button>
          <div className={toolbarClasses.toolbar}>
            <CfTextFilter
              initialValue={textFilter}
              name="precision-list-text-filter"
              onChange={setTextFilter}
              translId="PrecisionFarming.seasons.table.filter"
            />
            <Button
              color="secondary"
              onClick={toggleDrawer(true)}
              size="small"
              style={{ minHeight: 48 }}
              variant="outlined"
            >
              <span className={toolbarClasses.button}>
                <AddIcon sx={{ mr: 1 }} />
                <FormattedMessage id="PrecisionFarming.seasons.addSeason.openButton" />
              </span>
            </Button>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <Table
            dashboardParcels={dashboardParcels}
            dashboardSeasons={dashboardSeasons}
            noParcelsForSeasons={noParcelsForSeasons}
            isFetching={inProgress}
            prevIcon={
              <IconButton
                onClick={() => setSeasonsOffset((prev) => prev - 1)}
                disabled={!seasonsOffset}
                size="small"
                className={classNames({
                  [classes.arrowButton]: true,
                  [classes.hasNoColumnPagesArrow]: !hasColumnPages,
                  [classes.noParcelsForSeasonsArrow]: noParcelsForSeasons,
                })}
              >
                <WestIcon htmlColor={"#FFFFFF"} />
              </IconButton>
            }
            nextIcon={
              <IconButton
                onClick={() => setSeasonsOffset((prev) => prev + 1)}
                className={classNames({
                  [classes.arrowButton]: true,
                  [classes.hasNoColumnPagesArrow]: !hasColumnPages,
                })}
                disabled={isLastSeasonPage}
                size="small"
              >
                <EastIcon htmlColor={"#FFFFFF"} />
              </IconButton>
            }
          />
          <div className={classes.footerContainer}>
            <TableFooter
              count={parcelsCount}
              page={parcelsPage}
              rowsPerPage={parcelsPerPage}
              setPage={setParcelsPage}
              setRowsPerPage={handleRowsPerPageChange}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <CfErrorPage error={error ?? undefined}>
      <div className={classes.wrapper}>
        {Jsx}
        <NewSeasonForm
          open={isDrawerOpen}
          onCloseClick={toggleDrawer(false)}
          page={parcelsPage}
        />
      </div>
    </CfErrorPage>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",
    position: "relative",
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  footerContainer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
  arrowButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
    transform: "translateY(100%)",
    borderRadius: "100%",
    backgroundColor: alpha("#00A179", 0.8),
    "&:hover": {
      backgroundColor: alpha("#00A179", 0.65),
    },
  },
  noParcelsForSeasonsArrow: {
    left: 0,
    right: "unset",
  },
  hasNoColumnPagesArrow: {
    display: "none",
  },
}));

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 1,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    textTransform: "none",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    gap: 10,
  },
}));

export { Dashboard };
