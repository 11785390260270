import React, { useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getIsFetchingPlantProtection,
  getPlantProtectionDetail,
} from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";
import { getStoreAmounByMaterial } from "../../../../shared/api/stores/stores/stores.selectors";

import {
  resetPlantProtection,
  fetchPlantProtection,
  fetchPlantProtectionApplications,
  fetchStoreByMaterial,
} from "../../../actions/catalogues.actions";

import { CATALOGUES_URLS } from "../../../catalogues.constants";

import {
  copyPlantProtectionApi,
  createPlantProtectionApi,
  patchPlantProtectionApi,
  updatePlantProtectionApi,
} from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useWidth from "../../../../shared/hooks/useWidth";
import { CfFormikErrors, CfFormikProps } from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import Buttons from "../../shared/Buttons";

import { ActiveSubstancesControl } from "./ActiveSubstancesControl/ActiveSubstancesControl";
import { ApplicationsTable } from "./Applications/ApplicationsTable";
import { AuthorizationHolders } from "./components/AuthorizationHolders";
import { GeneralSectionEditMode } from "./components/GeneralSectionEditMode";
import { GeneralSectionNonEditMode } from "./components/GeneralSectionNonEditMode";
import { PlantProtectionDetailHeader } from "./components/PlantProtectionDetailHeader";
import { StoreAmount } from "./components/StoreAmount";
import {
  initialEmptyValues,
  mapInitialValues,
  mapRequestBodyCreateUpdatePlantProtection,
} from "./PlantProtectionDetail.services";
import { usePlantProtectionDetailStyles } from "./PlantProtectionDetail.styles";

import { PlantProtectionDetailFormValues } from "./PlantProtectionDetail.types";
import {
  PlantProtectionDetailTo,
  PlantProtectionRegistrationDataTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isNew: boolean;
}

const PlantProtectionDetail = ({ isNew }: Props) => {
  const classes = usePlantProtectionDetailStyles();
  const history = useHistory();
  const width = useWidth();
  const { farmId } = useContext(CataloguesContext);
  const { plantProtectionId } = useParams<{ plantProtectionId: string }>();
  const showSnackbar = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const plantProtection = useSelector(getPlantProtectionDetail);
  const isFetching = useSelector(getIsFetchingPlantProtection);
  const storeAmount = useSelector(getStoreAmounByMaterial);

  const [isEditing, setIsEditing] = useState(isNew);

  useEffect(() => {
    dispatch(resetPlantProtection());
    if (!isNew) {
      dispatch(fetchPlantProtection(plantProtectionId));
      dispatch(fetchPlantProtectionApplications(plantProtectionId));
      dispatch(fetchStoreByMaterial(plantProtectionId));
    }
  }, [plantProtectionId, isNew, dispatch]);

  const initialValues = useMemo(() => {
    if (isNew || !plantProtection) return initialEmptyValues;

    return mapInitialValues(plantProtection);
  }, [plantProtection, isNew]);

  const handleFavoriteClick = () => {
    (
      dispatch(
        patchPlantProtectionApi(plantProtectionId, {
          isFavorite: !plantProtection?.isFavorite,
        }),
      ) as unknown as Promise<unknown>
    ).then(() => dispatch(fetchPlantProtection(plantProtectionId)));
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleResetForm = () => {
    setIsEditing(false);
  };

  const handleSubmit = (values: PlantProtectionDetailFormValues) => {
    const data = mapRequestBodyCreateUpdatePlantProtection(values);
    if (isNew) {
      (
        dispatch(createPlantProtectionApi(data)) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction<PlantProtectionDetailTo>) => {
        if (!res.error && values.isFavorite) {
          const newPlantProtectionId = res.payload.id;
          dispatch(
            patchPlantProtectionApi(newPlantProtectionId as string, {
              isFavorite: true,
            }),
          );
        }
        handleResponse(res, true);
      });
    } else {
      (
        dispatch(
          updatePlantProtectionApi(plantProtectionId, data),
        ) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction<PlantProtectionDetailTo>) =>
        handleResponse(res),
      );
      setIsEditing(false);
    }
  };

  const handleResponse = (
    res: RSAAResultAction<PlantProtectionDetailTo>,
    newAction = false,
  ) => {
    if (res.error) {
      showSnackbar({
        message: (
          <FormattedMessage
            id={`Catalogues.plantProtection.${
              newAction ? "createError" : "updateError"
            }`}
          />
        ),
        isError: true,
      });
    } else {
      showSnackbar({
        message: (
          <FormattedMessage id="Catalogues.plantProtection.create.update.success" />
        ),
        isSuccess: true,
      });
      if (newAction)
        history.push(
          `/farm/${farmId}/catalogues/plantProtection/${res.payload.id}`,
        );
    }
  };

  const handleCopyPlantProtection = (
    registrations: PlantProtectionRegistrationDataTo[],
  ) => {
    const registrationsId = registrations.map((r) => r.id)?.join(",");
    (
      dispatch(
        copyPlantProtectionApi(plantProtectionId, registrationsId),
      ) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction<PlantProtectionDetailTo>) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.copy.error" />
          ),
          isError: true,
        });
      } else {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.copy.success" />
          ),
          isSuccess: true,
        });
        history.push(
          `/farm/${farmId}/catalogues/plantProtection/${res.payload.id}`,
        );
      }
    });
  };

  const handleGoBack = () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS.plantProtection}`);
  };

  const validate = (values: PlantProtectionDetailFormValues) => {
    const errors: CfFormikErrors<PlantProtectionDetailFormValues> = {};
    if (!values.name) {
      errors.name = "validation.required";
    }

    if (!values.bioFunction) {
      errors.bioFunction = "validation.required";
    }

    if (!values.usableUntil) {
      errors.usableUntil = <FormattedMessage id="validation.required" />;
    }

    return errors;
  };

  const isMobile = width === "xs" || width === "sm";
  const canUpdate = isNew || plantProtection?.catalogue.canUpdate;

  if (!plantProtection && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Formik<Partial<PlantProtectionDetailFormValues>>
      enableReinitialize
      initialValues={initialValues}
      onReset={handleResetForm}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        setFieldValue,
        values,
      }: CfFormikProps<PlantProtectionDetailFormValues>) => {
        const handleChangeName = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue("name", event.target.value);
        };

        const handleChangeFavoriteOnCreate = () => {
          setFieldValue("isFavorite", !values.isFavorite);
        };

        const handleChangeCheckbox = (
          event: React.ChangeEvent<HTMLInputElement>,
          name: string,
        ) => {
          setFieldValue(name, event.target.checked);
        };

        const handleChangeBioFunction = (bioFunction: string) => {
          setFieldValue("bioFunction", bioFunction);
        };

        const renderSectionHeading = (messageId: string) => (
          <Grid data-test="sectionHeading" item xs={12}>
            <Typography className={classes.sectionHeading} variant="h5">
              <FormattedMessage id={messageId} />
            </Typography>
          </Grid>
        );

        return (
          <Form>
            <div className={classes.body}>
              <Grid className={classes.headWrapper} container>
                <PlantProtectionDetailHeader
                  backButtonLabel="Catalogues.plantProtection.detail.backToPlantProtection"
                  canUpdate={canUpdate}
                  defaultIsFavorite={values.isFavorite}
                  defaultName={values.name}
                  error={!!errors.name}
                  handleCopyPlantProtection={handleCopyPlantProtection}
                  handleGoBack={handleGoBack}
                  handleStartEdit={handleStartEdit}
                  isEditing={isEditing}
                  itemNamePlaceholder="Catalogues.plantProtection.detail.name"
                  onChange={handleChangeName}
                  registrations={plantProtection?.registrations}
                  handleFavoriteClick={
                    isNew ? handleChangeFavoriteOnCreate : handleFavoriteClick
                  }
                />
              </Grid>
              <Grid className={classes.wrapper} container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: 8 }}
                  xs={12}
                >
                  <FormattedMessage
                    id={`Catalogues.detail.shared.source.${
                      canUpdate ? "custom" : "eAgri"
                    }`}
                    values={{
                      b: (chunks: string) => (
                        <b style={{ marginLeft: 10 }}>{chunks}</b>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={9} xs={12}>
                  {renderSectionHeading(
                    "Catalogues.plantProtection.detail.generalSection",
                  )}
                  <Paper className={classes.paper}>
                    {isEditing ? (
                      <GeneralSectionEditMode
                        errors={errors}
                        handleChangeBioFunction={handleChangeBioFunction}
                        handleChangeCheckbox={handleChangeCheckbox}
                        isEditing={isEditing}
                        isMobile={isMobile}
                        values={values}
                      />
                    ) : (
                      <GeneralSectionNonEditMode
                        isMobile={isMobile}
                        values={values}
                      />
                    )}
                  </Paper>
                </Grid>
                <Grid item lg={6} md={9} xs={12}>
                  {renderSectionHeading(
                    "Catalogues.plantProtection.detail.activeSubstancesSection",
                  )}
                  <Paper className={classes.paper}>
                    <FieldArray
                      name="activeSubstances"
                      render={(arrayHelpers) => (
                        <ActiveSubstancesControl
                          arrayHelpers={arrayHelpers}
                          isEditing={isEditing}
                          name={arrayHelpers.name}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
                {!isEditing && !canUpdate && (
                  <Grid item lg={6} md={9} xs={12}>
                    {renderSectionHeading(
                      "Catalogues.plantProtection.detail.authorizationHolders",
                    )}
                    <AuthorizationHolders
                      handleCopyPlantProtection={handleCopyPlantProtection}
                      isEditing={isEditing}
                      registrations={plantProtection?.registrations}
                    />
                  </Grid>
                )}
                <Grid item lg={6} md={9} xs={12}>
                  {renderSectionHeading("common.stores")}
                  <Paper className={classes.paper}>
                    <StoreAmount storeAmount={storeAmount} />
                  </Paper>
                </Grid>
                {isEditing && <Buttons isNew={isNew} />}
                {!isNew && (
                  <Grid item xs={12}>
                    {renderSectionHeading(
                      "Catalogues.plantProtection.detail.applicationsSection",
                    )}
                    <ApplicationsTable
                      canUpdate={canUpdate}
                      isEditing={isEditing}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { PlantProtectionDetail };
