import React, { PropsWithChildren } from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AxiosError, isAxiosError } from "axios";
import { FormattedMessage } from "react-intl";

import errorPage40x from "../../../../assets/img/error-page-404.svg";
import errorPage500 from "../../../../assets/img/error-page-500.svg";
import errorPage503 from "../../../../assets/img/error-page-503.svg";
import { RsaaApiError } from "../../../../types";

const images = {
  0: "",
  404: errorPage40x,
  500: errorPage500,
  503: errorPage503,
};

type Props = {
  handle400s?: boolean;
  error40xHeadingTranslId?: string;
  error40xMessageTranslId?: string;
  error?: Partial<RsaaApiError> | Error;
};

type ErrorHandlerFn<E extends AxiosError | Partial<RsaaApiError>> = (
  error: E,
) => {
  is500: boolean;
  is503: boolean;
  is40x: boolean;
  code: number;
};

const createErrorResult = (
  is500: boolean,
  is503: boolean,
  is40x: boolean,
  code: number,
) => ({
  is500,
  is503,
  is40x,
  code,
});

const isError40x = (code: string) => /40[0-4]/.test(code);

const handleAxiosError: ErrorHandlerFn<AxiosError> = (error) => {
  const is500 = error.response?.status === 500;
  const is503 = error.response?.status === 503;
  const is40x = error.response?.status
    ? isError40x(error.response?.status.toString())
    : false;
  const code = error.response?.status ?? 0;
  return createErrorResult(is500, is503, is40x, code);
};

const handleRsaaApiError: ErrorHandlerFn<Partial<RsaaApiError>> = (error) => {
  const is500 = error.code === 500;
  const is503 = error.code === 503;
  const is40x = error.code ? isError40x(error.code.toString()) : false;
  const code = error.code ?? 0;
  return createErrorResult(is500, is503, is40x, code);
};

const CfErrorPage = ({
  children,
  error = {},
  error40xHeadingTranslId = "",
  error40xMessageTranslId = "",
  handle400s = false,
}: PropsWithChildren<Props>) => {
  const classes = useStyle();

  let result = createErrorResult(false, false, false, 0);
  if (Object.keys(error).length) {
    if (isAxiosError(error)) {
      result = handleAxiosError(error);
    } else {
      result = handleRsaaApiError(error as Partial<RsaaApiError>);
    }
  }

  const shouldCatch =
    result.is500 || result.is503 || (result.is40x && handle400s);

  return shouldCatch ? (
    <div className={classes.wrapper}>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <img
            alt={`Error ${result.code}`}
            className={classes.image}
            src={images[result.code as keyof typeof images]}
          />
        </Grid>
        <Grid item>
          <h1 className={classes.heading} data-test="error-title">
            <FormattedMessage
              id={
                result.is40x
                  ? error40xHeadingTranslId
                  : `error.heading.${result.code}`
              }
            />
          </h1>
        </Grid>
        {!result.is40x && (
          <Grid item style={{ textAlign: "center" }} xs={8}>
            <FormattedMessage id={`error.message.${result.code}`} />{" "}
            <a href="mailto:help@cleverfarm.cz">help@cleverfarm.cz</a>.
          </Grid>
        )}
        {result.is40x && (
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={0}
          >
            <Grid item style={{ textAlign: "center" }} xs={12}>
              <FormattedMessage id={error40xMessageTranslId} />
            </Grid>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => history.back()}
              size="medium"
              variant="contained"
            >
              <FormattedMessage id="common.back" />
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  ) : (
    <>{children}</>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  image: {
    width: 250,
    height: "auto",
  },
  heading: {
    marginBottom: 10,
  },
  button: {
    marginTop: 40,
  },
}));

export default CfErrorPage;
