import React from "react";

import { Box, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedTime } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getTelematicsListFocusedRow } from "../../../../../shared/api/telematics/drives/drives.selectors";
import { selectIsAggregated } from "../../../../selectors/telematicsList.selectors";

import { TELEMATICS_URLS } from "../../../../telematics.constants";

import {
  LogbookAggregatedItemTo,
  LogbookItemTo,
} from "../../../../../generated/api/telematics";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTextBadge from "../../../../../shared/components/misc/CfTextBadge/CfTextBadge";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import { useFocusedTableRowTarget } from "../../../../../shared/hooks/useFocusedTableRowTarget";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../../../../components/TelematicsForeignWarning/TelematicsForeignWarning";
import TelematicsNotSendInfo from "../../../../components/TelematicsNotSendInfo/TelematicsNotSendInfo";
import { getDriveBadgeProps } from "../../../../helpers";
import { isAggregatedDrive } from "../../helpers";

import { TelematicsState } from "../../../../../reducers/telematics.reducer.types";
import { SeedApplicationType } from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  Affiliation,
  State,
} from "../../../../../shared/api/telematics/telematics.types";

type Drive = (LogbookItemTo | LogbookAggregatedItemTo) & {
  badgeProps: ReturnType<typeof getDriveBadgeProps>;
  operationTooltip: string;
};
type CellProps = {
  drive: Drive;
};

type ReduxProps = Omit<ConnectedProps<typeof connector>, "dispatch">;
type OwnProps = {
  drive: Drive;
  isInit?: boolean;
};
type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: TelematicsState) => ({
  focusedRow: getTelematicsListFocusedRow(state),
  isAggregated: selectIsAggregated(state),
});

const RecordRow = ({ drive, focusedRow, isAggregated, isInit }: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<Record<"farmId", string>>();

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: drive.id.toString(),
  });

  const rowClickRedirectionUrl = (driveId: string) =>
    isAggregated
      ? {
          pathname: `/farm/${farmId}/${TELEMATICS_URLS.drivers}/${driveId}`,
          state: {
            backLink: `/farm/${farmId}/${TELEMATICS_URLS.logbook}`,
          },
        }
      : {
          pathname: `/farm/${farmId}/${TELEMATICS_URLS.logbookDetail(driveId)}`,
        };

  return (
    <TableRow
      className={classes.pointer}
      component={Link}
      hover
      ref={targetElementRef}
      to={rowClickRedirectionUrl(drive.id)}
      sx={{
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      {isAggregatedDrive(drive) ? (
        <DurationCell drive={drive} />
      ) : (
        <DateCell drive={drive} />
      )}
      <DriverCell drive={drive} />
      <OperationCell drive={drive} />
      <ProductionOperationCell drive={drive} />
      <ParcelCell drive={drive} />
      <CultivatedCell drive={drive} />
      <DistanceCell drive={drive} />
      <MachineCell drive={drive} />
      <AdditionalEquipmentCell drive={drive} />
    </TableRow>
  );
};

const DateCell = ({
  drive,
}: {
  drive: LogbookItemTo & Pick<Drive, "operationTooltip" | "badgeProps">;
}) => {
  const classes = useStyles();
  return (
    <CfTableCell name="from">
      <div className={classes.leftOffset}>
        <CfTextBadge
          backgroundColor={drive.badgeProps.backgroundColor}
          color={drive.badgeProps.color}
          fontSize={drive.badgeProps.fontSize}
          text={drive.badgeProps.text}
          tooltipText={drive.badgeProps.tooltip}
        />
        <div className={classes.cellMultipleRowsContainer}>
          <div className={classnames(classes.cellRow, classes.nowrap)}>
            <FormattedDate value={drive.from} />
          </div>
          <div
            className={classnames(
              classes.cellRow,
              classes.secondaryText,
              classes.nowrap,
            )}
          >
            <FormattedTime value={drive.from} /> -{" "}
            <FormattedTime value={drive.to} />
          </div>
        </div>
      </div>
    </CfTableCell>
  );
};

const DurationCell = ({
  drive,
}: {
  drive: LogbookAggregatedItemTo &
    Pick<Drive, "operationTooltip" | "badgeProps">;
}) => {
  const classes = useStyles();
  const duration = moment
    .duration(drive.duration * 1000, "millisecond")
    .format("h:mm", {
      trim: false,
    });

  return (
    <CfTableCell name="from">
      <div className={classes.leftOffset}>
        <CfTextBadge
          backgroundColor={drive.badgeProps.backgroundColor}
          color={drive.badgeProps.color}
          fontSize={drive.badgeProps.fontSize}
          text={drive.badgeProps.text}
          tooltipText={drive.badgeProps.tooltip}
        />
        <div className={classes.cellMultipleRowsContainer}>
          <div className={classnames(classes.cellRow, classes.nowrap)}>
            {duration}
          </div>
          <div
            className={classnames(
              classes.cellRow,
              classes.secondaryText,
              classes.nowrap,
            )}
          >
            <FormattedDate value={drive.dateFrom} />
          </div>
        </div>
      </div>
    </CfTableCell>
  );
};

const DriverCell = ({ drive }: CellProps) => {
  const classes = useStyles();
  return (
    <CfTableCell name="driver.name">
      <div className={classes.cellMultipleRowsContainer}>
        <div className={classes.cellRow}>
          {drive.driver?.name ?? drive.driver?.code ?? "-"}
          {drive.driver?.affiliation === Affiliation.EXTERNAL && (
            <TelematicsForeignWarning
              text={drive.driver?.companyName}
              type={TelematicsForeignWarningType.Driver}
            />
          )}
        </div>
        {drive.driver?.affiliation === Affiliation.COMPANY && (
          <div className={classnames(classes.cellRow, classes.secondaryText)}>
            {drive.driver?.code ?? "-"}
          </div>
        )}
      </div>
    </CfTableCell>
  );
};

const OperationCell = ({ drive }: CellProps) => {
  const classes = useStyles();
  return (
    <CfTableCell name="operation">
      <div className={classes.cellWithIcon}>
        <Tooltip title={drive.operationTooltip}>
          <div
            className={classnames({
              [classes.operation]: drive.state !== State.NOT_APPROVED,
              [classes.operationNotApproved]:
                drive.state === State.NOT_APPROVED,
              [classes.secondaryText]: drive.state === State.DEFERRED,
            })}
          >
            {drive.operation ?? "-"}
          </div>
        </Tooltip>
        {drive.state !== State.THIRD_PARTY_ACKNOWLEDGED && (
          <TelematicsNotSendInfo />
        )}
      </div>
    </CfTableCell>
  );
};

const ProductionOperationCell = ({ drive }: CellProps) => (
  <CfTableCell name="productionOperation.name">
    {drive?.productionOperation?.name ?? "-"}
  </CfTableCell>
);

const ParcelCell = ({ drive }: { drive: Drive }) => {
  const classes = useStyles();

  return (
    <CfTableCell name="parcelName">
      <div>
        {drive.parcel?.blockNumber ?? "-"}
        {drive.parcel?.blockNumber &&
          drive.parcel?.affiliation !== Affiliation.EXTERNAL && (
            <span>{` ${drive.parcel?.localName}` ?? "-"}</span>
          )}
      </div>
      {drive.parcel?.affiliation !== Affiliation.EXTERNAL && (
        <Box
          className={classes.secondaryText}
          display="flex"
          sx={{ height: 15 }}
        >
          <CropName cropType={drive.cropType as SeedApplicationType}>
            {drive.cropName ?? "-"}
          </CropName>
        </Box>
      )}
      {drive.parcel?.affiliation === Affiliation.EXTERNAL && (
        <TelematicsForeignWarning
          text={drive.parcel?.subjectName}
          type={TelematicsForeignWarningType.Parcel}
        />
      )}
    </CfTableCell>
  );
};

const CultivatedCell = ({ drive }: CellProps) => {
  const classes = useStyles();

  return (
    <CfTableCell name="cultivated">
      <div className={classes.cellMultipleRowsContainer}>
        <div className={classes.cellRow}>
          {drive.cultivated ? (
            <span>
              <CfFormattedNumber value={drive.cultivated} /> ha
            </span>
          ) : (
            "-"
          )}
        </div>
        <div className={classnames(classes.cellRow, classes.secondaryText)}>
          {drive.parcelArea ? (
            <span>
              <CfFormattedNumber value={drive.parcelArea} /> ha
            </span>
          ) : (
            "-"
          )}
        </div>
      </div>
    </CfTableCell>
  );
};

const DistanceCell = ({ drive }: CellProps) => (
  <CfTableCell name="traversedDistance">
    {drive.distance ? (
      <span>
        <CfFormattedNumber value={drive.distance} /> km
      </span>
    ) : (
      "-"
    )}
  </CfTableCell>
);

const MachineCell = ({ drive }: { drive: Drive }) => {
  const classes = useStyles();

  return (
    <CfTableCell name="machine.name">
      <div className={classes.cellMultipleRowsContainer}>
        <div className={classes.cellRow}>
          {drive.machine?.name ?? drive.machine?.gpsUnit ?? "-"}
        </div>
        <div className={classnames(classes.cellRow, classes.secondaryText)}>
          {drive.machine?.licencePlate ?? "-"}
        </div>
      </div>
      {drive.machine?.affiliation === Affiliation.EXTERNAL && (
        <TelematicsForeignWarning
          text={drive.machine?.companyName}
          type={TelematicsForeignWarningType.Machine}
        />
      )}
    </CfTableCell>
  );
};

const AdditionalEquipmentCell = ({ drive }: CellProps) => {
  const classes = useStyles();

  return (
    <CfTableCell name="additionalEquipment">
      <div className={classes.cellMultipleRowsContainer}>
        <div className={classes.cellRow}>
          {drive.equipment?.name ?? drive.equipment?.code ?? "-"}
        </div>
        {drive.equipment?.affiliation !== Affiliation.EXTERNAL && (
          <div className={classnames(classes.cellRow, classes.secondaryText)}>
            {drive.equipment?.code ?? "-"}
          </div>
        )}
        {drive.equipment?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={drive.equipment?.companyName}
            type={TelematicsForeignWarningType.AdditionalEquipment}
          />
        )}
      </div>
    </CfTableCell>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cellMultipleRowsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cellRow: {
    margin: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  cellWithIcon: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
  leftOffset: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
  },
}));

const connector = connect(mapStateToProps);
export default connector(RecordRow);
