import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import invariant from "tiny-invariant";

import { JwtService } from "../services/Jwt.service";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";
axios.defaults.headers.common.Accept = "application/json, text/plain, */*";

type FailedRequest = {
  resolve: (value: AxiosResponse) => void;
  reject: (value: AxiosError) => void;
  config: AxiosRequestConfig;
  error: AxiosError;
};

type AxiosErrorWithRetry = AxiosError & { config: { _retry?: boolean } };

let failedRequests: FailedRequest[] = [];
let isTokenRefreshing = false;

const shouldRetry = (error: AxiosErrorWithRetry) =>
  error.response?.status === 401 && !error.config?._retry;

const isAuthenticateEndpoint = (error: AxiosError) =>
  error.config?.url?.includes("/api/authenticate");

const getAuthorizationHeader = (config: InternalAxiosRequestConfig) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: JwtService.hasValidToken()
      ? `Bearer ${JwtService.getTokenValue()}`
      : null,
  },
});

const refreshToken = async (error: AxiosErrorWithRetry) => {
  const originalRequest = error.config;
  invariant(originalRequest);

  if (shouldRetry(error)) {
    originalRequest._retry = true;

    // Give up trying, there's no way we could recover from 401 in authenticate endpoint
    if (isAuthenticateEndpoint(error)) {
      return Promise.reject(error);
    }

    if (isTokenRefreshing) {
      return new Promise((resolve, reject) => {
        failedRequests.push({
          resolve,
          reject,
          config: originalRequest,
          error,
        });
      });
    }

    isTokenRefreshing = true;

    try {
      const response = await axios.get<
        unknown,
        AxiosResponse<{ id_token: string; principal: string }>
      >(`${process.env.GATEWAY_URL}/authenticate`, {
        // TODO the refresh token is being set via a cookie
        // That is the reason why silent token refresh doesn't work on localhost,
        // as that cookie is being set for another domain
        withCredentials: true,
        params: {},
        headers: {
          "X-Expired-Authorization": JwtService.isImpersonatedToken()
            ? JwtService.getTokenValue()
            : null,
        },
      });
      JwtService.storeToken(response.data);
      originalRequest.headers.Authorization = `Bearer ${response.data.id_token}`;
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.id_token}`;
      failedRequests.forEach(({ config, reject, resolve }) => {
        axios(config).then(resolve).catch(reject);
      });
      return axios.request(originalRequest);
    } catch (refreshError) {
      JwtService.removeToken();
      window.location.href = "/login";
      return Promise.reject(refreshError);
    } finally {
      failedRequests = [];
      isTokenRefreshing = false;
    }
  }
  return Promise.reject(error);
};

const setup = () => {
  axios.defaults.paramsSerializer = {
    indexes: null,
  };

  // @ts-expect-error headers type is a bit off
  axios.interceptors.request.use(getAuthorizationHeader);
  axios.interceptors.response.use(undefined, refreshToken);
};

export { setup };
