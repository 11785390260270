import React from "react";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { COLOR_GREY } from "../../../../../theme";
import { AnyTodo } from "../../../../../types";

const StoreAmount = ({ storeAmount }: { storeAmount: AnyTodo[] }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" item xs={6}>
      <Typography className={classes.label} variant="body2">
        <FormattedMessage id="Stores.on-store" />
      </Typography>
      <Typography data-test="store-amount" variant="subtitle2">
        {storeAmount.length > 0
          ? storeAmount
              .map((item) => `${item.currentBalance} ${item.unitId}`)
              .join(" / ")
          : "-"}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    color: COLOR_GREY[500],
    marginRight: 10,
    fontWeight: 500,
  },
}));

export { StoreAmount };
