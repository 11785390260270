import React, { Suspense, useMemo } from "react";

import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";

import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";
import commonTranslationsCZ from "../../../../shared/lang/locale-cs-CZ.json";
import commonTranslationsEL from "../../../../shared/lang/locale-el-GR.json";
import commonTranslationsEN from "../../../../shared/lang/locale-en-US.json";
import commonTranslationsES from "../../../../shared/lang/locale-es-ES.json";
import commonTranslationsHU from "../../../../shared/lang/locale-hu-HU.json";
import commonTranslationsPL from "../../../../shared/lang/locale-pl-PL.json";
import commonTranslationsRO from "../../../../shared/lang/locale-ro-RO.json";
import Localization from "../../../../shared/services/Localization.service";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsPL from "../../lang/locale-pl-PL.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import Login from "../Login/Login";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

const commonTranslations = {
  [LANG_KEYS.CZ]: commonTranslationsCZ,
  [LANG_KEYS.EN]: commonTranslationsEN,
  [LANG_KEYS.ES]: commonTranslationsES,
  [LANG_KEYS.EL]: commonTranslationsEL,
  [LANG_KEYS.RO]: commonTranslationsRO,
  [LANG_KEYS.HU]: commonTranslationsHU,
  [LANG_KEYS.PL]: commonTranslationsPL,
};

const LoginWrapper = (props) => {
  const langId = Localization.getLang();

  const mergedTranslations = useMemo(
    () => Localization.mergeTranslations(commonTranslations, translations),
    [],
  );

  return (
    <IntlProvider locale={langId} messages={mergedTranslations[langId]}>
      <BaseContainer langId={langId} translations={translations}>
        <Suspense fallback={<CfLoader />}>
          <Login {...props} />
        </Suspense>
      </BaseContainer>
    </IntlProvider>
  );
};

LoginWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
};

export default LoginWrapper;
