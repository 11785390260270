import React, { useState, useEffect, useRef } from "react";
import { TextField, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  setEdit: (val: boolean) => void;
  value?: number;
  handleChange: (e: React.SyntheticEvent, val?: number) => void;
};

const YieldInput = ({ setEdit, value, handleChange }: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<number | undefined>(value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : undefined;
    setInputValue(newValue);
  };

  return (
    <FormControl className={classes.formControl}>
      <TextField
        inputRef={inputRef}
        variant="standard"
        type="number"
        InputProps={{
          endAdornment: " ha",
          inputProps: {
            min: 0,
          },
        }}
        onChange={handleInputChange}
        onBlur={(e) => {
          handleChange(e, inputValue);
          setEdit(false);
        }}
        value={inputValue ?? ""}
      />
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

export { YieldInput };
