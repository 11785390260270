import React, { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  isFetchingDriverAggregations,
  getAggregatedDrive,
} from "../../../shared/api/telematics/aggregations/aggregations.selectors";

import { setFocusedRow as setFocusedWorkerRow } from "../../actions/telematicsAggregations.actions";
import { setFocusedRow as setFocusedMachineRow } from "../../actions/telematicsMachines.actions";

import { TELEMATICS_URLS } from "../../telematics.constants";

import { useFarmIds } from "../../../shared/api/client.utils";
import {
  getAggregatedDriveKeysApi,
  getDriverAggregationDetailApi,
  resetDriverAggregationDetail,
} from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import useFocusedTableRowSource from "../../../shared/hooks/useFocusedTableRowSource";
import { Thunk } from "../../../types";
import { driveQuery } from "../../routes/drivers/Drivers.api";
import { useTelematicsContext } from "../Telematics/Context";
import {
  getPreviousTelematicsPage,
  TELEMATICS_TABS,
} from "../Telematics/helpers";

import HandworkAggregationDetailLayout from "./HandworkAggregationDetailLayout";
import TelematicsAggregationDetailLayout from "./StandardAggregationDetailLayout";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { Type } from "../../../shared/api/telematics/telematics.types";

export const BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH =
  "SEPARATION_INTERVAL_LENGTH";

const useStyles = makeStyles(() => ({
  spinnerWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  catalogueType?: string;
  fetchDriveKeys: (id: string) => void;
  resetDrive: () => void;
  setFocusedMachineRow: (val?: string) => void;
  setFocusedWorkerRow: (val?: string) => void;
}

const TelematicsAggregationDetail = ({
  catalogueType,
  fetchDriveKeys,
  resetDrive,
  setFocusedMachineRow,
  setFocusedWorkerRow,
}: Props) => {
  const location = useLocation<{ backLink?: string }>();
  const history = useHistory();
  const { farmId } = useTelematicsContext();
  const { driveId } = useParams<{ driveId: string }>();
  const driveDetail = useQuery(driveQuery(parseInt(driveId, 10), useFarmIds()));

  const classes = useStyles();

  const [previousPage, setPreviousPage] = useState<
    keyof typeof TELEMATICS_URLS | undefined
  >(undefined);

  const telTab = getPreviousTelematicsPage(location);

  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: driveId,
    setFocusedRow:
      telTab === TELEMATICS_TABS.WORKERS
        ? setFocusedWorkerRow
        : setFocusedMachineRow,
  });

  useEffect(() => () => resetDrive(), [resetDrive]);

  useEffect(() => {
    setPreviousPage(getPreviousTelematicsPage(location));
  }, [location, location.pathname]);

  useEffect(() => {
    if (driveId) {
      fetchDriveKeys(driveId);
    }
  }, [driveId, fetchDriveKeys]);

  const handleGoBack = () => {
    doSetFocusedRow();
    const parentRoute = location.pathname.split("/");
    parentRoute.pop();
    const link = location.state?.backLink ?? parentRoute.join("/");

    history.push(link);
  };

  if (driveDetail.status === "pending") {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (driveDetail.status === "error") {
    return <CfErrorPage error={driveDetail.error} />;
  }

  return driveDetail.data.data.type === Type.HANDWORK ? (
    <HandworkAggregationDetailLayout
      driveDetail={driveDetail.data.data}
      driveId={driveId}
      farmId={farmId}
      handleGoBack={handleGoBack}
    />
  ) : (
    <TelematicsAggregationDetailLayout
      catalogueType={catalogueType}
      driveDetail={driveDetail.data.data}
      driveId={driveId}
      farmId={farmId}
      handleGoBack={handleGoBack}
      previousPage={previousPage}
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isFetching: isFetchingDriverAggregations(state),
  driveDetail: getAggregatedDrive(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrive: getDriverAggregationDetailApi,
      fetchDriveKeys: getAggregatedDriveKeysApi,
      resetDrive: resetDriverAggregationDetail,
      setFocusedWorkerRow,
      setFocusedMachineRow,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsAggregationDetail);
