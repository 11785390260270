export type SelectObject<T = unknown> = {
  value: string;
  key: string;
  obj?: T;
};

export const SELECT_EMPTY_OBJ = {
  key: "",
  value: "",
  obj: undefined,
};
