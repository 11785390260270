import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";

import {
  DashboardParcelTo,
  InternalCropTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import { CropPlaceholder } from "../../dashboard/components/crops/CropPlaceholder";
import { CropsDropdown } from "../../dashboard/components/crops/CropsDropdown";
import { useDelayedLoader } from "../../../hooks/useDelayedLoader";
import { useUpdateSeasonMutation } from "../../dashboard/ParcelDashboard.api";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../shared/components/form/FormSelect/types";
import { satelliteServicesKey } from "../PrecisionServices.api";
import { Theme } from "@mui/material";

type Props = {
  parcel?: DashboardParcelTo;
  cropsOptions: SelectObject<InternalCropTo>[];
  season?: SeasonTo;
};

const CropCell = ({ cropsOptions, parcel, season }: Props) => {
  const loaderClasses = useLoaderStyles();
  const cropPlaceholderClasses = useCropPlaceholderStyles();
  const [edit, setEdit] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useUpdateSeasonMutation();

  const [value, setValue] = useState<
    SelectObject<InternalCropTo> | undefined
  >();
  const [mutatedParcel, setMutatedParcel] = useState<
    DashboardParcelTo | undefined
  >();

  const { isDelayed } = useDelayedLoader(mutation.isPending, 600);
  const parcelToUse = mutatedParcel ?? parcel;

  useEffect(() => {
    const selectedCrop =
      parcelToUse &&
      cropsOptions.find((c) => c?.obj?.name === parcelToUse?.cropName);
    const selectedDropdownValue = selectedCrop
      ? ({
          key: selectedCrop?.obj?.id,
          value: selectedCrop?.obj?.name,
          obj: selectedCrop?.obj,
        } as SelectObject<InternalCropTo>)
      : undefined;

    setValue(selectedDropdownValue);
  }, [cropsOptions, parcelToUse]);

  const handleChange = (
    e: React.SyntheticEvent,
    selected?: SelectObject<InternalCropTo>,
  ) => {
    e.stopPropagation();
    setValue(selected ?? SELECT_EMPTY_OBJ);

    mutation.mutate(
      {
        cropInternalId: selected?.obj?.id ?? "",
        parcelId: parcelToUse?.parcelId ?? "",
        seasonId: season?.id ?? "",
        yieldInTonnes: parcelToUse?.yieldInTonnes ?? 0,
      },
      {
        onSuccess: (data) => {
          const respParcels = data?.data?.parcels ?? [];
          if (respParcels.length) {
            setMutatedParcel(respParcels[0]);
          }
          queryClient.invalidateQueries({
            queryKey: satelliteServicesKey,
            refetchType: "inactive",
          });
        },
        onError: () => {
          setValue(undefined);
        },
      },
    );
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else if (edit) {
    Jsx = (
      <CropsDropdown
        cropsOptions={cropsOptions}
        handleChange={handleChange}
        setEdit={setEdit}
        value={value}
      />
    );
  } else if (!edit) {
    Jsx = (
      <CropPlaceholder
        classes={cropPlaceholderClasses}
        selectedOption={value}
        onClick={(e) => {
          e.stopPropagation();
          setEdit(true);
        }}
      />
    );
  }

  return <div style={{ width: "100%" }}>{Jsx}</div>;
};

export const useCropPlaceholderStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: 16,
    paddingBottom: 16,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { CropCell };
