import React, { Fragment, useState } from "react";

import Popover from "@mui/material/Popover";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import ToolbarIconBtn from "../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import usePopover from "../../../../shared/hooks/usePopover";
import LayersIcon from "../../../../shared/icons/LayersIcon";
import { AnyTodo } from "../../../../types";
import { CropSwitcher } from "../../containers/CropSwitcher/CropSwitcher";
import { LayerSwitcher } from "../../containers/LayerSwitcher/LayerSwitcher";

type Props = {
  withCrops?: boolean;
  farmCountryCode: string;
};

const LayersCropsControl = ({ farmCountryCode, withCrops = true }: Props) => {
  const classes = useStyles();
  const popover = usePopover();

  const [layersExpanded, setLayersExpanded] = useState(true);
  const [cropsExpanded, setCropsExpanded] = useState(false);

  let actions: AnyTodo;

  const handleLayersExpansion = () => {
    setLayersExpanded((prev) => !prev);
    setCropsExpanded(false);

    setTimeout(() => {
      actions.updatePosition();
    }, 0);
  };

  const handleCropsExpansion = () => {
    setCropsExpanded((prev) => !prev);
    setLayersExpanded(false);

    setTimeout(() => {
      actions.updatePosition();
    }, 0);
  };

  return (
    <div className={classes.wrapper}>
      <ToolbarSection>
        <ToolbarIconBtn
          active={popover.isOpen}
          callback={popover.handlePopoverOpen}
          data-test="layer-switcher"
          icon={(p) => <LayersIcon {...p} />}
          product-fruits="layers"
          tooltipTitle="LayerSwitcher.layers"
          withBackground={true}
        />
      </ToolbarSection>
      <Popover
        anchorEl={popover.anchorEl}
        className={classes.layerList}
        disableScrollLock={true}
        onClose={popover.handlePopoverClose}
        open={popover.isOpen}
        action={(newActions) => {
          actions = newActions;
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <LayerSwitcher
          expanded={layersExpanded}
          farmCountryCode={farmCountryCode}
          handleExpansion={handleLayersExpansion}
          testId="layers"
        />
        {withCrops && (
          <Fragment>
            <div className={classes.separator} />
            <CropSwitcher
              expanded={cropsExpanded}
              handleExpansion={handleCropsExpansion}
              testId="crops"
            />
          </Fragment>
        )}
      </Popover>
    </div>
  );
};

export { LayersCropsControl };

const useStyles = makeStyles((theme: Theme) => ({
  layerList: {
    marginTop: 0,
    marginLeft: -4,
  },
  separator: {
    height: 1,
    backgroundColor: theme.palette.grey[100],
  },
  wrapper: {
    marginRight: 8,
  },
}));
