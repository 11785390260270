import React from "react";

import { Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { getLocalizedDateString } from "../../../../shared/misc/timeHelpers";

interface Props {
  endDate?: string;
  startDate: string;
}

const CropDates = ({ endDate, startDate }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<FormattedMessage id="Eph.cropDates.tooltip" />}
    >
      <div className={classes.cropDate}>
        {getLocalizedDateString(startDate)}
        {endDate && <span> - {getLocalizedDateString(endDate)}</span>}
      </div>
    </Tooltip>
  );
};

export { CropDates };

const useStyles = makeStyles((theme: Theme) => ({
  cropDate: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginTop: 4,
  },
  tooltip: {
    fontSize: 12,
  },
}));
