import ErrorService from "../../../services/Error.service";

export const getIsFetching = (state) => state.api.stores.isFetching;
export const getTotalCount = (state) => state.api.stores.totalCount;
export const getError = (state) =>
  ErrorService.getResErrorDto(state.api.stores.error);

export const getItems = (state) => state.api.stores.items;
export const getItemById = (state, id) =>
  id
    ? state.api.stores.items.find(
        (item) => item.id.toString() === id.toString(),
      )
    : {};

export const getStoreAmounByMaterial = (state) =>
  state.api.stores.storeAmountByMaterial;
