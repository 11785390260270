import React from "react";

import { makeStyles } from "@mui/styles";
import {
  FieldArrayRenderProps,
  FieldInputProps,
  FormikErrors,
  useField,
  useFormikContext,
} from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { removeApplication } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";

import { PlantProtectionList } from "./PlantProtectionList";
import { PlantProtectionSelector } from "./PlantProtectionSelector";

import { PlantProtectionSuggestionTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ActionEphFormValues } from "../../actionEph.types";

interface Props {
  actionTotalArea: number;
  arrayHelpers: FieldArrayRenderProps;
  isEditing: boolean;
  isExisting: boolean;
  name: string;
  errors: FormikErrors<Partial<ActionEphFormValues>>;
}

const PlantProtectionControl = ({
  actionTotalArea,
  arrayHelpers,
  errors,
  isEditing,
  isExisting,
  name,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const [field] = useField(name);

  const handleItemRemove = (index: number, platProtectionId: string) => {
    dispatch(removeApplication(platProtectionId));
    arrayHelpers.remove(index);
  };

  const handleChangePlantProtection = (
    plantProtection: PlantProtectionSuggestionTo,
  ) => {
    const plantProtectionIndFields = field.value || [];

    if (plantProtection && !isItemInList(plantProtection, field))
      setFieldValue("plantProtections", [
        plantProtection,
        ...plantProtectionIndFields,
      ]);
  };

  const isItemInList = (
    activeItemToCheck: PlantProtectionSuggestionTo,
    field: FieldInputProps<PlantProtectionSuggestionTo[]>,
  ) =>
    field.value.some(
      (plantProtection: PlantProtectionSuggestionTo) =>
        plantProtection.id === activeItemToCheck.id,
    );

  return (
    <div>
      <div className={classes.container}>
        {isEditing && (
          <PlantProtectionSelector
            defaultValues={null}
            disabled={!isEditing}
            label={<FormattedMessage id="Por.selector.placeholder" />}
            onChange={handleChangePlantProtection}
          />
        )}
        <PlantProtectionList
          actionTotalArea={actionTotalArea}
          errors={errors}
          isEditing={isEditing}
          isExisting={isExisting}
          onItemRemove={handleItemRemove}
          plantProtections={field.value}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    marginLeft: 0,
    marginRight: "auto",
  },
}));

export { PlantProtectionControl };
