import { useEffect } from "react";

import { useField, useFormikContext } from "formik";

import { AnyTodo } from "../../../../types";
import { driftClassValue } from "../actionEph.services";

import {
  ActionEphFormValues,
  EphPlantProtectionsType,
} from "../actionEph.types";

const useEphRestrictions = () => {
  const { setFieldValue } = useFormikContext<ActionEphFormValues>();

  const [parcels] = useField(`parcels`);
  const [plantProtectionsFormValue] = useField(`plantProtections`);
  const plantProtections =
    plantProtectionsFormValue.value as EphPlantProtectionsType[];

  const buffers = plantProtections
    .map((por) =>
      por.pests
        ?.map((pest) =>
          pest.buffer
            ?.filter(
              (b) =>
                b.driftReduction ===
                driftClassValue[
                  (por.driftClass as AnyTodo)
                    ?.code as keyof typeof driftClassValue
                ],
            )
            ?.map((bufferItem) => ({
              type: bufferItem?.type,
              value: bufferItem?.buffer,
              isMaterial: true,
              isAdded: true,
              name: por.name,
            })),
        )
        ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []),
    )
    ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []);

  const waterRestrictions = buffers.filter((buffer) => buffer.type === "Water");

  const boundaryRestrictions = buffers.filter(
    (buffer) => buffer.type === "Boundary",
  );

  useEffect(() => {
    Promise.all(
      parcels.value.map(async (parcel: AnyTodo, index: number) => {
        const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
          (buffer: AnyTodo) => !buffer?.isAdded,
        );

        const defaultBoundaryBuffers =
          parcel?.subtractableAreas?.boundary.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

        await setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            water: [...defaultWaterBuffers, ...waterRestrictions],
            boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
          },
        });
      }),
    );
  }, [parcels?.value?.length, JSON.stringify(plantProtections)]);
};

export { useEphRestrictions };
