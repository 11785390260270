import React, { useState, useEffect } from "react";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface ICfCarouselProps<T> {
  data: T[];
  initialIndex: number;
  isInfinite?: boolean;
  onDataChange?: (value: number) => void;
}

const CfCarousel = <T,>({
  data,
  initialIndex,
  isInfinite = true,
  onDataChange,
}: ICfCarouselProps<T>) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === data.length - 1;

  const handlePrev = () => {
    setCurrentIndex((cur) => (cur - 1 + data.length) % data.length);
  };

  const handleNext = () => {
    setCurrentIndex((cur) => (cur + 1 + data.length) % data.length);
  };

  useEffect(() => {
    onDataChange && onDataChange(currentIndex);
  }, [currentIndex, data, onDataChange]);

  return (
    <div className={classes.wrapper}>
      <IconButton disabled={isInfinite && isAtStart} onClick={handlePrev}>
        <NavigateBeforeIcon />
      </IconButton>

      <span className={classes.label}>
        {data.length > 0 && data[currentIndex]}
      </span>

      <IconButton disabled={isInfinite && isAtEnd} onClick={handleNext}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
};

export { CfCarousel };

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 0",
  },
  label: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 16,
  },
}));
