import React from "react";

import { useSuspenseQuery } from "@tanstack/react-query";

import { ProductionOperationTo } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { productionOperationsQuery } from "./ProductionOperationSelector.api";

type Props = {
  defaultValues?: ProductionOperationTo[];
  externalClasses?: Record<string, string>;
  label: React.ReactNode;
  onChange(items: ProductionOperationTo[]): void;
};

const ProductionOperationSelector = ({
  defaultValues = [],
  externalClasses,
  label,
  onChange,
}: Props) => {
  const operations = useSuspenseQuery(
    productionOperationsQuery({ ...useFarmIds() }),
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      id="production-operation-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={operations.data.data}
      testId="production-operation-filter"
    />
  );
};

export { ProductionOperationSelector };
