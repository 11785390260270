import { AnyAction } from "redux";

import * as commonFilterTypes from "../../shared/actions/filter.constants";
import * as commonTableTypes from "../../shared/actions/table.constants";
import * as sortOrder from "../../shared/constants/sortOrder.constants";
import * as types from "../actions/telematicsMachines.constants";

import { LogbookAggregatedSortColumn } from "../../generated/api/telematics";

import { TelematicsAggregationsMachinesUI } from "../telematics.types";

export const NAMESPACE = "telematicsAggregationsMachinesUI";

export default (reducerNamespace = NAMESPACE) =>
  (
    state: TelematicsAggregationsMachinesUI = {
      page: 0,
      rowsPerPage: 10,
      order: sortOrder.ASC,
      orderBy: LogbookAggregatedSortColumn.DURATION,
      textFilter: "",
      openedMachineRows: [],
    },
    action: AnyAction,
  ) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
          openedMachineRows: [],
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
          openedMachineRows: [],
        };
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
          page: 0,
          openedMachineRows: [],
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
          page: 0,
          openedMachineRows: [],
        };
      case commonFilterTypes.SET_TEXT_FILTER:
        return {
          ...state,
          textFilter: action.textFilter,
          page: 0,
          openedMachineRows: [],
        };
      case commonFilterTypes.SET_ADVANCED_FILTER:
        return {
          ...state,
          advancedFilter: action.advancedFilter,
          page: 0,
        };
      case types.SET_OPENED_MACHINE_ROWS: {
        return {
          ...state,
          openedMachineRows: [...action.payload],
        };
      }
      default:
        return state;
    }
  };
