import { toNumber } from "lodash";
import moment from "moment";

import {
  FORM_TYPES,
  OTHER_ACTION_HARVEST_CODE,
  OTHER_ACTION_MOWING_CODE,
  OTHER_ACTION_SOWING_CODE,
} from "./actionOther.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import {
  ActionOtherDetailTo,
  ActionParcelDetailTo,
  ActionRevenueDetailTo,
  ActionSowingCreateTo,
  ActionSowingDetailTo,
  SubtractionResponse,
} from "../../../generated/api/agroevidence";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import Localization from "../../../shared/services/Localization.service";
import { AnyTodo } from "../../../types";

import {
  ActionOtherFormValues,
  InitialParcelToAdd,
  SubtractableAreasType,
} from "./actionOther.types";
import { RestrictionType } from "../../../shared/api/agroevidence/agroevidence.types";

export const initialEmptyValues: ActionOtherFormValues = {
  actionType: "",
  date: moment().startOf("day"),
  description: "",
  parcels: [],
  productId: "",
  totalGain: 0,
  hectarGain: 0,
  harvestCrop: true,
  isCatchCrop: false,
  seedId: "",
  cropUseType: "",
  mainHarvestProductId: 0,
  secondaryHarvestProductId: 0,
  secondaryTotalGain: 0,
  secondaryHectarGain: 0,
  totalAmount: 0,
  hectarAmount: 0,
  unitId: "t",
  seedApplications: [],
};

export const mapOtherFormInitialValues = (
  action: ActionRevenueDetailTo | ActionSowingDetailTo | ActionOtherDetailTo,
  locale: LANGUAGE_ID,
) => {
  const isHarvestAction = action.actionType === FORM_TYPES.HARVEST;
  const isMowingAction = action.actionType === FORM_TYPES.MOWING;
  const isSowingAction = action.actionType === FORM_TYPES.SOWING;

  const standardFields = {
    parcels: mapParcelsFrom(action.parcels),
    date: moment(action.date).startOf("day"),
    description: action.note || "",
  };

  let optionalFields;

  if (isHarvestAction) {
    const harvestAction = action as ActionRevenueDetailTo;
    const revenues = harvestAction.revenues;
    if (revenues?.length === 1) {
      optionalFields = {
        mainHarvestProductId: revenues[0].product || 0,
        totalGain:
          Localization.num2strNonFixed(revenues[0].amount, locale, 3, 3) || 0,
        hectarGain: 0,
        secondaryHarvestProductId: 0,
        secondaryTotalGain: 0,
        secondaryHectarGain: 0,
      };
    } else if (revenues?.length === 2) {
      optionalFields = {
        mainHarvestProductId: revenues[0].isMainProduct
          ? revenues[0].product
          : revenues[1].product,
        totalGain: revenues[0].isMainProduct
          ? Localization.num2strNonFixed(revenues[0].amount, locale, 3, 3)
          : Localization.num2strNonFixed(revenues[1].amount, locale, 3, 3),
        hectarGain: 0,
        secondaryHarvestProductId: revenues[0].isMainProduct
          ? revenues[1].product
          : revenues[0].product,
        secondaryTotalGain: revenues[0].isMainProduct
          ? Localization.num2strNonFixed(revenues[1].amount, locale, 3, 3)
          : Localization.num2strNonFixed(revenues[0].amount, locale, 3, 3),
        secondaryHectarGain: 0,
      };
    } else if (!revenues?.length) {
      optionalFields = {
        mainHarvestProductId: 0,
        totalGain: 0,
        hectarGain: 0,
        secondaryHarvestProductId: 0,
        secondaryTotalGain: 0,
        secondaryHectarGain: 0,
      };
    }
  }

  if (isMowingAction) {
    const mowingAction = action as ActionRevenueDetailTo;
    optionalFields = {
      totalGain:
        Localization.num2strNonFixed(
          mowingAction?.revenues?.[0]?.amount ?? 0,
          locale,
          3,
          3,
        ) || 0,
      hectarGain: 0,
      productId: mowingAction?.revenues?.[0]?.product || "",
      harvestCrop: true,
    };
  }

  if (isSowingAction) {
    const sowingAction = action as ActionSowingDetailTo;

    optionalFields = {
      isCatchCrop: action.isDraft
        ? false
        : sowingAction.seedApplicationType !== "MAIN_CROP",
      seedId: sowingAction.seedExpense?.material?.id || "",
      cropUseType: sowingAction.cropUseType?.cropUseType || "",
      totalAmount:
        Localization.num2strNonFixed(
          sowingAction.seedExpense?.actionExpense?.amount as number,
          locale,
          3,
          3,
        ) || 0,
      hectarAmount:
        Localization.num2strNonFixed(
          sowingAction.seedExpense?.actionExpense?.dosePerHa as number,
          locale,
          3,
          3,
        ) || 0,
      unitId: sowingAction.seedExpense?.actionExpense?.unit || "t",
      mainHarvestProductId: sowingAction?.mainHarvestProduct?.externalId ?? 0,
    };
  }

  return {
    actionType: action.actionType,
    ...standardFields,
    ...optionalFields,
  };
};

const mapParcelsFrom = (parcels: ActionParcelDetailTo[]) =>
  parcels
    ?.map((p: ActionParcelDetailTo) => ({
      ...p.parcel,
      subtractableAreas: mapSubtractableAreasFrom(p.restrictions),
      actionParcelTotalArea: toNumber(p.actionArea.toFixed(5)),
      restrictedArea: p.restrictedArea,
    }))
    .sort((a: AnyTodo, b: AnyTodo) => a.id - b.id);

export const mapSubtractableAreasFrom = (p: SubtractionResponse[]) => {
  const areas: SubtractableAreasType = {
    absolute: p.filter((sa: SubtractionResponse) => sa.type === "Absolute"),
    boundary: p.filter((sa: SubtractionResponse) => sa.type === "Boundary"),
    water: p.filter((sa: SubtractionResponse) => sa.type === "Water"),
    boundaryChecked:
      p
        .filter((sa: SubtractionResponse) => sa.type === "Boundary")
        .find((sa: SubtractionResponse) => sa.isUsed === true)?.value || 0,
    waterChecked:
      p
        .filter((sa: SubtractionResponse) => sa.type === "Water")
        .find((sa: SubtractionResponse) => sa.isUsed === true)?.value || 0,
  };
  return areas;
};

// ==================================================================
// ======================== MAP REQUEST BODY ========================
// ==================================================================

export const mapRequestBodyOtherActionTo = (
  values: ActionOtherFormValues,
  locale: LANGUAGE_ID,
  existingAction?: ActionOtherDetailTo,
) => {
  const { actionType } = values;

  const isHarvestAction = actionType === OTHER_ACTION_HARVEST_CODE;
  const isMainHarvestProductSelected = values.mainHarvestProductId !== 0;
  const isSecondaryHarvestProductSelected =
    values.secondaryHarvestProductId !== 0;
  const notCzechFarmHarvestAction =
    !isMainHarvestProductSelected && !isSecondaryHarvestProductSelected;

  const isMowingAction = actionType === OTHER_ACTION_MOWING_CODE;
  const isSowingAction = actionType === OTHER_ACTION_SOWING_CODE;

  const payload: AnyTodo = {
    date: getShortDateString(values.date),
    note: values.description,
    parcels: mapParcels(values.parcels),
  };

  if (existingAction) {
    payload.id = existingAction.id;
  }

  if (!isMowingAction && !isHarvestAction && !isSowingAction) {
    payload.actionType = actionType;
  }

  if (isMowingAction) {
    return {
      ...payload,
      revenue: {
        amount:
          Localization.str2numNonFixed(values.totalGain ?? 0, locale) || 0,
        productLegislativeCode: values.productId,
        isMainProduct: true,
      },
    };
  }

  if (isHarvestAction) {
    if (isMainHarvestProductSelected && isSecondaryHarvestProductSelected) {
      return {
        ...payload,
        revenues: [
          {
            amount:
              Localization.str2numNonFixed(values.totalGain ?? 0, locale) || 0,
            productLegislativeCode: values.mainHarvestProductId,
            isMainProduct: true,
          },
          {
            amount:
              Localization.str2numNonFixed(
                values.secondaryTotalGain ?? 0,
                locale,
              ) || 0,
            productLegislativeCode: values.secondaryHarvestProductId,
            isMainProduct: false,
          },
        ],
      };
    } else if (
      isMainHarvestProductSelected &&
      !isSecondaryHarvestProductSelected
    ) {
      return {
        ...payload,
        revenues: [
          {
            amount:
              Localization.str2numNonFixed(values.totalGain ?? 0, locale) || 0,
            productLegislativeCode: values.mainHarvestProductId,
            isMainProduct: true,
          },
        ],
      };
    } else if (notCzechFarmHarvestAction) {
      return {
        ...payload,
        revenues: [
          {
            amount:
              Localization.str2numNonFixed(values.totalGain ?? 0, locale) || 0,
            isMainProduct: true,
          },
        ],
      };
    } else {
      return {
        ...payload,
        revenues: [],
      };
    }
  }

  if (isSowingAction) {
    const res = {
      ...payload,
      seedExpense: {
        actionMaterial: {
          materialId: values.seedId,
        },
      },
      seedApplicationType: values.isCatchCrop ? "CATCH_CROP" : "MAIN_CROP",
      mainHarvestProductExternalId: values.mainHarvestProductId,
    };

    if (existingAction && !existingAction.isDraft) {
      res.seedExpense.actionMaterial.id = (
        existingAction as unknown as ActionSowingCreateTo
      ).seedExpense?.actionMaterial?.id;
    }

    if (values.cropUseType !== "") {
      res.cropUseType = values.cropUseType;
    }

    if (values.totalAmount !== "0") {
      res.seedExpense.actionExpense = {
        amount: Localization.str2numNonFixed(values.totalAmount ?? 0, locale),
        unit: values.unitId,
        dosePerHa: Localization.str2numNonFixed(
          values.hectarAmount ?? 0,
          locale,
        ),
      };
    }
    return res;
  }

  return payload;
};

const mapParcels = (parcels: InitialParcelToAdd[]) =>
  parcels.map((parcel) => ({
    parcelId: parcel.id,
    restrictions: mapParcelSubtractableAreas(parcel),
  }));

const mapParcelSubtractableAreas = (parcel: InitialParcelToAdd) => {
  const areas = parcel.subtractableAreas.absolute
    .filter((sa: SubtractionResponse) => sa.isUsed === true)
    .map((sa: SubtractionResponse) => ({
      type: sa.type,
      value: sa.value,
    }));
  if (
    !(
      toNumber(parcel.subtractableAreas.boundaryChecked) === 0 ||
      parcel.subtractableAreas.boundaryChecked === undefined
    )
  ) {
    areas.push({
      type: RestrictionType.Boundary,
      value: toNumber(parcel.subtractableAreas.boundaryChecked),
    });
  }
  if (
    !(
      toNumber(parcel.subtractableAreas.waterChecked) === 0 ||
      parcel.subtractableAreas.waterChecked === undefined
    )
  ) {
    areas.push({
      type: RestrictionType.Water,
      value: toNumber(parcel.subtractableAreas.waterChecked),
    });
  }
  return areas;
};
