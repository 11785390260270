import React, { FC, Suspense, useMemo } from "react";

import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";

import {
  DriveKeyTo,
  DriveValidationType,
  KeyType,
  WinfasClientTo,
  WinfasTo,
  WinfasUpdateTo,
} from "../../../generated/api/telematics";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";

import { Buttons, DriveKey } from "./formComponents";
import { WinfasClient } from "./formComponents/WinfasClient";
import { validateKeysForm } from "./validators";

interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  date: string;
  driveKeys: WinfasTo | null;
  handleReset: () => void;
  handleSave: (values: WinfasUpdateTo) => void;
  isEditing: boolean;
}

export interface DriveKeysFormValues {
  client?: WinfasClientTo;
  customerKey?: DriveKeyTo;
  equipmentKey?: DriveKeyTo;
  supplierKey?: DriveKeyTo;
}

const DriveKeysForm: FC<Props> = ({
  approvalValidationErrors,
  date,
  driveKeys,
  handleReset,
  handleSave,
  isEditing,
}) => {
  const initialValues = useMemo(
    () => ({
      supplierKey: driveKeys?.keys?.supplierKey,
      customerKey: driveKeys?.keys?.customerKey,
      equipmentKey: driveKeys?.keys?.equipmentKey,
      client: driveKeys?.client,
    }),
    [driveKeys],
  );

  const handleSubmit = (values: DriveKeysFormValues) => {
    const data = {
      keys: {
        supplierKeyId: values.supplierKey?.id,
        customerKeyId: values.customerKey?.id,
        equipmentKeyId: values.equipmentKey?.id,
      },
      client: values.client ?? undefined,
    };
    handleSave(data);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={handleReset}
      onSubmit={handleSubmit}
      validate={validateKeysForm}
      validateOnBlur
      validateOnChange
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <DriveKey
                disabled
                fieldName="supplierKey"
                keyType={KeyType.SUPPLIER}
                showApprovalWarning={!!approvalValidationErrors.supplierKey}
                tooltipCode={approvalValidationErrors.supplierKey}
                variant="disabled"
                helperText={
                  !!errors.supplierKey && (
                    <FormattedMessage id={errors.supplierKey} />
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DriveKey
                disabled={!isEditing}
                fieldName="customerKey"
                keyType={KeyType.CUSTOMER}
                showApprovalWarning={!!approvalValidationErrors.customerKey}
                tooltipCode={approvalValidationErrors.customerKey}
                helperText={
                  !!errors.customerKey && (
                    <FormattedMessage id={errors.customerKey} />
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DriveKey
                disabled
                fieldName="equipmentKey"
                keyType={KeyType.EQUIPMENT}
                showApprovalWarning={false}
                variant="disabled"
                helperText={
                  !!errors.equipmentKey && (
                    <FormattedMessage id={errors.equipmentKey} />
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary fallback={<></>}>
                <Suspense fallback={<CfLoader />}>
                  <WinfasClient
                    date={date}
                    disabled={!isEditing}
                    fieldName="client"
                    showApprovalWarning={!!approvalValidationErrors.client}
                    tooltipCode={approvalValidationErrors.client}
                    helperText={
                      !!errors.client && <FormattedMessage id={errors.client} />
                    }
                  />
                </Suspense>
              </ErrorBoundary>
            </Grid>
          </Grid>
          {isEditing && <Buttons isSubmitting={isSubmitting} />}
        </Form>
      )}
    </Formik>
  );
};

export default DriveKeysForm;
