import { useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";

import { setVisitedTab } from "../../actions/telematicsTabs.actions";

import { API_MODULES } from "../../../shared/api/api.constants";

import UiCacheService from "../../../shared/services/UiCache.service";

import { Tab } from "./TelematicsTabs";

const useTelematicsLogger = (tab: Tab) => {
  const logLastVisitedTab = useLogLastVisitedTab();

  useEffect(() => {
    logLastVisitedTab(tab);
  }, [tab, logLastVisitedTab]);
};

const useLogLastVisitedTab = () => {
  const dispatch = useDispatch();

  return useCallback(
    (tab: Tab) => {
      dispatch(setVisitedTab(tab));
      saveToCache("lastVisitedTab", tab);
    },
    [dispatch],
  );
};

const dropLastVisitedTab = () => {
  const futureCache = getTelematicsUICache();
  if (futureCache.telematicsTabs) {
    futureCache.telematicsTabs.lastVisitedTab = undefined;
  }
  UiCacheService.storeSettings(API_MODULES.TELEMATICS, futureCache);
};

const saveToCache = (key: string, value: unknown) => {
  const futureCache = getTelematicsUICache();
  futureCache.telematicsTabs = futureCache.telematicsTabs ?? {};
  futureCache.telematicsTabs[key] = value;
  UiCacheService.storeSettings(API_MODULES.TELEMATICS, futureCache);
};

const getTelematicsUICache = () => {
  const currentCache =
    UiCacheService.getCachePerSection(API_MODULES.TELEMATICS) ?? {};
  const futureCache = JSON.parse(JSON.stringify(currentCache));

  return futureCache;
};

export {
  dropLastVisitedTab,
  useLogLastVisitedTab,
  useTelematicsLogger,
  saveToCache,
};
