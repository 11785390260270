import React from "react";

import HomeIcon from "@mui/icons-material/Home";
import { Box, styled } from "@mui/material";

type Props = { zoomToHome: () => void } & React.ComponentProps<typeof Wrapper>;

const HomeControl = ({ zoomToHome, ...rest }: Props) => (
  <Wrapper className="ol-control ol-home-control" {...rest}>
    <button data-test="map-home" onClick={zoomToHome}>
      <HomeIcon />
    </button>
  </Wrapper>
);

const Wrapper = styled(Box)({
  bottom: "103px",
  position: "absolute",
  right: "15px",
  zIndex: 9,
});

export { HomeControl };
