const PREFIX = "AGROEVIDENCE_ACTIONS";

export const GET_ACTIONS_REQUEST = `${PREFIX}/GET_ACTIONS_REQUEST`;
export const GET_ACTIONS_SUCCESS = `${PREFIX}/GET_ACTIONS_SUCCESS`;
export const GET_ACTIONS_ERROR = `${PREFIX}/GET_ACTIONS_ERROR`;
export const RESET_ACTIONS = `${PREFIX}/RESET_ACTIONS`;

export const DELETE_ACTIONS_REQUEST = `${PREFIX}/DELETE_ACTIONS_REQUEST`;
export const DELETE_ACTIONS_SUCCESS = `${PREFIX}/DELETE_ACTIONS_SUCCESS`;
export const DELETE_ACTIONS_ERROR = `${PREFIX}/DELETE_ACTIONS_ERROR`;

export const GET_ACTION_REQUEST = `${PREFIX}/GET_ACTION_REQUEST`;
export const GET_ACTION_SUCCESS = `${PREFIX}/GET_ACTION_SUCCESS`;
export const GET_ACTION_ERROR = `${PREFIX}/GET_ACTION_ERROR`;

export const UPDATE_SOWING_REQUEST = `${PREFIX}/UPDATE_SOWING_REQUEST`;
export const UPDATE_SOWING_SUCCESS = `${PREFIX}/UPDATE_SOWING_SUCCESS`;
export const UPDATE_SOWING_ERROR = `${PREFIX}/UPDATE_SOWING_ERROR`;

export const UPDATE_OTHER_REQUEST = `${PREFIX}/UPDATE_OTHER_REQUEST`;
export const UPDATE_OTHER_SUCCESS = `${PREFIX}/UPDATE_OTHER_SUCCESS`;
export const UPDATE_OTHER_ERROR = `${PREFIX}/UPDATE_OTHER_ERROR`;

export const UPDATE_MOWING_REQUEST = `${PREFIX}/UPDATE_MOWING_REQUEST`;
export const UPDATE_MOWING_SUCCESS = `${PREFIX}/UPDATE_MOWING_SUCCESS`;
export const UPDATE_MOWING_ERROR = `${PREFIX}/UPDATE_MOWING_ERROR`;

export const UPDATE_HARVEST_REQUEST = `${PREFIX}/UPDATE_HARVEST_REQUEST`;
export const UPDATE_HARVEST_SUCCESS = `${PREFIX}/UPDATE_HARVEST_SUCCESS`;
export const UPDATE_HARVEST_ERROR = `${PREFIX}/UPDATE_HARVEST_ERROR`;

export const UPDATE_EPH_REQUEST = `${PREFIX}/UPDATE_EPH_REQUEST`;
export const UPDATE_EPH_SUCCESS = `${PREFIX}/UPDATE_EPH_SUCCESS`;
export const UPDATE_EPH_ERROR = `${PREFIX}/UPDATE_EPH_ERROR`;

export const CREATE_SOWING_REQUEST = `${PREFIX}/CREATE_SOWING_REQUEST`;
export const CREATE_SOWING_SUCCESS = `${PREFIX}/CREATE_SOWING_SUCCESS`;
export const CREATE_SOWING_ERROR = `${PREFIX}/CREATE_SOWING_ERROR`;

export const CREATE_OTHER_REQUEST = `${PREFIX}/CREATE_OTHER_REQUEST`;
export const CREATE_OTHER_SUCCESS = `${PREFIX}/CREATE_OTHER_SUCCESS`;
export const CREATE_OTHER_ERROR = `${PREFIX}/CREATE_OTHER_ERROR`;

export const CREATE_MOWING_REQUEST = `${PREFIX}/CREATE_MOWING_REQUEST`;
export const CREATE_MOWING_SUCCESS = `${PREFIX}/CREATE_MOWING_SUCCESS`;
export const CREATE_MOWING_ERROR = `${PREFIX}/CREATE_MOWING_ERROR`;

export const CREATE_HARVEST_REQUEST = `${PREFIX}/CREATE_HARVEST_REQUEST`;
export const CREATE_HARVEST_SUCCESS = `${PREFIX}/CREATE_HARVEST_SUCCESS`;
export const CREATE_HARVEST_ERROR = `${PREFIX}/CREATE_HARVEST_ERROR`;

export const CREATE_EPH_REQUEST = `${PREFIX}/CREATE_EPH_REQUEST`;
export const CREATE_EPH_SUCCESS = `${PREFIX}/CREATE_EPH_SUCCESS`;
export const CREATE_EPH_ERROR = `${PREFIX}/CREATE_EPH_ERROR`;

export const GET_ACTION_RESTRICTIONS_REQUEST = `${PREFIX}/GET_ACTION_RESTRICTIONS_REQUEST`;
export const GET_ACTION_RESTRICTIONS_SUCCESS = `${PREFIX}/GET_ACTION_RESTRICTIONS_SUCCESS`;
export const GET_ACTION_RESTRICTIONS_ERROR = `${PREFIX}/GET_ACTION_RESTRICTIONS_ERROR`;
export const RESET_ACTION_RESTRICTIONS = `${PREFIX}/RESET_ACTION_RESTRICTIONS`;

export const GET_OTHER_ACTION_TYPES_REQUEST = `${PREFIX}/GET_OTHER_ACTION_TYPES_REQUEST`;
export const GET_OTHER_ACTION_TYPES_SUCCESS = `${PREFIX}/GET_OTHER_ACTION_TYPES_SUCCESS`;
export const GET_OTHER_ACTION_TYPES_ERROR = `${PREFIX}/GET_OTHER_ACTION_TYPES_ERROR`;
export const RESET_OTHER_ACTION_TYPES = `${PREFIX}/RESET_OTHER_ACTION_TYPES`;

export const GET_ACTION_SPLIT_REQUEST = `${PREFIX}/GET_ACTIONS_SPLIT_REQUEST`;
export const GET_ACTION_SPLIT_SUCCESS = `${PREFIX}/GET_ACTIONS_SPLIT_SUCCESS`;
export const GET_ACTION_SPLIT_ERROR = `${PREFIX}/GET_ACTIONS_SPLIT_ERROR`;

export const GET_EPH_TARGET_SEED_APPLICATION_REQUEST = `${PREFIX}/GET_EPH_TARGET_SEED_APPLICATION_REQUEST`;
export const GET_EPH_TARGET_SEED_APPLICATION_SUCCESS = `${PREFIX}/GET_EPH_TARGET_SEED_APPLICATION_SUCCESS`;
export const GET_EPH_TARGET_SEED_APPLICATION_ERROR = `${PREFIX}/GET_EPH_TARGET_SEED_APPLICATION_ERROR`;
export const RESET_EPH_TARGET_SEED_APPLICATION = `${PREFIX}/RESET_EPH_TARGET_SEED_APPLICATION`;

export const GET_EPH_ACCOUNTABLE_NITROGEN_REQUEST = `${PREFIX}/GET_EPH_ACCOUNTABLE_NITROGEN_REQUEST`;
export const GET_EPH_ACCOUNTABLE_NITROGEN_SUCCESS = `${PREFIX}/GET_EPH_ACCOUNTABLE_NITROGEN_SUCCESS`;
export const GET_EPH_ACCOUNTABLE_NITROGEN_ERROR = `${PREFIX}/GET_EPH_ACCOUNTABLE_NITROGEN_ERROR`;
export const RESET_EPH_ACCOUNTABLE_NITROGEN = `${PREFIX}/RESET_EPH_ACCOUNTABLE_NITROGEN`;
