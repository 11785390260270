import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import {
  DashboardParcelTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import { COLOR_FONT } from "../../../../theme";

interface Props {
  handleClose: () => void;
  handleAccept: () => void;
  open: boolean;
  season?: SeasonTo;
  parcel: DashboardParcelTo;
}

const DeleteDialog = ({
  handleAccept,
  handleClose,
  open,
  parcel,
  season,
}: Props) => {
  const classes = useStyles();

  return (
    <CfDialog
      acceptButtonVariant="text"
      acceptText={<FormattedMessage id="common.delete" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={handleAccept}
      onCancel={handleClose}
      opened={open}
      processing={false}
      title={<></>}
    >
      <div className={classes.body}>
        <FormattedMessage
          id="PrecisionServices.deleteDialog.question"
          values={{
            b: (chunks: string) => <strong>{chunks}</strong>,
            seasonName: season?.name,
            parcelName: parcel.localName,
          }}
        />
      </div>
    </CfDialog>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    color: COLOR_FONT.main,
    fontSize: 16,
  },
}));

export { DeleteDialog };
