import React, { useEffect } from "react";

import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMonitoringCropIntervalsIsFetching,
  getRecords,
  getRecordsIsFetching,
  getRecordsValidity,
} from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getDateFrom,
  getDateTo,
  getIndex,
  getMonitoringStatus,
} from "../../selectors/monitoring.selectors";

import * as satelliteProductsTypes from "../../../../shared/constants/satelliteProductsTypes.constants";

import {
  getBiomonitoringIndexRecordsApi,
  getCropIntervalsApi,
} from "../../../../shared/api/sentinel/monitoring/monitoring.api";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import BioMonitoringImageErrorService from "../../services/BioMonitoringImageError.services";
import { ChartDataOrMonitoringData } from "../BioMonitoring";

import { isPlanetIndexType } from "./BiomonitoringWrapper/helpers";
import { IndexTypeDataType } from "./types";
import { useLocking } from "./useLocking";

import {
  BiomonitoringIndexRecordDto,
  IndexType,
} from "../../../../shared/api/satellite/satellite.types";

const useSaviBioMonitoring = (parcelId: string) => {
  const dispatch = useDispatch();

  const index = useSelector(getIndex);
  const dateFrom = useSelector(getDateFrom);
  const dateTo = useSelector(getDateTo);
  const monitoringStatus = useSelector(getMonitoringStatus);
  const records = useSelector(getRecords);
  const isFetchingRecords = useSelector(getRecordsIsFetching);
  const validity = useSelector(getRecordsValidity);
  const isFetchingCropIntervals = useSelector(
    getMonitoringCropIntervalsIsFetching,
  );

  const { isInit } = useLocking(isPlanetIndexType(index));

  const isFetching = isFetchingRecords || isFetchingCropIntervals;

  useEffect(() => {
    if (!isInit) {
      if (
        monitoringStatus === satelliteProductsTypes.ACTIVE ||
        monitoringStatus === satelliteProductsTypes.HISTORICAL
      ) {
        dispatch(getCropIntervalsApi(parcelId, IndexType.SAVI));
      }
    }
  }, [dispatch, isInit, monitoringStatus, parcelId]);

  useEffect(() => {
    if (isInit && dateFrom) {
      dispatch(
        getBiomonitoringIndexRecordsApi({
          "date-from": dateFrom,
          "date-to": dateTo.length > 0 ? dateTo : getShortDateString(),
          "index-type": index,
          parcelId,
        }),
      );
    }
  }, [dateFrom, parcelId, dateTo, dispatch, isInit, index]);

  const getChartValue = (r: BiomonitoringIndexRecordDto) => {
    const average = r.average;
    if (average === undefined || average === null || average >= 0) {
      return average;
    } else {
      return 0;
    }
  };

  const data: ChartDataOrMonitoringData[] = (records?.records ?? []).map(
    (r) =>
      ({
        value: getChartValue(r),
        tooltipValue: r.average,
        average: r.average,
        crop: undefined,
        dateFrom: r.dateFrom,
        dateTo: r.dateTo,
        imgExtent: r.imgExtent,
        indexType: r.type,
        imgPath: r.rasterUrl,
        zones: r.zones.map((z) => ({
          color: z.color,
          geometry: {
            type: z.geometry.type,
            coordinates: z.geometry.coordinates,
            geometries: z.geometry.geometries,
          },
          zoneNumber: Number(z.index) || z.index,
          areaHa: -1,
          zoneValue: -1,
        })),
      }) as ChartDataOrMonitoringData,
  );

  const overallImagesError =
    BioMonitoringImageErrorService.getOverallImagesError(data, index);
  const cropHarvestedError =
    BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = validity;

  const infoDialogContent = isPlanetIndexType(index) ? (
    <BioMonitoringHistoryInfoDialog.Root index={index}>
      <BioMonitoringHistoryInfoDialog.Footnote>
        <FormattedMessage
          id={`BioMonitoringInfoDialog.zoningExplanation.footnote.${index}`}
        />
      </BioMonitoringHistoryInfoDialog.Footnote>
    </BioMonitoringHistoryInfoDialog.Root>
  ) : undefined;

  return {
    isFetching,
    overallImagesError,
    cropHarvestedError,
    lastImageDateTo,
    data,
    infoDialogContent,
    graphData: [...data].sort((a, b) =>
      moment(a.dateFrom).diff(moment(b.dateFrom)),
    ),
  } as IndexTypeDataType;
};

export { useSaviBioMonitoring };
