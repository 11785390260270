import React, { FC } from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

interface Props {
  defaultValues: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: React.ReactElement;
  name: string;
}

const WaterProtectionZoneRadioGroup: FC<Props> = ({
  defaultValues,
  label,
  name,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.container}>
      <Grid data-test="sectionHeading" item xs={12}>
        <Typography className={classes.sectionHeading} variant="h6">
          {label}
        </Typography>
      </Grid>
      <RadioGroup
        defaultValue={defaultValues}
        name={name}
        onChange={onChange}
        row
      >
        <FormControlLabel
          control={<Radio size="small" />}
          value={0}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.noProtectionZone" />
          }
        />
        <FormControlLabel
          control={<Radio size="small" />}
          value={1}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id="Catalogues.plantProtection.application.editDialog.protectionZoneLevel"
              values={{ zone: 1 }}
            />
          }
        />
        <FormControlLabel
          control={<Radio size="small" />}
          value={2}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id="Catalogues.plantProtection.application.editDialog.protectionZoneLevel"
              values={{ zone: 2 }}
            />
          }
        />
        <FormControlLabel
          control={<Radio size="small" />}
          value={3}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id="Catalogues.plantProtection.application.editDialog.protectionZoneLevel"
              values={{ zone: 3 }}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export { WaterProtectionZoneRadioGroup };

const useStyles = makeStyles(() => ({
  radioGroupLabel: {
    fontSize: 13,
    marginBottom: 0,
  },
  sectionHeading: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    textAlign: "center",
  },
  container: {
    marginLeft: "16px",
  },
}));
