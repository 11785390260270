import { AnyAction } from "redux";

import * as types from "../actions/monitoring.constants";

import { IndexType } from "../../../shared/api/satellite/satellite.types";

// LAI is default index, because if monitoring does not contain LAI, we can consider it not fully active
const defaultIndex = IndexType.LAI;

const initialState = {
  dateFrom: "",
  dateTo: "",
  selectedIntervalId: undefined,
  index: defaultIndex,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.SET_MONITORING_INTERVAL:
        return {
          ...state,
          dateFrom: action.dateFrom,
          dateTo: action.dateTo,
          selectedIntervalId: action.selectedIntervalId,
        };
      case types.RESET_MONITORING_INTERVAL:
        return {
          ...state,
          dateFrom: "",
          dateTo: "",
          selectedIntervalId: undefined,
        };
      case types.SET_MONITORING_INDEX:
        return {
          ...state,
          index: action.index,
        };
      default:
        return state;
    }
  };
