import React from "react";

const useTableFooter = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const onRowsPerPageChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(evt.target.value));
  };

  const onPageChange = (
    evt: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ) => {
    setPage(page);
  };

  return {
    onRowsPerPageChange,
    onPageChange,
    page,
    rowsPerPage,
  };
};

export { useTableFooter };
