import React, { Suspense } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { updateSubject } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import { stringDateToMoment } from "../../../../shared/misc/timeHelpers";
import { CfFormikErrors } from "../../../../types";
import {
  EMPTY_NAME,
  WinfasClient,
} from "../../../components/TelematicsAggregationDetailContent/formComponents/WinfasClient";
import { MESSAGES } from "../../../components/TelematicsAggregationDetailContent/validators";
import { useCustomStyles } from "../components/styles/useCustomStyles";
import { telematicsDriversKey } from "../Drivers.api";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import { WinfasClientTo } from "../../../../shared/api/telematics/telematics.types";

const useClient = () => {
  const intl = useIntl();
  const customClasses = useCustomStyles();
  const updateClientMutation = useBatchClientMutation();

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );
  const dateFrom = useSelector(
    (state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom,
  );
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers =
      dateFromMoment.toISOString() ?? moment().startOf("day").toISOString();
  }

  const initialValues: { client?: WinfasClientTo } = {
    client: undefined,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.client) {
      errors.client = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      drive: selectedRides.map((ride) => parseInt(ride, 10)),
      subject:
        values.client && values.client?.name !== EMPTY_NAME
          ? values.client?.idNumber
          : undefined,
    };
    // TODO use sync version of mutate when all the hooks are refactored
    return updateClientMutation.mutateAsync(payload);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<CfLoader />}>
        <WinfasClient
          customClasses={customClasses}
          date={dateForClassifiers}
          disabled={formikProps.isSubmitting}
          fieldName="client"
          hasEmptyOption
          helperText={(formikProps.errors.client as string) ?? ""}
          placeholder={intl.formatMessage({
            id: "TelematicsAggregations.list.bulk.secondary.placeholder.client",
          })}
        />
      </Suspense>
    </ErrorBoundary>
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

const useBatchClientMutation = () => {
  const queryClient = useQueryClient();
  const { farmIds } = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateSubject>[0]) =>
      updateSubject(payload, {
        farmIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export { useClient };
