import React, { createContext, ReactNode, useState } from "react";

import CfSnackbar from "../../components/common/CfSnackbar/CfSnackbar";

interface SnackbarProps {
  autoHideDuration?: number;
  isError?: boolean;
  isSuccess?: boolean;
  message: ReactNode;
}

export const SnackbarContext =
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createContext<(snackbarProps: SnackbarProps) => void>(() => {});

interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarData, setSnackbarData] = useState<SnackbarProps | null>(null);

  const showSnackbar = (snackbarProps: SnackbarProps) => {
    setSnackbarData(snackbarProps);
  };

  const handleClose = () => {
    setSnackbarData(null);
  };

  return (
    <>
      {snackbarData && (
        <CfSnackbar
          autoHideDuration={snackbarData.autoHideDuration}
          isError={snackbarData.isError}
          isSuccess={snackbarData.isSuccess}
          message={snackbarData.message}
          onClose={handleClose}
          open={true}
        />
      )}
      <SnackbarContext.Provider value={showSnackbar}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
};

export default SnackbarProvider;
