import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import { AnyTodo } from "../../../../types";

interface Props {
  item: AnyTodo;
}

const StoreDetailHeading = ({ item }: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<{ farmId: string }>();

  let hrefLink: string | undefined;
  switch (item.material.type) {
    case "FERTILIZER":
      hrefLink = `/farm/${farmId}/catalogues/fertilizersReact/${item.material.id}`;
      break;
    case "PLANT_PROTECTION":
      hrefLink = `/farm/${farmId}/catalogues/plantProtection/${item.material.id}`;
      break;
    case "SEED":
      break;
    default:
      return;
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    if (hrefLink) {
      window.open(hrefLink, "_blank");
    }
  };

  return (
    <PageHeading
      dataTest="store-heading"
      value={
        <div className={classes.heading}>
          <div className={classes.materialName}>{item.material.name}</div>
          {hrefLink && (
            <a
              className={classes.materialDetailButton}
              data-test="material-detail"
              onClick={handleClick}
            >
              <FormattedMessage id="Store.detailHeading.materialDetail" />
            </a>
          )}
        </div>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  materialName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 4,
  },
  materialDetailButton: {
    padding: 0,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
  },
}));

export { StoreDetailHeading };
