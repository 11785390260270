import React, { Fragment, ReactNode, useEffect, useState } from "react";

import { TextField, Theme } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderGroupParams,
} from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import differenceBy from "lodash/differenceBy";
import some from "lodash/some";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getParcelsAndZonesSuggestionsAutocomplete } from "../../selectors/actions.selectors";

import { fetchParcelsAndZonesSuggestions } from "../../actions/actions.actions";

import { FORM_TYPES } from "../../../ActionOthers/actionOther.constants";

import { ParcelTo } from "../../../../../generated/api/agroevidence";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import {
  SelectedParcelType,
  SelectedZoneType,
} from "../../../ActionOthers/actionOther.types";

type Props = {
  parcels: ParcelTo[];
  parcelsOnly?: boolean;
  zonesOnly?: boolean;
  formType: string;
  formName: string;
  onChange: (selected: SelectedParcelType | SelectedZoneType) => void;
  label: ReactNode;
};

const ParcelsZoneAutocompleteSelector = ({
  formName,
  formType,
  label,
  onChange,
  parcels,
  parcelsOnly = false,
  zonesOnly = false,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const suggestions = useSelector((state: ActionsState) =>
    getParcelsAndZonesSuggestionsAutocomplete(formName)(state),
  );

  const [values, setValues] = useState<
    (SelectedParcelType | SelectedZoneType)[]
  >([]);
  const [inputValue, setInputValue] = useState("");

  const getSuggestionValue = (
    suggestion: SelectedParcelType | SelectedZoneType,
  ) => {
    if ("localName" in suggestion) {
      return `${suggestion.blockNumber ? `${suggestion.blockNumber},` : ""} ${
        suggestion.localName
      }`;
    } else {
      return suggestion.name;
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent,
    selectedValues: (SelectedParcelType | SelectedZoneType)[],
  ) => {
    // using diff for selectedValues and parcels/zones to get newlyAdded one and call onChange
    if (selectedValues?.length > 0) {
      // as removing items is done outside Autocomplete via Formik, only the last added value should be
      // used to prevent buggy behaviour when some items are removed in Formik (but still exist in Autocomplete)
      const lastlyAddedValue = selectedValues[selectedValues.length - 1];
      const filteredSelectedValues = [...values, lastlyAddedValue];
      // we need to filter out parcels and zones and work with them differently
      const zones = filteredSelectedValues.filter((s) => "parcelCount" in s);
      const selectedParcels = filteredSelectedValues.filter(
        (s) => !("parcelCount" in s),
      );
      if (zones.length > 0) {
        const zonesAlreadyInSelect = values.filter((s) => "parcelCount" in s);
        const newlyAddedZone = differenceBy(zones, zonesAlreadyInSelect, "id");
        if (newlyAddedZone.length) {
          // there should always be one zone added
          const newValue = newlyAddedZone[0];
          onChange(newValue);
        }
      }
      const newlyAddedParcels = differenceBy(selectedParcels, parcels, "id");
      if (newlyAddedParcels.length) {
        // there should always be one parcel added
        const newValue = newlyAddedParcels[0];
        if (!some(parcels, (parcel) => parcel.id === newValue.id)) {
          onChange(newValue);
        }
      }
      setValues(filteredSelectedValues);
    }
  };

  const handleRenderGroup = (option: AutocompleteRenderGroupParams) => (
    <Fragment key={option.key}>
      <span className={classes.groupHeading}>
        <FormattedMessage id={option.group} />
      </span>
      {option.children}
    </Fragment>
  );

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SelectedParcelType,
  ) => (
    <li {...props} key={option.id}>
      {getSuggestionValue(option)}
    </li>
  );

  useEffect(() => {
    // Harvest form -> GET only sown parcels
    const isSown =
      formType === FORM_TYPES.HARVEST || formType === FORM_TYPES.MOWING;
    dispatch(
      fetchParcelsAndZonesSuggestions(
        inputValue,
        parcelsOnly,
        zonesOnly,
        isSown ? true : null,
      ),
    );
  }, [inputValue, parcelsOnly, zonesOnly, formType, dispatch]);

  useEffect(() => {
    setValues(parcels);
  }, [parcels]);

  return (
    <Autocomplete
      autoComplete
      blurOnSelect
      filterOptions={() => suggestions}
      getOptionLabel={(option) => getSuggestionValue(option)} // hack to not trigger warning with empty values
      groupBy={(option) => option.title as string}
      id="parcel-zone-selector"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      multiple
      onChange={handleOnChange}
      options={[...values, ...suggestions]}
      renderGroup={handleRenderGroup}
      renderOption={handleRenderOption}
      onInputChange={debounce(
        (event, newInputValue) => setInputValue(newInputValue),
        500,
      )}
      renderInput={(params) => (
        <TextField {...params} fullWidth label={label} />
      )}
      // This will prevent autocomplete from duplicity values in suggestion list
    />
  );
};

export default ParcelsZoneAutocompleteSelector;

const useStyles = makeStyles((theme: Theme) => ({
  groupHeading: {
    paddingLeft: 16,
    textTransform: "uppercase",
    color: theme.palette.grey[500],
    fontWeight: 500,
    display: "flex",
    paddingBottom: 10,
    paddingTop: 10,
  },
}));
