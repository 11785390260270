import React, { useCallback, useEffect, useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Theme,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMaterialsSuggestions,
  getIsFetchingMaterialsSuggestions,
} from "../selectors/stores.selectors";

import {
  fetchMaterialsByName,
  resetMaterials,
} from "../actions/stores.actions";

import { getSourceId } from "../../../../catalogues/helpers/selectorHelpers";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfSwitcherButton from "../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../../../shared/hooks/usePopover";
import {
  getLocalizedDateString,
  getShortDateString,
} from "../../../../shared/misc/timeHelpers";
import { COLOR_SECONDARY } from "../../../../theme";
import { AnyTodo } from "../../../../types";

interface Props {
  onChange: (item: AnyTodo) => void;
  materialTypeId: string;
  error?: boolean;
}

const MaterialSelector = ({
  error = false,
  materialTypeId,
  onChange,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const popover = usePopover();

  const dispatch = useDispatch();
  const suggestions = useSelector(getMaterialsSuggestions);
  const isFetchingSuggestions = useSelector(getIsFetchingMaterialsSuggestions);

  const [showHistorical, setShowHistorical] = useState(false);

  const pathnameEnd = window.location.pathname.split("/").pop();

  const handleGetMaterials = useCallback(
    (search: string) => {
      const usableUntil = !showHistorical ? getShortDateString() : undefined;
      dispatch(fetchMaterialsByName(search, pathnameEnd, usableUntil));
    },
    [dispatch, pathnameEnd, showHistorical],
  );

  useEffect(() => {
    handleGetMaterials("");
    return () => {
      dispatch(resetMaterials());
    };
  }, [dispatch, handleGetMaterials]);

  const debouncedOptions = debounce(handleGetMaterials, 500);

  const handleRenderOption = (option: AnyTodo) => {
    const catalogueType = option.catalogue?.type
      ? intl.formatMessage({ id: getSourceId(option.catalogue.type) })
      : null;

    const usableUntil = (() => {
      switch (materialTypeId) {
        case "FR":
          return option.validTo ? getLocalizedDateString(option.validTo) : "-";
        case "CH":
          return option.validUntil
            ? getLocalizedDateString(option.validUntil)
            : "-";
        default:
          return null;
      }
    })();

    const optionValue = [
      option.name,
      materialTypeId !== "SD" ? catalogueType : (option.varietyName ?? "-"),
      materialTypeId !== "SD" ? usableUntil : null,
    ]
      .filter(Boolean)
      .join(", ");

    if (option.isFavorite) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StarRoundedIcon
            style={{ color: COLOR_SECONDARY.cleverfarm.main, marginRight: 5 }}
          />{" "}
          {optionValue}
        </div>
      );
    }
    return optionValue;
  };

  const resolveTranslateId = (materialTypeId: string) =>
    translateIds.find((type) => type.id === materialTypeId);

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={10}>
        <CfAutocomplete
          error={error}
          id="material-selector"
          isFetching={isFetchingSuggestions}
          isMultiple={false}
          loadOptions={debouncedOptions}
          onChange={onChange}
          renderOption={handleRenderOption}
          suggestions={suggestions}
          testId="material-selector"
          helperText={
            error ? <FormattedMessage id="validation.required" /> : ""
          }
          label={
            <FormattedMessage
              id={resolveTranslateId(materialTypeId)?.placeholder}
            />
          }
        />
      </Grid>
      <Grid item style={{ marginTop: 16 }} xs={2}>
        <Tooltip
          disableInteractive
          title={
            <FormattedMessage
              id={resolveTranslateId(materialTypeId)?.tooltip}
            />
          }
        >
          <span>
            <CfSwitcherButton
              className={showHistorical ? classes.selectedFilter : ""}
              onClick={popover.handlePopoverOpen}
              withArrowDropDownIcon={false}
            >
              <FilterListIcon />
            </CfSwitcherButton>
          </span>
        </Tooltip>
        <Popover
          anchorEl={popover.anchorEl}
          classes={{ paper: classes.popoverPaper }}
          onClose={popover.handlePopoverClose}
          open={popover.isOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <FormGroup>
            <FormControlLabel
              checked={showHistorical}
              control={<Checkbox />}
              data-test="invalid-ppp"
              onChange={() => setShowHistorical(!showHistorical)}
              label={
                <FormattedMessage id="common.selector.filter.showHistory" />
              }
            />
          </FormGroup>
        </Popover>
      </Grid>
    </Grid>
  );
};

const translateIds = [
  {
    id: "FR",
    placeholder: "Stores.fert-name",
    tooltip: "Stores.materialSelector.fertFilter.tooltip",
  },
  {
    id: "CH",
    placeholder: "Stores.por-name",
    tooltip: "Stores.materialSelector.porFilter.tooltip",
  },
  {
    id: "SD",
    placeholder: "Stores.seed-name",
    tooltip: "Stores.materialSelector.seedFilter.tooltip",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    overflow: "hidden",
    padding: 10,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export { MaterialSelector };
