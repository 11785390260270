import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getSeasonsSowingPlan } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";
import { getTool } from "../../selectors/editor.selectors";

import { setSelectedSeasonSowingPlan } from "../../actions/sowingPlan/sowingPlan.actions";

import CfSwitcher from "../../../../shared/components/common/CfSwitcher/CfSwitcher";

const PreviewToolbar = () => {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const seasons = useSelector(getSeasonsSowingPlan);
  const tool = useSelector(getTool);

  const seasonsArray = useMemo(
    () => [
      {
        id: "0",
        season: intl.formatMessage({
          id: "PreviewToolbar.sowingSeason.current",
        }),
        years: "0",
      },
      ...seasons.map((season) => ({
        id: season.id,
        season: `${intl.formatMessage({
          id: "PreviewToolbar.sowingSeason.prefix",
        })} ${season.startYear}/${season.endYear.toString().slice(-2)}`,
        years: `${season.startYear}/${season.endYear.toString().slice(-2)}`,
      })),
    ],
    [intl, seasons],
  );

  const [selectedSeason, setSelectedSeason] = useState<{
    id: string;
    season: string;
    years: string;
  }>(seasonsArray[0]);

  const isDisabledSeasonSelector = !!tool;

  useEffect(() => {
    dispatch(
      setSelectedSeasonSowingPlan(selectedSeason.id, selectedSeason.years),
    );
  }, [dispatch, selectedSeason]);

  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item sm="auto" xs={12}>
        <CfSwitcher
          disabled={isDisabledSeasonSelector}
          getItemId={(item) => item.id}
          getItemValue={(item) => item.season}
          items={seasonsArray}
          onMenuItemClick={setSelectedSeason}
          selectedItemId={selectedSeason.id}
          classes={{
            wrapper: classes.cfSwitcherWrapper,
            button: classes.button,
            buttonPanel: classes.cfSwitcherButtonPanel,
          }}
        />
      </Grid>
    </Grid>
  );
};

export { PreviewToolbar };

const useStyles = makeStyles({
  button: {
    backgroundColor: "#FFFFFF",
    height: "28px",
  },
  cfSwitcherWrapper: {
    justifyContent: "flex-start",
    marginBottom: "5px",
  },
  cfSwitcherButtonPanel: {
    minHeight: "unset",
  },
});
