import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { COLOR_GREY } from "../../../../theme";
import { IndiceType } from "../../selectors/indices";
import { hasMinMax, zonesColors } from "../../services/monitoringZonesColors";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

interface Props {
  index: IndiceType;
  renderItem?: (
    index: Props["index"],
    item: (typeof zonesColors)[Props["index"]][number],
  ) => React.ReactNode;
}

const BioMonitoringZonesLegend = ({ index, renderItem }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.legendWrapper}>
      {zonesColors[index].map((zoneColor) => (
        <span
          className={classes.legendItem}
          key={`legend_zone_${zoneColor.id}`}
        >
          <span
            className={classes.colorRectangle}
            style={{
              backgroundColor: zoneColor.color,
              border: zoneColor?.borderColor
                ? `1px solid ${COLOR_GREY[200]}`
                : "",
            }}
          />
          <span className={classes.label}>
            {renderItem
              ? renderItem(index, zoneColor)
              : renderLabel(index, zoneColor)}
          </span>
        </span>
      ))}
    </div>
  );
};

const renderLabelWithValues = (
  index: Props["index"],
  zoneColor: (typeof zonesColors)[typeof index][number],
) => {
  if (!hasMinMax(zoneColor)) {
    return renderLabel(index, zoneColor);
  }

  return (
    <>
      {renderLabel(index, zoneColor)} (
      <small>
        <CfFormattedNumber decimalDigits={1} value={zoneColor.min} />-
        <CfFormattedNumber decimalDigits={1} value={zoneColor.max} />
      </small>
      )
    </>
  );
};

const renderLabel = (
  index: Props["index"],
  zoneColor: (typeof zonesColors)[typeof index][number],
) => {
  if (index === IndexType.SAVI) {
    if (zoneColor.id === 1) {
      return <FormattedMessage id="BioMonitoring.history.bareClay" />;
    } else {
      return (
        <>
          <FormattedMessage id="BioMonitoring.history.zone" />
          {` ${zoneColor.id - 1}`}
        </>
      );
    }
  }
  return (
    <>
      <FormattedMessage id="BioMonitoring.history.zone" />
      {` ${zoneColor.id}`}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  colorRectangle: {
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: 6,
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

export { renderLabelWithValues, BioMonitoringZonesLegend };
