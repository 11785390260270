import React from "react";

import PercentIcon from "@mui/icons-material/Percent";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";

import { updateBonus } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { CfFormikErrors } from "../../../../types";
import { BonusField } from "../../../components/TelematicsAggregationDetailContent/formComponents";
import { MESSAGES } from "../../../components/TelematicsAggregationDetailContent/validators";
import { useCustomStyles } from "../components/styles/useCustomStyles";
import { telematicsDriversKey } from "../Drivers.api";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 16,
    lineHeight: "140%",
    fontWeight: 400,
    fontStyle: "normal",
    color: theme.palette.grey[400],
  },
}));

const useBonus = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const batchBonusMutation = useBatchBonusMutation();

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );

  const initialValues = {
    bonus: 0,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (typeof values.bonus !== "number") {
      errors.bonus = MESSAGES.required;
    }

    if (values.bonus < -999 || values.bonus > 999) {
      errors.bonus = MESSAGES.bonus;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      drive: selectedRides.map((ride) => parseInt(ride, 10)),
      bonus: values.bonus,
    };
    // TODO use sync version of mutate when all the hooks are refactored
    return batchBonusMutation.mutateAsync(payload);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <BonusField
      customClasses={customClasses}
      disabled={formikProps.isSubmitting}
      endAdornment={<PercentIcon className={classes.icon} />}
      {...formikProps}
    />
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

const useBatchBonusMutation = () => {
  const queryClient = useQueryClient();
  const { farmIds } = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateBonus>[0]) =>
      updateBonus(payload, {
        farmIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export { useBonus };
