import React from "react";

import { Select, SelectProps } from "@mui/material";
import { useField } from "formik";

import { getError } from "../../../misc/formHelpers";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

type Props = {
  children?: React.ReactNode;
  onChange?: () => void;
};

const FormSelect: React.FC<
  Props & Omit<SelectProps, "onChange" | "onBlur" | "value">
> = (props) => {
  const { onChange, ...settings } = props;
  const fieldProps = useField(settings.name || "");
  const field = fieldProps[0];
  const meta = fieldProps[1];
  const form = fieldProps[2];

  return (
    <>
      <Select
        {...field}
        error={!!getError(meta)}
        onChange={(e) => {
          form.setValue(e.target.value);
          if (onChange) {
            onChange();
          }
        }}
        {...settings}
        defaultValue={field.value ?? ""}
      >
        {props.children}
      </Select>
      <FormErrorMessage name={field.name} />
    </>
  );
};

export default FormSelect;
