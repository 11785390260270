import React, { FC, useState, useRef } from "react";

import { IconButton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import RedirectToActionIcon from "../../../assets/img/icons/telematics/redirectToAction.svg";

import { DriveDetailActionTo } from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
  showActionDetailIcon: {
    minHeight: 24,
    minWidth: 24,
  },
});

interface RedirectToActionProps {
  actions: DriveDetailActionTo[];
}

const RedirectToAction: FC<RedirectToActionProps> = ({ actions }) => {
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { farmId } = useParams<{ farmId: string }>();
  const menuItems = actions
    .filter((action) => !isNil(action.actionId))
    .map((action) => ({
      intlId: `common.${action.actionCode}`,
      actionId: action.actionId,
    }));

  const handleGoToAction = (actionId: string) => {
    window.location.assign(`/farm/${farmId}/actions/${actionId}`);
  };

  const handlePopoverOpen = () => setIsOpen(true);

  const handlePopoverClose = () => setIsOpen(false);

  return (
    <div>
      {actions.length > 0 && (
        <Tooltip
          disableInteractive
          title={
            <span>
              <FormattedMessage id="TelematicsAggregations.detail.redirectToAction.tooltip" />
            </span>
          }
        >
          <IconButton
            data-test="redirect-to-actions"
            ref={anchorEl}
            size="large"
            onClick={
              actions.length === 1
                ? () => handleGoToAction(actions[0].actionId!)
                : handlePopoverOpen
            }
          >
            <img
              alt="view telematic ride"
              className={classes.showActionDetailIcon}
              src={RedirectToActionIcon}
            />
          </IconButton>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorEl.current}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map(({ actionId, intlId }, i) => (
          <MenuItem key={i} onClick={() => handleGoToAction(actionId!)}>
            <FormattedMessage id={intlId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default RedirectToAction;
