import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingNitrogenCategory,
  getNitrogenCategory,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import {
  getFertilizerNitrogenCategoriesApi,
  resetFertilizerNitrogenCategoriesApi,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { EagriFertilizerNitrogenCategoryTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues?:
    | EagriFertilizerNitrogenCategoryTo
    | EagriFertilizerNitrogenCategoryTo[];
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  onChange: (
    items:
      | EagriFertilizerNitrogenCategoryTo
      | EagriFertilizerNitrogenCategoryTo[],
  ) => void;
};
type Props = ReduxProps & OwnProps;

const NitrogenCategorySelector = ({
  defaultValues,
  disabled = false,
  error = false,
  getFertilizerNitrogenCategoriesApi,
  isMultiple = false,
  isNitrogenCategoryFetching,
  label,
  nitrogenCategory,
  onChange,
  resetFertilizerNitrogenCategoriesApi,
}: Props) => {
  useEffect(() => {
    getFertilizerNitrogenCategoriesApi();

    return () => {
      resetFertilizerNitrogenCategoriesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="nitrogen-categories-selector"
      isFetching={isNitrogenCategoryFetching}
      isMultiple={isMultiple}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      label={label}
      loadOptions={getFertilizerNitrogenCategoriesApi}
      onChange={onChange}
      suggestions={nitrogenCategory}
      testId="nitrogen-categories-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  nitrogenCategory: getNitrogenCategory(state),
  isNitrogenCategoryFetching: getIsFetchingNitrogenCategory(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerNitrogenCategoriesApi,
      resetFertilizerNitrogenCategoriesApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NitrogenCategorySelector);
