import React from "react";

import {
  Select,
  MenuItem,
  InputBase,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import { SeasonTo } from "../../../../generated/api/satellite";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../shared/components/form/FormSelect/types";

type Props = {
  seasonsOptions: SelectObject<SeasonTo>[];
  selected?: SelectObject<SeasonTo>;
  setSelected: (val: SelectObject<SeasonTo>) => void;
};

const SeasonDropdown = ({ seasonsOptions, selected, setSelected }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedSeason =
      seasonsOptions.find((s) => s.key === event.target.value) ??
      SELECT_EMPTY_OBJ;
    setSelected(selectedSeason);
  };

  return (
    <FormControl style={{ width: "fit-content" }}>
      <CustomSelect
        displayEmpty
        input={<CustomInput />}
        onChange={handleChange}
        value={selected?.key || SELECT_EMPTY_OBJ.key}
        renderValue={(selectedKey) => {
          if (selectedKey === SELECT_EMPTY_OBJ.key) {
            return (
              <span style={{ color: "#CECECE" }}>
                <FormattedMessage id="PrecisionServices.noSeason" />
              </span>
            );
          }
          return (
            seasonsOptions.find((item) => item.key === selectedKey)?.value ||
            SELECT_EMPTY_OBJ.value
          );
        }}
      >
        {seasonsOptions.map((crop) => (
          <CustomMenuItem key={crop.key} value={crop.key}>
            {crop.value}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

const CustomSelect = styled(Select)(() => ({
  backgroundColor: "transparent",
  borderRadius: "8px",
  "& .MuiSelect-select.MuiInputBase-input:focus": {
    backgroundColor: "#EEEEEE",
  },
  "& .MuiSelect-select": {
    padding: 0,
    paddingLeft: 8,
    paddingRight: "40px !important",
  },
  "& .MuiSelect-icon": {
    color: "#00A179", // Arrow color
    right: 8,
  },
  "&.Mui-focused": {
    color: "#00A179",
    fontWeight: 500,
    borderColor: "#00A179",
    borderRadius: 8,
    overflow: "hidden", // Ensure shadow is clipped to the border radius
    backgroundColor: "#EEEEEE",
  },
}));

const CustomInput = styled(InputBase)(() => ({
  fontSize: 24,
  fontWeight: 500,
  "&.Mui-focused": {
    background: "#F7F7F7",
    borderColor: "#00A179", // Add focus color to input
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: "8px 16px",
  color: theme.palette.grey[500],
  fontSize: 12,
  fontWeight: 500,
  "&.Mui-selected": {
    color: theme.palette.common.black,
    backgroundColor: "#f5f5f5",
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#f5f5f5",
    outline: "none",
  },
  "&.Mui-selected:focus": {
    backgroundColor: "#f5f5f5",
    outline: "none",
  },
}));

export { SeasonDropdown };
