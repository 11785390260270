import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import planetDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/planet_disabled.svg";
import planetIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/planet.svg";
import testTubeDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/test-tube_disabled.svg";
import testTubeIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/test-tube.svg";
import {
  DashboardParcelTo,
  PrecisionFarmingProduct,
} from "../../../../../generated/api/satellite";
import { useFarmIds } from "../../../../../shared/api/client.utils";

type Props = {
  products: PrecisionFarmingProduct[];
  data: DashboardParcelTo;
};

const ParcelCellIconBar = ({ data, products }: Props) => {
  const classes = useStyles();
  const disabledColor = "#F6F6F6";

  const planetIsActive = products.includes(
    "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
  );
  const soilIsActive = products.includes("INTERNAL_SOIL_SAMPLES");

  const { farmIds } = useFarmIds();
  const rootUrl = `/farm/${farmIds}/precision-farming/services/${data.externalParcelId}`;

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.managementZones" />}
      >
        {planetIsActive ? (
          <Link
            className={classes.icon}
            to={`${rootUrl}/zone-management`}
            style={{
              background: "#E0F5F0",
            }}
          >
            <img alt="planet" src={planetIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="planet_disabled" src={planetDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.soilSamples" />}
      >
        {soilIsActive ? (
          <Link
            className={classes.icon}
            to={`${rootUrl}/soil-samples`}
            style={{
              background: "#F8E6DB",
            }}
          >
            <img alt="test-tube" src={testTubeIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="test-tube_disabled" src={testTubeDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 24,
    width: 24,
    borderRadius: "100%",
  },
}));

export { ParcelCellIconBar };
