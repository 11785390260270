import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getMonitoringDataError } from "../../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getCropIntervals,
  getIndex,
  getMonitoringStatus,
} from "../../../selectors/monitoring.selectors";

import {
  setMonitoringIndex,
  setMonitoringInterval,
} from "../../../actions/monitoring.actions";

import { LANGUAGE_ID } from "../../../../../shared/lang/lang.constants";

import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import useWidth from "../../../../../shared/hooks/useWidth";
import BioMonitoringStatusWrapper from "../../../components/BioMonitoring/BioMonitoringStatusWrapper";
import { OverallImagesError } from "../../../services/BioMonitoringImageError.services";
import { BiomonitoringIndexTypeSection } from "../BiomonitoringIndexTypeSection/BiomonitoringIndexTypeSection";
import { CropHarvestedError } from "../CropHarvestedError";
import { HeaderBar } from "../HeaderBar";
import { IndexTypeSection } from "../IndexTypeSection/IndexTypeSection";
import { useCommonBioMonitoring } from "../useCommonBioMonitoring";

import { isPlanetIndexType } from "./helpers";

type Props = {
  langId: LANGUAGE_ID;
  ngRedirectToSowing: (parcelId: string[]) => void;
  parcelId: string;
};

const BiomonitoringWrapper = ({
  langId,
  ngRedirectToSowing,
  parcelId,
}: Props) => {
  const dispatch = useDispatch();
  const width = useWidth();
  const index = useSelector(getIndex);
  const error = useSelector(getMonitoringDataError);
  const monitoringStatus = useSelector(getMonitoringStatus);
  const cropIntervals = useSelector(getCropIntervals);

  const { activeIndicesList, isInit } = useCommonBioMonitoring();

  const [cropHarvestedError, setCropHarvestedError] = useState<
    OverallImagesError | null | undefined
  >(undefined);

  const isPlanet = isPlanetIndexType(index);

  return (
    <CfErrorPage error={error}>
      <BioMonitoringStatusWrapper
        langId={langId}
        monitoringStatus={monitoringStatus}
        width={width}
      >
        <>
          {cropHarvestedError && (
            <CropHarvestedError
              cropHarvestedErrorIcon={cropHarvestedError.icon}
              cropHarvestedErrorId={cropHarvestedError.id}
              ngRedirectToSowing={ngRedirectToSowing}
              parcelId={parcelId}
            />
          )}
          <HeaderBar
            cropIntervals={cropIntervals}
            index={index}
            items={activeIndicesList}
            setMonitoringIndex={(i) => dispatch(setMonitoringIndex(i))}
            setMonitoringInterval={(from, to, selectedId) => {
              dispatch(setMonitoringInterval(from, to, selectedId));
            }}
          />
          {isInit && (
            <>
              {isPlanet && (
                <BiomonitoringIndexTypeSection
                  index={index}
                  parcelId={parcelId}
                  setCropHarvestedError={setCropHarvestedError}
                />
              )}
              {!isPlanet && (
                <IndexTypeSection
                  index={index}
                  parcelId={parcelId}
                  setCropHarvestedError={setCropHarvestedError}
                />
              )}
            </>
          )}
        </>
      </BioMonitoringStatusWrapper>
    </CfErrorPage>
  );
};

export { BiomonitoringWrapper };
