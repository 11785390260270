import React, { Fragment, useEffect, useState } from "react";

import OilBarrel from "@mui/icons-material/OilBarrel";
import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { Field, FormikErrors, useField, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import { fetchStoreByMaterialConverted } from "../../../shared/actions/actions.actions";

import { getFertilizerDoseApi } from "../../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { ListItem } from "../../../../../shared/components/common/SelectionItem/ListItem";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../shared/services/Localization.service";
import UnitService from "../../../../../shared/services/Unit.service";
import { COLOR_GREY } from "../../../../../theme";
import { AnyTodo } from "../../../../../types";
import { useStockOut } from "../../hooks/useStockOut";

import { FertilizerNutrientsDose } from "./FertilizerNutrientsDose";
import { FertilizersIncorporationDaysSelector } from "./FertilizersIncorporationDaysSelector";

import {
  FertilizerMineralIntakeResponse,
  FertilizerSuggestionTo,
  UnitType,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ActionEphFormValues, StoreItemMinimalTo } from "../../actionEph.types";

interface Props {
  actionTotalArea: number;
  isEditing: boolean;
  fertilizer: FertilizerSuggestionTo;
  index: number;
  onItemRemove: (index: number, id: string) => void;
  errors: FormikErrors<Partial<ActionEphFormValues>>;
}

export const FertilizersListItem = ({
  actionTotalArea,
  errors,
  fertilizer,
  index,
  isEditing,
  onItemRemove,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionId } = useParams<{ actionId: string }>();

  const [storeAmount, setStoreAmount] = useState<number | undefined>(undefined);
  const [currentFertilizerDose, setCurrentFertilizerDose] = useState<
    FertilizerMineralIntakeResponse | undefined
  >(undefined);

  const { isStockOutActive, onStockOut, setIsStockOutActive } = useStockOut({
    actionTotalArea,
    dosageField: `fertilizers[${index}].dosage`,
    dosePerHectareField: `fertilizers[${index}].dosePerHectare`,
    storeAmount,
  });

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const [currentFertilizer] = useField(`fertilizers[${index}]`);
  const [doseUnit] = useField(`fertilizers[${index}].doseUnit`);
  const [dosePerHectare] = useField(`fertilizers[${index}].dosePerHectare`);

  const { locale } = useTypedIntl();

  const recommendedDoseUnit =
    currentFertilizer.value.defaultUnit ??
    (currentFertilizer.value.preferredUnitType === UnitType.WEIGHT
      ? "kg"
      : "l");
  const defaultUnit = unitsPerHectare.find(
    (unit) => unit.id === recommendedDoseUnit,
  );
  const doseUnitValue = unitsPerHectare.find(
    (unit) => unit.id === doseUnit?.value,
  );

  useEffect(() => {
    setFieldValue(
      `fertilizers[${index}].dosePerHectare`,
      currentFertilizer.value?.dosePerHectare ?? "0",
    );
    setFieldValue(
      `fertilizers[${index}].doseUnit`,
      doseUnitValue ?? defaultUnit,
    );
    setFieldValue(
      `fertilizers[${index}].dosage`,
      currentFertilizer.value?.dosage ?? "0",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!doseUnit?.value?.id) {
      return;
    }

    dispatch(
      fetchStoreByMaterialConverted(
        currentFertilizer.value.id,
        doseUnit.value.id,
        actionId,
      ) as unknown as Promise<unknown>,
    ).then((res: RSAAResultAction<StoreItemMinimalTo>) => {
      if (!res.error && res.payload) {
        setStoreAmount(res.payload.currentBalance);
      } else {
        setStoreAmount(undefined);
      }
    });
  }, [doseUnit?.value?.id, currentFertilizer.value.id, dispatch, actionId]);

  useEffect(() => {
    const dosePerHectareNum = Localization.str2numNonFixed(
      dosePerHectare.value,
      locale,
    );
    (
      dispatch(
        getFertilizerDoseApi(
          fertilizer.id,
          doseUnit.value?.id || defaultUnit?.id,
          (dosePerHectareNum as number) || 0,
        ),
      ) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction<FertilizerMineralIntakeResponse>) => {
      if (!res.error && res.payload) {
        setCurrentFertilizerDose(res.payload);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dosePerHectare.value, doseUnit.value, fertilizer.id, locale]);

  useEffect(() => {
    if (actionTotalArea && dosePerHectare.value) {
      const normalizedDosePerHectare =
        Localization.parseNumberFromLocalizedString(dosePerHectare.value);

      const totalDosage =
        (normalizedDosePerHectare as number) * actionTotalArea;

      setFieldValue(
        `fertilizers[${index}].dosePerHectare`,
        Localization.num2str(normalizedDosePerHectare, locale, 3),
      );
      setFieldValue(
        `fertilizers[${index}].dosage`,
        Localization.num2str(totalDosage, locale, 3),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, actionTotalArea, locale]);

  const updateFieldValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainField: string,
    relatedField: string,
    calculationFn: (value: number) => number,
  ) => {
    const value = event.currentTarget.value;
    const isValidValue = Localization.checkValidStr(value, locale);

    if (!isValidValue) {
      setFieldValue(mainField, value);
      return;
    }

    const normalizedVal = Localization.str2num(value, locale, 5);

    const calculatedValue = Number(
      calculationFn(normalizedVal as number).toFixed(5),
    );

    setFieldValue(
      mainField,
      Localization.num2strNonFixed(normalizedVal, locale),
    );
    setFieldValue(
      relatedField,
      Localization.num2str(calculatedValue, locale, 5),
    );
  };

  const setDosePerHectarUnit = (value: { id: string; name: string }) => {
    setFieldValue(`fertilizers[${index}].doseUnit`, value);
  };

  const setDosePerHectare = (event: React.ChangeEvent<HTMLInputElement>) => {
    const calculateDosage = (value: number) =>
      actionTotalArea !== 0 ? value * actionTotalArea : value;

    updateFieldValues(
      event,
      `fertilizers[${index}].dosePerHectare`,
      `fertilizers[${index}].dosage`,
      calculateDosage,
    );
    setIsStockOutActive(false);
  };

  const setDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const calculateDosePerHectare = (value: number) =>
      actionTotalArea !== 0 ? value / actionTotalArea : value;

    updateFieldValues(
      event,
      `fertilizers[${index}].dosage`,
      `fertilizers[${index}].dosePerHectare`,
      calculateDosePerHectare,
    );
    setIsStockOutActive(false);
  };

  const hasStock = storeAmount && storeAmount > 0;

  return (
    <Fragment>
      <ListItem
        isEditing={isEditing}
        key={fertilizer?.id}
        header={
          <div className={classes.headerRow} data-test="fert-heading">
            <div>
              <div className={classes.name}>{fertilizer?.name}</div>
              <div className={classes.companyName}>
                {fertilizer?.producerTradeName}
              </div>
            </div>
            <FertilizerNutrientsDose fertilizerDose={currentFertilizerDose} />
          </div>
        }
        onRemoveItem={() => {
          onItemRemove(index, currentFertilizer.value.id);
        }}
      >
        <Grid container>
          <Grid item md={5} xs={12}>
            <FertilizersIncorporationDaysSelector
              error={false}
              index={index}
              isDisabled={!isEditing}
            />
          </Grid>
          <Grid
            alignItems="baseline"
            container
            item
            md={7}
            spacing={1}
            wrap="nowrap"
            xs={12}
          >
            <Grid alignItems="flex-end" container item spacing={1} xs={6}>
              <Grid item xs={7}>
                <CfFormControl
                  classes={{ formControl: classes.customFormControl }}
                >
                  <Field
                    component={CfFormikTextField}
                    disabled={!isEditing}
                    label={<FormattedMessage id="common.dosePerHectare" />}
                    name={`fertilizers[${index}].dosePerHectare`}
                    onChange={setDosePerHectare}
                    validateOnBlur
                    error={
                      errors?.fertilizers
                        ? !!(errors?.fertilizers[index] as AnyTodo)
                            ?.dosePerHectare
                        : undefined
                    }
                    helperText={
                      !!(errors?.fertilizers?.[index] as AnyTodo)
                        ?.dosePerHectare && (
                        <FormattedMessage id="validation.number" />
                      )
                    }
                  />
                </CfFormControl>
              </Grid>
              <Grid item xs={5}>
                <CfFormControl
                  classes={{ formControl: classes.customFormControl }}
                >
                  <CfAutocomplete
                    disableClearable
                    disabled={!isEditing}
                    error={false}
                    id="fertilizers-selection-dose-unit"
                    onChange={setDosePerHectarUnit}
                    suggestions={unitsPerHectare}
                    testId="dose-unit-selector"
                    defaultValues={
                      doseUnit?.value &&
                      typeof doseUnit?.value === "object" &&
                      Object.keys(doseUnit.value).length > 0
                        ? doseUnit.value
                        : defaultUnit
                    }
                  />
                </CfFormControl>
              </Grid>
            </Grid>
            <Grid alignItems="center" container item spacing={1} xs={6}>
              <Grid item xs={10}>
                <CfFormControl>
                  <Field
                    component={CfFormikTextField}
                    disabled={!isEditing}
                    name={`fertilizers[${index}].dosage`}
                    onChange={setDosage}
                    validateOnBlur
                    error={
                      errors?.fertilizers
                        ? !!(errors?.fertilizers[index] as AnyTodo)?.dosage
                        : undefined
                    }
                    helperText={
                      !!(errors?.fertilizers?.[index] as AnyTodo)?.dosage && (
                        <FormattedMessage id="validation.number" />
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              color: isEditing
                                ? "black"
                                : "rgba(0, 0, 0, 0.38)",
                              marginRight: "36px",
                            }}
                          >
                            /
                          </span>
                          <span
                            style={{
                              color: COLOR_GREY[400],
                              marginRight: "6px",
                            }}
                          >
                            {storeAmount ?? "-"}
                          </span>
                          <span
                            style={{
                              color: isEditing
                                ? "black"
                                : "rgba(0, 0, 0, 0.38)",
                            }}
                          >
                            {doseUnit?.value?.id}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    label={
                      <>
                        <FormattedMessage id="common.totalDose" /> /{" "}
                        <FormattedMessage id="Por.storeAmount.label" />
                      </>
                    }
                  />
                </CfFormControl>
              </Grid>
              <Grid item xs={2}>
                <Tooltip
                  disableInteractive
                  title={
                    <span>
                      <FormattedMessage id="action.stockOut.button.tooltip" />
                    </span>
                  }
                >
                  <span>
                    <IconButton
                      aria-label="Stock out"
                      disabled={!isEditing || !hasStock}
                      onClick={onStockOut}
                      size="small"
                      className={classNames(classes.stockOutButton, {
                        [classes.stockOutActive]: isStockOutActive,
                      })}
                    >
                      <OilBarrel fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </Fragment>
  );
};

const unitsPerHectare = UnitService.getUnits()
  .filter(
    (item: { id: string; name: string }) =>
      item.id !== "vj" && item.id !== "ml" && item.id !== "g",
  )
  .map((unit) => ({ id: unit.id, name: `${unit.name}/ha` }));

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    fontSize: 16,
    marginRight: 10,
    color: "#8cb586",
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  companyName: {
    fontSize: "12px",
  },
  headerRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  warningMessage: {
    fontSize: 12,
    color: "#B28500",
    paddingLeft: 8,
    paddingBottom: 8,
  },
  customFormControl: {
    margin: "5px 0px 5px 0px",
  },
  stockOutButton: {
    height: 30,
    width: 30,
    padding: 0,
    marginLeft: 4,
    backgroundColor: theme.palette.grey[300],
  },
  stockOutActive: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
