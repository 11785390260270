import { useQuery } from "@tanstack/react-query";

import { NO_CROP } from "../../../../evidence/actions/shared/misc/action.helpers";
import { InternalCropTo } from "../../../../generated/api/satellite";
import { dashboardCropsQuery } from "../../dashboard/ParcelDashboard.api";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";

const useCropOptions = () => {
  const fetchedCrops = useQuery(dashboardCropsQuery(useFarmIds()));
  const cropsOptions = (() => {
    const sortedCrops: SelectObject<InternalCropTo>[] = [];
    let noCrop: SelectObject<InternalCropTo> | undefined;

    fetchedCrops.data?.data?.forEach((c) => {
      const crop = {
        key: c.id,
        value: c.name,
        obj: c,
      } as SelectObject<InternalCropTo>;

      if (c.id === NO_CROP.id) {
        noCrop = crop;
      } else {
        sortedCrops.push(crop);
      }
    });

    return noCrop ? [noCrop, ...sortedCrops] : sortedCrops;
  })();

  return {
    cropsOptions,
    crops: fetchedCrops?.data?.data ?? [],
  };
};

export { useCropOptions };
