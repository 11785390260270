import React, { CSSProperties } from "react";

import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

type Props = {
  colLength: number;
  translId?: string;
  classes?: Record<string, string>;
  style?: CSSProperties;
};

const CfTableBodyEmpty = ({
  children,
  classes: propClasses = {},
  colLength,
  style: propStyle,
  translId = "common.empty-table",
}: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  return (
    <TableBody>
      <TableRow>
        <TableCell
          className={classNames(classes.wrapper, propClasses.wrapper)}
          colSpan={colLength}
          data-test="no-data"
          padding="normal"
          style={propStyle}
        >
          {children ?? <FormattedMessage id={translId} />}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
  },
}));

export default CfTableBodyEmpty;
