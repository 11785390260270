import React, { Suspense } from "react";

import { FormikProps } from "formik";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";

import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { TelematicsDetailDriverSelector } from "../../TelematicsDetailSelectors/TelematicsDetailDriverSelector";

import { DriverTo } from "../../../../shared/api/telematics/telematics.types";

type Props = {
  customClasses?: Record<string, string>;
  date: string;
  disabled?: boolean;
  placeholder?: string;
  placeholderId?: string;
  setSelectedDriver?: (val?: DriverTo | null) => void;
};

const DriverField = ({
  customClasses,
  date,
  disabled,
  errors,
  placeholder,
  placeholderId = "TelematicsList.driver",
  setFieldValue,
  setSelectedDriver,
  values,
}: Props & FormikProps<{ driverCode: string }>) => {
  const handleDriverChange = (driver?: DriverTo | null) => {
    if (setSelectedDriver) {
      setSelectedDriver(driver);
    }
    setFieldValue("driverCode", driver?.code ?? "");
  };
  return (
    <CfFormControl>
      <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<CfLoader />}>
          <TelematicsDetailDriverSelector
            customClasses={customClasses}
            dateFrom={date}
            dateTo={date}
            disabled={disabled}
            hasError={!!errors.driverCode}
            onChange={handleDriverChange}
            placeholder={placeholder}
            selectedDriverCode={values.driverCode}
            helperText={
              errors.driverCode ? (errors.driverCode as string) : undefined
            }
            label={
              placeholder ? undefined : <FormattedMessage id={placeholderId} />
            }
          />
        </Suspense>
      </ErrorBoundary>
    </CfFormControl>
  );
};

export { DriverField };
