import React from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import useWidth from "../../../../shared/hooks/useWidth";
import BioMonitoringIndexSwitcher, {
  IndexSwitcherItemProps,
} from "../../components/BioMonitoring/BioMonitoringIndexSwitcher";
import CropIntervalSwitcher from "../../components/BioMonitoring/CropIntervalSwitcher";
import { IndiceType } from "../../selectors/indices";
import { MonitoringCropIntervals } from "../BioMonitoring";

type Props = {
  setMonitoringIndex: (index: string) => void;
  cropIntervals: MonitoringCropIntervals[];
  index: IndiceType;
  setMonitoringInterval: (
    dateFrom: string,
    dateTo: string,
    selectedId: string,
  ) => void;
  items?: IndexSwitcherItemProps[];
};

const HeaderBar = ({
  cropIntervals,
  index,
  items,
  setMonitoringIndex,
  setMonitoringInterval,
}: Props) => {
  const classes = useStyles();
  const width = useWidth();

  return (
    <Grid container spacing={1}>
      <Grid className={classes.headerBar} item xs={12}>
        <Grid
          alignItems="center"
          container
          justifyContent={width !== "xs" ? "flex-start" : "center"}
        >
          <Grid data-test="bio-monitoring-switchers" item sm="auto" xs={12}>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              spacing={1}
            >
              <Grid item sm="auto" xs={12}>
                <CropIntervalSwitcher
                  cropIntervals={cropIntervals}
                  onMenuItemClick={setMonitoringInterval}
                />
              </Grid>
              <Grid item sm="auto" xs={12}>
                <BioMonitoringIndexSwitcher
                  index={index}
                  items={items}
                  setMonitoringIndex={setMonitoringIndex}
                  width={width}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  headerBar: {
    marginBottom: 5,
  },
}));

export { HeaderBar };
