import React from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";

const ReportOrganicFertilizer = () => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.heading}>
        <PageHeading
          dataTest="report=csv-heading"
          translationId="Reports.organicFertilizer.Heading"
        />
      </span>
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage
            id="Reports.organicFertilizer.Info"
            values={{
              link: (
                <a
                  href="https://www.vurv.cz/2024/08/06/model-oh-pro-ekoplatbu/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  VURV
                </a>
              ),
              br: <br />,
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
    </>
  );
};

export { ReportOrganicFertilizer };

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
}));
