import {
  DriveDetailTo,
  DrivePartDetailTo,
  Type,
} from "../../../generated/api/telematics";
import { Tab } from "../../containers/TelematicsTabs/TelematicsTabs";

import {
  DriveRecalculateTo,
  HandworkCreateTo,
  DriveOverlapRequestTo,
  WinfasTo,
  DriveValidationType,
} from "../../../shared/api/telematics/telematics.types";
import { SharedFormValues } from "../TelematicsAggregationDetailContent/DetailContentForm.types";

interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  driveDetail?: Partial<DriveDetailTo & DrivePartDetailTo>;
  handleReset: () => void;
  handleSave?: (values: HandworkCreateTo) => void;
}

export interface ConnectedProps extends Props {
  calculateArea: (
    driveId: number,
    equipment: DriveRecalculateTo,
    changeHandler: (area: number) => void,
  ) => void;
  dateFilter?: {
    dateFrom: string;
    dateTo: string;
  };
  driveKeys?: WinfasTo | null;
  formPath?: Tab;
  getAggregatedOverlapsApi: (data: DriveOverlapRequestTo) => void;
}

export enum FORM_TYPES {
  CREATE = "CREATE",
  EDIT = "EDIT",
}
export interface TelematicsHandworkFormValues extends SharedFormValues {
  duration: number;
  formType: FORM_TYPES;
  id: number;
  timeFrom: moment.Moment;
  timeIntervals?: string[];
  timeTo: moment.Moment;
  type: Type;
}
