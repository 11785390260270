import React, { useCallback, useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { useQuery } from "@tanstack/react-query";
import { difference, uniq } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getTelematicsAggregationsOrder,
  getTelematicsAggregationsOrderBy,
  getTelematicsAggregationsPage,
  getTelematicsAggregationsRowsPerPage,
  getTelematicsAggregationsTextFilter,
  getTelematicsAggregationsUnapprovedFilter,
  getTelematicsAggregationsBulkEditMode,
  getTelematicsAggregationsSelectedRides,
  getTelematicsAggregationsSelected,
  getTelematicsAggregationsSelectedOnPage,
  getTelematicsAggregationsSelectedRidesOnPage,
} from "../../../../selectors/telematicsAggregations.selectors";
import { selectDateRange } from "../../../../selectors/telematicsTabs.selectors";

import { setAdvancedFilter } from "../../../../../shared/actions/filter.actions";
import {
  setTableSelected,
  setTableSubrowSelected,
} from "../../../../../shared/actions/table.actions";
import { setFocusedRow } from "../../../../actions/telematicsAggregations.actions";
import { setDateRange } from "../../../../actions/telematicsTabs.actions";

import { NAMESPACE } from "../../../../reducer/telematicsAggregations.reducer";
import { NAMESPACE as telListNamespace } from "../../../../reducer/telematicsList.reducer";

import DoubleLinedHeader from "../../../../../catalogues/components/telematics/shared/TCDoubleLinedHeader";
import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import {
  LogbookAggregatedSortColumn,
  LogbookAggregatedTo,
  State,
} from "../../../../../generated/api/telematics";
import { useFarmIds } from "../../../../../shared/api/client.utils";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../shared/misc/helper";
import { TelematicsNgProps } from "../../../../containers/Telematics/Telematics";
import { driversQuery } from "../../Drivers.api";

import { DriverRow } from "./DriverRow";
import { getIsDisabled } from "./RideRow";

const Table = ({
  ngRedirectToMainMapWithFilters,
  setBulkEditMode,
}: { setBulkEditMode: () => void } & TelematicsNgProps) => {
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(NAMESPACE);
  const { onSelect, onSort } = useCfTableHead(NAMESPACE);
  const dispatch = useDispatch();
  const order = useSelector(getTelematicsAggregationsOrder);
  const orderBy = useSelector(getTelematicsAggregationsOrderBy);
  const dateFilter = useSelector(selectDateRange);
  const page = useSelector(getTelematicsAggregationsPage);
  const rowsPerPage = useSelector(getTelematicsAggregationsRowsPerPage);
  const textFilter = useSelector(getTelematicsAggregationsTextFilter);
  const unapprovedOnly = useSelector(getTelematicsAggregationsUnapprovedFilter);
  const bulkEditMode = useSelector(getTelematicsAggregationsBulkEditMode);
  const selected = useSelector(getTelematicsAggregationsSelected);
  const selectedRides = useSelector(getTelematicsAggregationsSelectedRides);
  const drivers = useQuery(
    driversQuery({
      ...dateFilter,
      driver: textFilter !== "" ? textFilter : undefined,
      page: page + 1,
      "per-page": rowsPerPage,
      "sort-col": orderBy,
      "sort-dir": order,
      // @ts-expect-error swagger is prolly a bit off
      state: unapprovedOnly ? State.NOT_APPROVED : undefined,
      ...useFarmIds(),
    }),
  );
  const selectedOnPage = useSelector(
    getTelematicsAggregationsSelectedOnPage(drivers.data?.data),
  );
  const selectedRidesOnPage = useSelector(
    getTelematicsAggregationsSelectedRidesOnPage(drivers.data?.data),
  );

  useEffect(
    () => () => {
      dispatch(setFocusedRow(undefined));
    },
    [dispatch],
  );

  const logbookFilterHandler = useCallback(
    (
      dateFrom: string,
      dateTo: string,
      driver: Record<"code" | "name", string>,
    ) => {
      dispatch(setDateRange(dateFrom, dateTo));
      dispatch(
        setAdvancedFilter(
          { driver: [{ code: driver.code, name: driver.name, validFrom: "" }] },
          telListNamespace,
        ),
      );
    },
    [dispatch],
  );

  const columns = generateColumns(
    bulkEditMode,
    setBulkEditMode,
    drivers.status === "pending",
    drivers.fetchStatus === "fetching",
    drivers.data?.data,
  );

  const driverRows =
    drivers.data?.data.filter((driver) =>
      driver.drives.some((d) => !getIsDisabled(d)),
    ) ?? [];

  return (
    <CfTableWrapper>
      <CfTableHead
        bulkMode={bulkEditMode}
        columns={columns}
        items={drivers.data?.data}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
        checked={
          selectedOnPage.length > 0 &&
          selectedOnPage.length === driverRows.length
        }
        customSelectHandler={(checked?: boolean) => {
          if (checked) {
            if (driverRows.length > 0) {
              dispatch(
                setTableSelected(
                  uniq([...selected, ...driverRows.map((r) => r.drives[0].id)]),
                  NAMESPACE,
                ),
              );
              let subrowsToSelect: string[] = [];
              driverRows.forEach((r) => {
                const validRides = r.drives.filter((d) => !getIsDisabled(d));
                const validIds = validRides.map((r) => r.id);
                subrowsToSelect = subrowsToSelect.concat(validIds);
              });
              dispatch(
                setTableSubrowSelected(
                  uniq([...selectedRides, ...subrowsToSelect]),
                  NAMESPACE,
                ),
              );
            }
          } else {
            dispatch(
              setTableSelected(difference(selected, selectedOnPage), NAMESPACE),
            );
            dispatch(
              setTableSubrowSelected(
                difference(selectedRides, selectedRidesOnPage),
                NAMESPACE,
              ),
            );
          }
        }}
        disabledCheckbox={
          driverRows.length === 0 || drivers.status === "pending"
        }
        indeterminate={
          selectedOnPage.length > 0 && selectedOnPage.length < driverRows.length
        }
      />
      {drivers.status === "pending" ? (
        <CfTableBodyLoader columns={columns} />
      ) : null}
      {drivers.status === "success" && drivers.data.data.length > 0 ? (
        <TableBody>
          {drivers.data.data.map((driver, i) => (
            <DriverRow
              columnsLength={Object.keys(columns).length + 1}
              data={driver}
              dateFilter={dateFilter}
              key={i}
              logbookFilterHandler={logbookFilterHandler}
              ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
              rowId={i}
            />
          ))}
        </TableBody>
      ) : null}
      {drivers.status === "success" && drivers.data.data.length === 0 ? (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      ) : null}
      <CfTableFooter
        count={drivers.data?.count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const generateColumns = (
  bulkEditMode: boolean,
  bulkEditOnClick: () => void,
  isFetching: boolean,
  isRefetching: boolean,
  driverAggregations?: LogbookAggregatedTo[],
) => ({
  [LogbookAggregatedSortColumn.DURATION]: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsAggregations.list.date"
      secondaryId="TelematicsAggregations.list.duration"
    />,
  ),
  "driver.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.driverName"
      secondaryId="TelematicsAggregations.list.driverId"
    />,
  ),
  cultivated: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.cultivated" />,
    { width: 100 },
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.distance" />,
    { width: 100 },
  ),
  time: getColDesc(
    false,
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <FormattedMessage id="TelematicsAggregations.list.time" />
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Tooltip
          title={
            <FormattedMessage id="TelematicsAggregations.list.bulk.editTooltip" />
          }
        >
          <span>
            <IconButton
              aria-label="Bulk editation"
              onClick={bulkEditOnClick}
              size="large"
              style={{ padding: 4 }}
              disabled={
                bulkEditMode || driverAggregations?.length === 0 || isFetching
              }
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        {!isFetching && isRefetching ? <CfLoader size={20} /> : null}
      </div>
    </div>,
  ),
});

export { Table };
