import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  getTelematicsAggregationsTextFilter,
  getTelematicsAggregationsUnapprovedFilter,
  getTelematicsAggregationsBulkEditMode,
} from "../../selectors/telematicsAggregations.selectors";

import {
  setUnapprovedRidesFilter,
  setBulkEditMode,
} from "../../actions/telematicsAggregations.actions";

import { TelematicsNgProps } from "../../containers/Telematics/Telematics";

import { Table } from "./components/Table/Table";
import { TableBulkFilters } from "./components/TableBulkFilters";
import { TableFilters } from "./components/TableFilters";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
}));

const Drivers = ({ ngRedirectToMainMapWithFilters }: TelematicsNgProps) => {
  const dispatch = useDispatch();
  const textFilter = useSelector(getTelematicsAggregationsTextFilter);
  const unapprovedRidesOnly = useSelector(
    getTelematicsAggregationsUnapprovedFilter,
  );
  const bulkEditMode = useSelector(getTelematicsAggregationsBulkEditMode);

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {!bulkEditMode ? (
        <TableFilters
          textFilter={textFilter}
          unapprovedRidesOnly={unapprovedRidesOnly}
          setUnapprovedRidesFilter={(bool) =>
            dispatch(setUnapprovedRidesFilter(bool))
          }
        />
      ) : null}
      {bulkEditMode ? <TableBulkFilters /> : null}
      <Table
        ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
        setBulkEditMode={() => dispatch(setBulkEditMode(true))}
      />
    </div>
  );
};

export { Drivers };
