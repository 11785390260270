import React, { FC } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import {
  CropTo,
  ParcelDetailTo,
  SowingPlanSeasonCropTo,
} from "../../../../../generated/api/agroevidence";
import ParcelCropAssign from "../../../shared/containers/ParcelCropAssign/ParcelCropAssign";
import { START_YEAR_FIRST_SOWING_SEASONS } from "../../../shared/services/Parcels.service";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "auto",
    whiteSpace: "nowrap",
  },
  cell: {
    width: "100px",
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    color: "#333333",
    textAlign: "left",
    padding: "0px 6px",
  },
  row: {
    height: 33,
  },
  headText: {
    margin: 0,
    fontWeight: 600,
  },
  text: {
    fontWeight: 400,
    textTransform: "lowercase",
  },
}));

const startYear = START_YEAR_FIRST_SOWING_SEASONS;

const seasonsTable: { [key: string]: string } = {
  [`${startYear}`]: `${startYear}/${startYear + 1}`,
  [`${startYear + 1}`]: `${startYear + 1}/${startYear + 2}`,
  [`${startYear + 2}`]: `${startYear + 2}/${startYear + 3}`,
  [`${startYear + 3}`]: `${startYear + 3}/${startYear + 4}`,
};

interface ParcelSowingPlanTableProps {
  onCropChange: (cropId: string, seasonId: string, parcelId: string) => void;
  parcel: ParcelDetailTo;
  parcelSowingPlan: SowingPlanSeasonCropTo[];
}

const ParcelSowingPlanTable: FC<ParcelSowingPlanTableProps> = ({
  onCropChange,
  parcel,
  parcelSowingPlan,
}) => {
  const classes = useStyles();

  const handleAssignCrop = (
    crop: CropTo,
    seasonId: string,
    parcelId: string,
  ) => {
    onCropChange(crop.id, seasonId, parcelId);
  };

  return (
    <TableContainer>
      <Table classes={{ root: classes.table }} data-test="table" size="small">
        <TableHead>
          <TableRow classes={{ root: classes.row }}>
            <TableCell classes={{ root: classes.cell }}>
              <div>
                <p className={classes.headText}>
                  <FormattedMessage id="ParcelSowingPlanTable.firstColumnName" />
                </p>
              </div>
            </TableCell>
            {parcelSowingPlan.map((value, i) => (
              <TableCell classes={{ root: classes.cell }} key={i}>
                <div>
                  <p className={classes.headText}>
                    {seasonsTable[value.season.startYear]}
                  </p>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow classes={{ root: classes.row }}>
            <TableCell
              classes={{ root: classNames(classes.cell, classes.text) }}
            >
              {parcel.seedApplication?.seed.crop.name || (
                <FormattedMessage id="common.noCrop" />
              )}
            </TableCell>
            {parcelSowingPlan.map((value, i) => (
              <TableCell classes={{ root: classes.cell }} key={i}>
                <ParcelCropAssign
                  onCropChange={handleAssignCrop}
                  parcel={parcel}
                  sowingPlan={value}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ParcelSowingPlanTable;
