import React, { useContext } from "react";

import { Badge, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getTelematicsAggregationsBulkEditMode } from "../../selectors/telematicsAggregations.selectors";

import TosIcon from "../../../assets/img/icons/telematics/tos.svg";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import {
  payrollSystemStateQuery,
  useSendToPayrollSystemMutation,
} from "../../routes/drivers/Drivers.api";

const PayrollSystemSendButton = () => {
  const {
    on: dialogIsOpen,
    setOff: closeDialog,
    setOn: openDialog,
  } = useToggle();
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const systemStatus = useQuery(payrollSystemStateQuery(useFarmIds()));
  const sendToEconomicSystem = useSendToPayrollSystemMutation();
  const bulkEditMode = useSelector(getTelematicsAggregationsBulkEditMode);

  const handleAccept = () => {
    closeDialog();
    sendToEconomicSystem.mutate(undefined, {
      onError: () => {
        showSnackbar({
          message: <FormattedMessage id="TelematicsExport.error" />,
          isError: true,
        });
      },
    });
  };

  if (
    systemStatus.status === "pending" ||
    sendToEconomicSystem.status === "pending"
  ) {
    return (
      <IconButton aria-label="Send to payroll system" disabled={bulkEditMode}>
        <CircularProgress size={18} />
      </IconButton>
    );
  }

  if (systemStatus.status === "error") {
    return null;
  }

  if (systemStatus.status === "success") {
    if (systemStatus.data.data.count === 0)
      return (
        <img
          alt="send to payroll system"
          className={classes.iconInactive}
          src={TosIcon}
        />
      );

    return (
      <>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={
            <FormattedMessage id="TelematicsAggregations.payrollSystem.tooltip" />
          }
        >
          <Badge
            badgeContent={systemStatus.data.data.count}
            classes={{ badge: classes.badge }}
            className={bulkEditMode ? classes.iconInactive : classes.iconActive}
            color="error"
            onClick={bulkEditMode ? undefined : () => openDialog()}
          >
            <IconButton
              aria-label="Send to payroll system"
              disabled={bulkEditMode}
            >
              <img alt="send to payroll system" src={TosIcon} />
            </IconButton>
          </Badge>
        </Tooltip>
        <CfDialog
          acceptText={<FormattedMessage id="common.confirm" />}
          cancelText={<FormattedMessage id="common.cancel" />}
          onAccept={handleAccept}
          onCancel={closeDialog}
          opened={dialogIsOpen}
          title={
            <FormattedMessage id="TelematicsAggregations.payrollSystem.confirmationDialog.title" />
          }
        >
          <div>
            <p>
              <FormattedMessage
                id="TelematicsAggregations.payrollSystem.confirmationDialog.line1"
                values={{
                  count: systemStatus.data.data.count,
                  b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                }}
              />
            </p>
            <p>
              <FormattedMessage id="TelematicsAggregations.payrollSystem.confirmationDialog.line2" />
              <span className={classes.bold}>
                <FormattedDate value={systemStatus.data.data.dateFrom} />
                {" - "}
                <FormattedDate value={systemStatus.data.data.dateTo} />
              </span>
            </p>
          </div>
        </CfDialog>
      </>
    );
  }

  return null;
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  badge: {
    top: 7,
    right: 11,
  },
  iconActive: {
    cursor: "pointer",
  },
  iconInactive: {
    opacity: 0.5,
  },
  tooltip: {
    maxWidth: 340,
  },
});

export { PayrollSystemSendButton };
