import { queryOptions } from "@tanstack/react-query";

import {
  aggregatedRecords,
  AggregatedRecordsParams,
  logbook,
  LogbookParams,
} from "../../../generated/api/telematics";
import { getCountedData } from "../../../shared/api/client.utils";

const logbookQuery = ({
  aggregated,
  ...params
}: (LogbookParams | AggregatedRecordsParams) & { aggregated: boolean }) =>
  queryOptions({
    queryKey: ["telematics", "logbook", aggregated, ...Object.values(params)],
    queryFn: async () => {
      // typescript harakiri :shrug:
      if (aggregated) {
        const data = await aggregatedRecords(params);
        return getCountedData(data);
      } else {
        const data = await logbook(params);
        return getCountedData(data);
      }
    },
  });

export { logbookQuery };
