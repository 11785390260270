import React from "react";

import DoneIcon from "@mui/icons-material/Done";
import { Button, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";

import {
  DriveValidationType,
  State,
} from "../../../../../generated/api/telematics";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import TelematicsPartialDriveWarning from "../../../../components/TelematicsPartialDriveWarning/TelematicsPartialDriveWarning";
import { useApproveDriveMutation } from "../../Drivers.api";

interface Props {
  driveIds: number[];
  complete: boolean;
  iconTooltipId?: string;
  state: State;
  tooltipId: string;
  validationErrors?: DriveValidationType[];
}

// TODO refactor states in this component
const ApprovalButton = ({
  complete,
  driveIds,
  iconTooltipId = "TelematicsAggregations.incompleteRide.ride.short",
  state,
  tooltipId,
  validationErrors,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const approveDrivesMutation = useApproveDriveMutation();

  const handleApproveClick = () => {
    approveDrivesMutation.mutate({
      drive: driveIds,
      state: State.APPROVED,
    });
  };

  const handleEventPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (state === State.NOT_APPROVED) {
      e.stopPropagation();
    }
    // other states do not behave button-like
  };

  const getIconTooltip = () => {
    if (!validationErrors) return intl.formatMessage({ id: iconTooltipId });
    return `${intl.formatMessage({
      id: "TelematicsAggregations.incompleteRide.short.intro",
    })}
       ${validationErrors
         .map(
           (error) =>
             `- ${intl.formatMessage({
               id: `TelematicsAggregations.incompleteRide.short.${error}`,
             })}`,
         )
         .join("\n")}`;
  };

  return (
    <div className={classes.container} onClick={handleEventPropagation}>
      {state === State.NOT_APPROVED && !complete && (
        <TelematicsPartialDriveWarning
          classes={classes.warning}
          customTooltipTitle={
            <span style={{ whiteSpace: "pre-line" }}>{getIconTooltip()}</span>
          }
        />
      )}
      {state === State.DEFERRED && !complete && (
        <TelematicsPartialDriveWarning
          classes={classes.warning}
          customTooltipTitle={<span style={{ whiteSpace: "pre-line" }} />}
          isPostponedRide
        />
      )}

      {state === State.NOT_APPROVED && (
        <Tooltip title={!complete ? <FormattedMessage id={tooltipId} /> : ""}>
          <Button
            aria-label="Approved ride"
            classes={{ root: classes.notApprovedButton }}
            color="primary"
            disabled={!complete}
            onClick={handleApproveClick}
            variant="contained"
            endIcon={
              approveDrivesMutation.status === "pending" ? (
                <CfLoader classes={{ custom: classes.loader }} size={20} />
              ) : null
            }
          >
            <FormattedMessage id="TelematicsAggregations.approvals.notApproved" />
          </Button>
        </Tooltip>
      )}

      {state === State.DEFERRED && (
        <Tooltip
          title={
            <FormattedMessage id="TelematicsAggregations.incompleteRide.ride.postponed" />
          }
        >
          <span>
            <Button disabled variant="contained">
              <FormattedMessage id="TelematicsAggregations.approvals.notApproved" />
            </Button>
          </span>
        </Tooltip>
      )}

      {state === State.APPROVED && (
        <Button
          classes={{ root: classes.approvedButton }}
          color="primary"
          disabled
          startIcon={<DoneIcon />}
          variant="contained"
        >
          <FormattedMessage id="TelematicsAggregations.approvals.approved" />
        </Button>
      )}

      {state === State.THIRD_PARTY_ACKNOWLEDGED && (
        <Button
          classes={{ root: classes.acknowledgedButton }}
          disabled
          variant="text"
        >
          <FormattedMessage id="TelematicsAggregations.approvals.thirdPartyAcknowledged" />
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: 200,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  notApprovedButton: {
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: "rgba(0, 161, 121, 1)",
      opacity: 0.5,
    },
  },
  approvedButton: {
    "&.Mui-disabled": {
      color: theme.palette.primary.main,
      backgroundColor: "inherit",
    },
  },
  acknowledgedButton: {
    "&.Mui-disabled": {
      color: theme.palette.grey[500],
    },
  },
  warning: {
    height: 24,
    marginRight: 12,
  },
  loader: {
    "& .MuiCircularProgress-circle": {
      stroke: theme.palette.common.white,
    },
  },
}));

export default ApprovalButton;
