import React, { useState } from "react";

import { Grid, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";

import GradientRange from "../../../../shared/components/common/GradientRange/GradientRange";
import { useToggle } from "../../../../shared/hooks/useToggle";
import useWidth from "../../../../shared/hooks/useWidth";
import BioMonitoringHistoryImage from "../../components/BioMonitoring/BioMonitoringHistoryImage";
import { BioMonitoringZonesLegend } from "../../components/BioMonitoring/BioMonitoringZonesLegend";
import PrecisionMap from "../../components/PrecisionMapModal/PrecisionMap";
import PrecisionMapModal from "../../components/PrecisionMapModal/PrecisionMapModal";
import SectionHeader from "../../components/SectionHeader";
import { IndiceType } from "../../selectors/indices";
import {
  Geometries,
  ChartDataOrMonitoringData,
  MapImage,
} from "../BioMonitoring";

import { TimeInterval } from "../../../../shared/api/api.types";
import { BiomonitoringIndexType } from "../../../../shared/api/satellite/satellite.types";

export interface SetMapForModalParams {
  source: Geometries[] | MapImage | null;
  dateFrom: string;
  dateTo: string;
  isImage?: boolean;
}

type Props = {
  monitoringData: ChartDataOrMonitoringData[];
  index: IndiceType;
  parcelId: string;
  lastUpdate?: string;
  infoDialogContent?: JSX.Element;
};

const TilesContainer = ({
  index,
  infoDialogContent,
  lastUpdate,
  monitoringData,
  parcelId,
}: Props) => {
  const width = useWidth();
  const classes = useStyles();
  const { on: modalIsOpen, setOff: closeModal, setOn: openModal } = useToggle();

  const [geometries, setGeometries] = useState<Geometries[] | null>(null);
  const [mapImage, setMapImage] = useState<MapImage | null>(null);
  const [dateModal, setDateModal] = useState<TimeInterval>({
    dateFrom: "",
    dateTo: "",
  });

  const setMapForModal = ({
    dateFrom,
    dateTo,
    isImage = false,
    source,
  }: SetMapForModalParams) => {
    if (isImage) {
      setMapImage(source as MapImage);
      setGeometries(null);
    } else {
      setMapImage(null);
      setGeometries(source as Geometries[]);
    }
    setDateModal({ dateFrom, dateTo });
    openModal();
  };

  return (
    <>
      <Grid data-test="image-history" item xs={12}>
        <Paper>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid
              className={classes.dialogWrapper}
              data-test="bio-monitoring-legend"
              item
              xs={12}
            >
              <SectionHeader
                headingTranslation={<FormattedMessage id="common.history" />}
                infoDialogContent={infoDialogContent}
                lastUpdate={lastUpdate}
                maxDialogWidth="md"
                testId="bio-monitoring-legend-header"
              >
                <div className={classes.imagesLegendWrapper}>
                  {index !== BiomonitoringIndexType.RGB && (
                    <>
                      <div className={classes.legend}>
                        <GradientRange index={index} />
                      </div>
                      <div className={classes.zonesLegend}>
                        <BioMonitoringZonesLegend index={index} />
                      </div>
                    </>
                  )}
                </div>
              </SectionHeader>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={0}>
            {monitoringData.map((item, i) => (
              <BioMonitoringHistoryImage
                item={item}
                key={`${item.dateFrom}-${item.dateTo}`}
                onMapClick={setMapForModal}
                order={i}
                width={width}
              />
            ))}
          </Grid>
        </Paper>
      </Grid>
      {modalIsOpen && (
        <PrecisionMapModal
          onClose={closeModal}
          header={
            <Grid className={classes.modalDateHeading}>
              <FormattedDate
                day="numeric"
                month="numeric"
                value={dateModal.dateFrom}
              />
              {" \u2013 "}
              <FormattedDate
                day="numeric"
                month="numeric"
                value={dateModal.dateTo}
              />
            </Grid>
          }
          legend={
            <>
              {!!mapImage && <GradientRange index={index} />}
              {!!geometries && <BioMonitoringZonesLegend index={index} />}
            </>
          }
          map={
            <PrecisionMap
              geometries={geometries}
              mapImage={mapImage}
              parcelId={parcelId}
            />
          }
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imagesLegendWrapper: {
    marginTop: 10,
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  dialogWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  zonesLegend: {
    marginTop: 6,
    marginBottom: 3,
    marginRight: -15,
    fontSize: 13,
  },
  legend: {
    fontSize: 13,
  },
  heading: {
    margin: 0,
    fontSize: 18,
  },
  historicalStatusPanel: {
    marginTop: 15,
    marginBottom: 30,
  },
  modalDateHeading: {
    paddingLeft: 10,
    fontSize: 13,
    fontWeight: 600,
  },
  [theme.breakpoints.down("xs")]: {
    imagesLegendWrapper: {
      marginTop: -6,
      alignItems: "center",
    },
  },
  [theme.breakpoints.down("xs")]: {
    heading: {
      textAlign: "center",
      marginBottom: 10,
    },
  },
}));

export { TilesContainer };
