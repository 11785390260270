import React, { Fragment, ReactNode } from "react";

import { Breakpoint } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";

import * as satelliteProductsTypes from "../../../../shared/constants/satelliteProductsTypes.constants";
import * as services from "../../../../shared/constants/services.constants";

import CfStatusPanel from "../../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import ServiceNotPurchased from "../../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import { links } from "../../../../shared/constants/links";
import SatelliteIcon from "../../../../shared/icons/SatelliteIcon";

interface Props {
  children?: ReactNode;
  langId: string;
  monitoringStatus?: string;
  width?: Breakpoint;
}

const BioMonitoringStatusWrapper = ({
  children = null,
  langId,
  monitoringStatus,
  width,
}: Props) => (
  <Fragment>
    {monitoringStatus === satelliteProductsTypes.INACTIVE && (
      <CfStatusPanel
        icon={SatelliteIcon}
        linkText={<FormattedMessage id="common.findOutMore" />}
        title={<FormattedMessage id="BioMonitoring.notPurchased.title" />}
        titleWithIcon={true}
        customContent={
          <ServiceNotPurchased serviceId={services.BIOMONITORING} />
        }
        linkHref={
          langId === "cs-CZ"
            ? links.cs.precisionFarming
            : links.en.precisionFarming
        }
      />
    )}
    {monitoringStatus === satelliteProductsTypes.AWAITING_DATA && (
      <CfStatusPanel
        content={<FormattedMessage id="BioMonitoring.triggeredExplanation" />}
        icon={SatelliteIcon}
        title={<FormattedMessage id="BioMonitoring.triggered" />}
      />
    )}
    {(monitoringStatus === satelliteProductsTypes.ACTIVE ||
      monitoringStatus === satelliteProductsTypes.HISTORICAL) && (
      <Fragment>
        {monitoringStatus === satelliteProductsTypes.HISTORICAL && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CfStatusPanel
                dense={true}
                fullWidth={true}
                horizontal={width !== "xs"}
                icon={width !== "xs" ? SatelliteIcon : null}
                linkText={<FormattedMessage id="common.findOutMore" />}
                title={<FormattedMessage id="BioMonitoring.historical" />}
                content={
                  <FormattedMessage
                    id="BioMonitoring.historicalExplanation"
                    values={{
                      b: (chunks: string) => <b>{chunks}</b>,
                    }}
                  />
                }
                linkHref={
                  langId === "cs-CZ"
                    ? links.cs.precisionFarming
                    : links.en.precisionFarming
                }
              />
            </Grid>
          </Grid>
        )}
        {children}
      </Fragment>
    )}
  </Fragment>
);

export default BioMonitoringStatusWrapper;
