import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import reportsUI from "../evidence/reports/reducer/reports.reducer";
import parcelsApi from "../shared/api/agroevidence/parcels/parcels.reducer";
import reportsApi from "../shared/api/agroevidence/reports/reports.reducer";
import zonesApi from "../shared/api/agroevidence/zones/zones.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";

import porReportHistoryUI from "../evidence/reports/reducer/porReportHistoryTableReducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      reports: reportsUI(),
      porReportHistoryTable: porReportHistoryUI(),
      reportParcels: combineReducers({
        additions: parcelsApi("additions"),
        suggestions: parcelsApi("suggestions"),
      }),
    }),
    api: combineReducers({
      zones: zonesApi(),
      reports: reportsApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
