import React, { FC } from "react";

import { Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getTelematicsAggregationsFocusedRow } from "../../../../../shared/api/telematics/aggregations/aggregations.selectors";
import {
  getTelematicsAggregationsBulkEditMode,
  getTelematicsAggregationsRidesStatusUpdate,
  getTelematicsAggregationsSelected,
  getTelematicsAggregationsSelectedRides,
} from "../../../../selectors/telematicsAggregations.selectors";

import { setTableSelected } from "../../../../../shared/actions/table.actions";
import {
  refetchAggregationsSaga,
  setRidesStatusUpdate,
} from "../../../../actions/telematicsAggregations.actions";

import { TELEMATICS_URLS } from "../../../../telematics.constants";

import { NAMESPACE as namespace } from "../../../../reducer/telematicsAggregations.reducer";

import {
  Affiliation,
  LogbookAggregatedItemTo,
  LogbookAggregatedTo,
  State,
} from "../../../../../generated/api/telematics";
import {
  changeDrivesStateApi,
  getTosStatusApi,
} from "../../../../../shared/api/telematics/aggregations/aggregations.api";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTextBadge from "../../../../../shared/components/misc/CfTextBadge/CfTextBadge";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCheckbox from "../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { useFocusedTableRowTarget } from "../../../../../shared/hooks/useFocusedTableRowTarget";
import { Thunk } from "../../../../../types";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../../../../components/TelematicsForeignWarning/TelematicsForeignWarning";
import TelematicsNotSendInfo from "../../../../components/TelematicsNotSendInfo/TelematicsNotSendInfo";
import { useTelematicsContext } from "../../../../containers/Telematics/Context";
import { getDriveBadgeProps, getDuration } from "../../../../helpers";

import ApprovalButton from "./ApprovalButton";
import { PostponedIconButton, getState } from "./PostponedIconButton";

import { TelematicsState } from "../../../../../reducers/telematics.reducer.types";
import { SeedApplicationType } from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  firstCell: {
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
  },
  row: {
    cursor: "pointer",
  },
  bulkRow: {
    cursor: "unset",
  },
  approvals: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 58,
  },
}));

interface Props {
  bulkEditMode: boolean;
  data: LogbookAggregatedItemTo;
  focusedRow?: string;
  isInit?: boolean;
  mainRowData: LogbookAggregatedTo;
  selected: string[];
  selectedRides: string[];
  setTableSelected: (selected: string[], namespace: string) => void;
}

export const getIsApprovedRide = (item: LogbookAggregatedItemTo) =>
  item.state === State.APPROVED ||
  item.state === State.THIRD_PARTY_ACKNOWLEDGED;
export const getIsPostponedRide = (item: LogbookAggregatedItemTo) =>
  item.state === State.DEFERRED;
export const getIsDisabled = (item: LogbookAggregatedItemTo) =>
  getIsApprovedRide(item) || getIsPostponedRide(item);

const RideRow: FC<Props> = ({
  bulkEditMode,
  data,
  focusedRow,
  isInit,
  mainRowData,
  selected,
  selectedRides,
  setTableSelected,
}) => {
  const isApprovedRide =
    data.state === State.APPROVED ||
    data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const isPostponedRide = data.state === State.DEFERRED;
  const isSentToTos = data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: data.id.toString(),
  });

  // todo zmen vsude v telematice
  const { farmId } = useTelematicsContext();

  const getOperationTooltip = () => {
    if (isPostponedRide)
      return intl.formatMessage({ id: "TelematicsList.postponedRide" });
    if (isApprovedRide)
      return intl.formatMessage({ id: "TelematicsList.approvedRide" });
    return intl.formatMessage({ id: "TelematicsList.unApprovedRide" });
  };

  const rowClickRedirectionUrl = `/farm/${farmId}/${TELEMATICS_URLS.drivers}/${data.id}`;
  const handleClick = () => history.push(rowClickRedirectionUrl);
  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 1) {
      window.open(rowClickRedirectionUrl, "_blank", "noopener noreferrer");
    }
  };

  const badgeProps = getDriveBadgeProps(data.type, intl);

  return (
    <TableRow
      className={bulkEditMode ? classes.bulkRow : classes.row}
      onClick={bulkEditMode ? undefined : handleClick}
      onMouseDown={bulkEditMode ? undefined : handleMouseDown}
      ref={targetElementRef}
    >
      {bulkEditMode && (
        <CfTableCheckbox
          disabled={getIsDisabled(data)}
          id={data.id}
          isSubrow
          name="id"
          namespace={namespace}
          selected={selectedRides}
          onCheck={(id, newSelectedRides) => {
            const ridesIds = mainRowData.drives
              .filter((d) => !getIsDisabled(d))
              .map((d) => d.id);
            const allSelected = ridesIds.every((id) =>
              newSelectedRides.includes(id),
            );
            if (allSelected) {
              setTableSelected(
                [...selected, mainRowData.drives[0].id],
                namespace,
              );
            }
          }}
          onUncheck={() => {
            if (selected.includes(mainRowData.drives[0].id)) {
              setTableSelected(
                selected.filter((val) => val !== mainRowData.drives[0].id),
                namespace,
              );
            }
          }}
          tooltipText={
            // eslint-disable-next-line no-nested-ternary
            isPostponedRide
              ? intl.formatMessage({
                  id: "Telematics.validation.checkbox.postponedRide",
                })
              : isApprovedRide
                ? intl.formatMessage({
                    id: "Telematics.validation.checkbox.approvedRide",
                  })
                : undefined
          }
        />
      )}
      <CfTableCell name="duration">
        <div className={classes.firstCell}>
          <CfTextBadge
            backgroundColor={badgeProps.backgroundColor}
            color={badgeProps.color}
            fontSize={badgeProps.fontSize}
            text={badgeProps.text}
            tooltipText={badgeProps.tooltip}
          />
          <span>{getDuration(data.duration)}</span>
        </div>
      </CfTableCell>
      <CfTableCell name="operation">
        <Tooltip title={getOperationTooltip()}>
          <div
            className={classNames({
              [classes.operation]: isApprovedRide,
              [classes.operationNotApproved]: !isApprovedRide,
              [classes.secondaryTextLight]: isPostponedRide,
            })}
          >
            {data.operation ?? "-"}
          </div>
        </Tooltip>
        {!isPostponedRide && isApprovedRide && !isSentToTos && (
          <TelematicsNotSendInfo />
        )}
      </CfTableCell>
      <CfTableCell name="productionOperation">
        <span>{data.productionOperation?.name ?? "-"}</span>
      </CfTableCell>
      <CfTableCell name="parcel">
        <div>
          {data.parcel?.blockNumber ?? "-"}
          {data.parcel?.blockNumber &&
            data.parcel?.affiliation !== Affiliation.EXTERNAL && (
              <span>{` ${data.parcel?.localName}` ?? "-"}</span>
            )}
        </div>
        {data.parcel?.affiliation !== Affiliation.EXTERNAL && (
          <CropName
            className={classes.secondaryText}
            cropType={data.cropType as SeedApplicationType}
          >
            {data.cropName ?? "-"}
          </CropName>
        )}
        {data.parcel?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={data.parcel?.subjectName}
            type={TelematicsForeignWarningType.Parcel}
          />
        )}
      </CfTableCell>
      <CfTableCell name="area">
        <div>
          {" "}
          {data?.cultivated ? (
            <span>
              <CfFormattedNumber decimalDigits={2} value={data.cultivated} />{" "}
              {" ha"}{" "}
            </span>
          ) : (
            "-"
          )}
        </div>
        {data?.parcelArea && (
          <div className={classes.secondaryText}>
            <CfFormattedNumber decimalDigits={2} value={data.parcelArea} />{" "}
            {" ha"}
          </div>
        )}
      </CfTableCell>
      <CfTableCell name="distance">
        <span>
          <CfFormattedNumber decimalDigits={2} value={data?.distance || 0} />{" "}
          {Number(data.distance) >= 0 ? " km" : ""}
        </span>
      </CfTableCell>
      <CfTableCell name="machine">
        <div>{data.machine?.name ?? data.machine?.gpsUnit}</div>
        {data.machine?.licencePlate && (
          <div className={classes.secondaryText}>
            {data.machine?.licencePlate}
          </div>
        )}
        {data.machine?.affiliation === Affiliation.EXTERNAL ? (
          <TelematicsForeignWarning
            text={data.machine?.companyName}
            type={TelematicsForeignWarningType.AdditionalEquipment}
          />
        ) : null}
      </CfTableCell>
      <CfTableCell name="additionalEquipment">
        <div>{data.equipment?.name ?? "-"} </div>
        {data.equipment?.code && (
          <div className={classes.secondaryText}>{data.equipment?.code}</div>
        )}
        {data.equipment?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={data.equipment?.companyName}
            type={TelematicsForeignWarningType.AdditionalEquipment}
          />
        )}
      </CfTableCell>
      <CfTableCell name="approvals">
        <div
          className={classes.approvals}
          style={bulkEditMode ? { visibility: "hidden" } : undefined}
        >
          <ApprovalButton
            complete={data.validation.isComplete}
            driveIds={[parseInt(data.id, 10)]}
            state={data.state}
            tooltipId="TelematicsAggregations.incompleteRide.ride.long"
            validationErrors={data.validation?.validationErrors || []}
          />
          {!isApprovedRide &&
          data.machine?.affiliation === Affiliation.COMPANY ? (
            <PostponedIconButton
              drive={{ driveId: parseInt(data.id, 10), state: data.state }}
              state={getState({
                postponed: isPostponedRide,
              })}
            />
          ) : null}
        </div>
      </CfTableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  ridesStatusUpdate: getTelematicsAggregationsRidesStatusUpdate(state),
  selected: getTelematicsAggregationsSelected(state),
  selectedRides: getTelematicsAggregationsSelectedRides(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
  focusedRow: getTelematicsAggregationsFocusedRow(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      changeState: changeDrivesStateApi,
      refetchAggregationsSaga,
      refetchTosStatus: getTosStatusApi,
      setRidesStatusUpdate,
      setTableSelected,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RideRow);
