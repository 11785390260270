import React, { useState, useCallback } from "react";

import MomentUtils from "@date-io/moment";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { FormattedDate, FormattedMessage } from "react-intl";

import usePopover from "../../../hooks/usePopover";
import useWidth from "../../../hooks/useWidth";

interface Props {
  dateFrom: Moment;
  dateTo: Moment;
  disabled?: boolean;
  minDate?: string;
  setDate: (dateFrom: Moment, dateTo: Moment) => void;
}

const DateRangeSelectorReports = ({
  dateFrom,
  dateTo,
  disabled,
  minDate = "2020-01-01T00:00:00.000Z",
  setDate,
}: Props) => {
  const classes = useStyles();
  const width = useWidth();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } =
    usePopover();
  const [selectedDate, setSelectedDate] = useState<DateRange<Moment>>([
    dateFrom,
    dateTo,
  ]);
  // Use react key attribute to re-mount the StaticDateRangePicker component: https://github.com/mui/mui-x/issues/4542
  const [key, setKey] = useState(0);

  const isDesktop = width !== "sm" && width !== "xs";

  const handleRangeClick = useCallback(
    (range: RangeShortcutType) => {
      switch (range) {
        case rangeShortcuts.lastWeek:
          setSelectedDate([
            moment().subtract(1, "week").startOf("week"),
            moment().subtract(1, "week").endOf("week"),
          ]);
          setKey(key + 1);
          break;
        case rangeShortcuts.lastMonth:
          setSelectedDate([
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ]);
          setKey(key + 1);
          break;
        case rangeShortcuts.currentFinYear:
          if (moment().month() < 6) {
            setSelectedDate([
              moment().subtract(1, "year").month(6).startOf("month"),
              moment().month(5).endOf("month"),
            ]);
          } else {
            setSelectedDate([
              moment().month(6).startOf("month"),
              moment().add(1, "year").month(5).endOf("month"),
            ]);
          }
          setKey(key + 1);
          break;
        case rangeShortcuts.previousFinYear:
          if (moment().month() < 6) {
            setSelectedDate([
              moment().subtract(2, "year").month(6).startOf("month"),
              moment().subtract(1, "year").month(5).endOf("month"),
            ]);
          } else {
            setSelectedDate([
              moment().subtract(1, "year").month(6).startOf("month"),
              moment().month(5).endOf("month"),
            ]);
          }
          setKey(key + 1);
          break;
        case rangeShortcuts.currentYear:
          setSelectedDate([moment().startOf("year"), moment().endOf("year")]);
          setKey(key + 1);
          break;
        case rangeShortcuts.previousYear:
          setSelectedDate([
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year"),
          ]);
          setKey(key + 1);
          break;
        default:
          break;
      }
    },
    [setSelectedDate, key],
  );

  const handleDateSelectCancel = () => {
    setSelectedDate([dateFrom, dateTo]);
    handlePopoverClose();
  };

  const handleDateSelectAccept = () => {
    const newDateFrom = selectedDate[0] ?? dateFrom;
    const newDateTo = selectedDate[1] ?? dateTo;
    setDate(newDateFrom, newDateTo);
    handlePopoverClose();
  };

  return (
    <>
      <Button
        onClick={handlePopoverOpen}
        variant="contained"
        className={classNames(classes.dateButton, {
          [classes.disabled]: disabled,
        })}
      >
        <div className={classes.wrapper}>
          <span className={classes.dateContent} data-test="advanced-calendar">
            <FormattedDate
              day="2-digit"
              month="2-digit"
              value={selectedDate[0]?.toISOString()}
              year="numeric"
            />
            {" – "}
            <FormattedDate
              day="2-digit"
              month="2-digit"
              value={selectedDate[1]?.toISOString()}
              year="numeric"
            />
          </span>
          <InsertInvitationOutlinedIcon
            className={classes.icon}
            data-test="advanced-calendar-icon"
          />
        </div>
      </Button>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handleDateSelectCancel}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className={classes.boxDateRangeSelector} key={key} mb={2} mt={2}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <StaticDateRangePicker
              calendars={isDesktop ? 2 : 1}
              className={classes.dateRangePicker}
              displayStaticWrapperAs="desktop"
              minDate={moment(minDate)}
              renderInput={() => <div />}
              value={selectedDate}
              onChange={(newValue: DateRange<Moment>) => {
                setSelectedDate(newValue);
              }}
            />
          </LocalizationProvider>

          <Divider
            className={classes.notDisplayOnMobile}
            flexItem
            orientation="vertical"
          />
          <div className={classes.rangesSection}>
            <List className={classes.notDisplayOnMobile}>
              {rangeShortcutsLabel.map(({ label, range }) => (
                <ListItem disablePadding key={range}>
                  <ListItemButton
                    data-test={range}
                    onClick={() => handleRangeClick(range as RangeShortcutType)}
                  >
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        fontSize: 14,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box className={classes.buttonGroup}>
              <Button
                color="inherit"
                data-test="cancel-date"
                onClick={handleDateSelectCancel}
                sx={{ mr: 1 }}
                variant="contained"
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                color="primary"
                data-test="confirm-date"
                onClick={handleDateSelectAccept}
                variant="contained"
              >
                <FormattedMessage id="common.select" />
              </Button>
            </Box>
          </div>
        </Box>
      </Popover>
    </>
  );
};

export { DateRangeSelectorReports };

const rangeShortcuts = {
  lastWeek: "LAST_WEEK",
  lastMonth: "LAST_MONTH",
  currentFinYear: "CURRENT_FIN_YEAR",
  previousFinYear: "PREVIOUS_FIN_YEAR",
  currentYear: "CURRENT_YEAR",
  previousYear: "PREVIOUS_YEAR",
};

type RangeShortcutType = keyof typeof rangeShortcuts;

const rangeShortcutsLabel = [
  {
    range: rangeShortcuts.lastWeek,
    label: <FormattedMessage id="common.dateRangeSelector.lastWeek" />,
  },
  {
    range: rangeShortcuts.lastMonth,
    label: <FormattedMessage id="common.dateRangeSelector.lastMonth" />,
  },
  {
    range: rangeShortcuts.currentFinYear,
    label: <FormattedMessage id="common.dateRangeSelector.currentFinYear" />,
  },
  {
    range: rangeShortcuts.previousFinYear,
    label: <FormattedMessage id="common.dateRangeSelector.previousFinYear" />,
  },
  {
    range: rangeShortcuts.currentYear,
    label: <FormattedMessage id="common.dateRangeSelector.currentYear" />,
  },
  {
    range: rangeShortcuts.previousYear,
    label: <FormattedMessage id="common.dateRangeSelector.previousYear" />,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    height: 32,
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
  },
  dateButton: {
    background: theme.palette.grey[300],
    padding: 0,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  dateContent: {
    margin: "auto 12px",
    paddingTop: 1,
  },
  icon: {
    margin: "auto 12px auto 6px",
    width: 22,
  },
  popoverPaper: {
    overflow: "auto",
    marginTop: 7,
  },
  rangesSection: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  notDisplayOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  boxDateRangeSelector: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  buttonGroup: {
    textAlign: "right",
    padding: "0 16px",
  },
  dateRangePicker: {
    "& .MuiTypography-subtitle1": {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: 260,
    },
    "& .css-f7iyql": {
      padding: 0,
    },
  },
  disabled: {
    backgroundColor: "#C5C5C5 !important",
    background: "#C5C5C5 !important",
    color: "#929292",
  },
}));
