import React, { Fragment } from "react";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { withStyles } from "@mui/styles";
import map from "lodash/map";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { AdminIcon } from "../../../../shared/icons/navbar/AdminIcon";
import { ChangePasswordIcon } from "../../../../shared/icons/navbar/ChangePasswordIcon";
import { FarmIcon } from "../../../../shared/icons/navbar/FarmIcon";
import { ImpersonationIcon } from "../../../../shared/icons/navbar/ImpersonationIcon";
import { IntegrationsIcon } from "../../../../shared/icons/navbar/IntegrationsIcon";
import { LocalizationIcon } from "../../../../shared/icons/navbar/LocalizationIcon";
import { LogoutIcon } from "../../../../shared/icons/navbar/LogoutIcon";
import { ProfileIcon } from "../../../../shared/icons/navbar/ProfileIcon";
import { UpdateLpisIcon } from "../../../../shared/icons/navbar/UpdateLpisIcon";
import Localization from "../../../../shared/services/Localization.service";
import useToggles from "../../../../shared/toggles/toggles";
import TOGGLES from "../../../../shared/toggles/toggles.const";
import NavbarService from "../../services/NavbarService";
import CollapseSwitchMenu from "../CollapseSwitchMenu/CollapseSwitchMenu";
import { ContextMenuItem } from "../ContextMenuItem/ContextMenuItem";
import UserAvatar from "../UserAvatar/UserAvatar";

const styles = {
  desktopSeparator: {
    margin: "8px 0px",
  },
  mobileSeparator: {
    margin: "16px 0px",
  },
  userHeader: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  username: {
    fontWeight: 500,
  },
  mobileGutters: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  MuiListItemIcon_root: {
    minWidth: "unset",
  },
};

// todo: "a" is temporary
// change to "component={Link}" and "to={}" after remove angular and updating react-router-dom to version 6 (or higher)

// eslint-disable-next-line
const UserMenu = React.forwardRef((props, ref) => {
  const { classes, mobile, ngProps, onMenuItemClick, updatePosition } = props;
  const {
    farm,
    farms,
    isAdmin,
    langId,
    langs,
    logout,
    ngImpersEnable,
    ngSwitchFarm,
    user,
  } = ngProps;
  const username = NavbarService.getUsername(user);
  // eslint-disable-next-line no-unused-vars
  const [_, __, isToggleActive] = useToggles();

  return (
    <div>
      <ListItem
        className={classes.userHeader}
        dense={true}
        classes={{
          gutters: mobile ? classes.mobileGutters : "",
        }}
      >
        <ListItemIcon
          classes={{
            root: classes.MuiListItemIcon_root,
          }}
        >
          <UserAvatar grey={true} user={user} />
        </ListItemIcon>
        <ListItemText
          primary={username}
          secondary={farm.name}
          secondaryTypographyProps={{ id: "user-menu-farmname" }}
          primaryTypographyProps={{
            variant: "subtitle2",
            id: "user-menu-username",
          }}
        />
      </ListItem>
      <hr
        className={mobile ? classes.mobileSeparator : classes.desktopSeparator}
      />
      <CollapseSwitchMenu
        currentItemId={farm.id}
        icon={<FarmIcon />}
        items={farms.sort((a, b) => a.name.localeCompare(b.name))}
        label={<FormattedMessage id="UserMenu.farm" />}
        mobile={mobile}
        onMenuItemClick={ngSwitchFarm}
        switchId="farm"
      />
      <CollapseSwitchMenu
        currentItemId={langId}
        icon={<LocalizationIcon />}
        label={<FormattedMessage id="UserMenu.language" />}
        mobile={mobile}
        switchId="language"
        items={map(langs, (lang, langKey) => ({
          id: langKey,
          name: lang.NAME,
        }))}
        onMenuItemClick={(newLangId) => {
          Localization.setLang(newLangId);
          setTimeout(() => {
            updatePosition();
          }, 0);
        }}
      />
      <hr
        className={mobile ? classes.mobileSeparator : classes.desktopSeparator}
      />
      <ContextMenuItem
        component="a"
        href={`/farm/${farm.id}/user/integrations`}
        icon={<IntegrationsIcon />}
        id="user-menu-integrations"
        label={<FormattedMessage id="UserMenu.myIntegrations" />}
        mobile={mobile}
        onClick={onMenuItemClick}
      />
      <ContextMenuItem
        component="a"
        href={`/farm/${farm.id}/user/profile`}
        icon={<ProfileIcon />}
        id="user-menu-profile"
        label={<FormattedMessage id="UserMenu.myProfile" />}
        mobile={mobile}
        onClick={onMenuItemClick}
      />
      {farm.customer.countryCode === "CZ" &&
        isToggleActive(TOGGLES.LPIS_UPDATE_TOGGLE.name) && (
          <ContextMenuItem
            component="a"
            href={`/farm/${farm.id}/user/updatelpis`}
            icon={<UpdateLpisIcon />}
            id="user-menu-update-lpis"
            label={<FormattedMessage id="UserMenu.updateLpis" />}
            mobile={mobile}
            onClick={onMenuItemClick}
          />
        )}
      <ContextMenuItem
        component="a"
        href={`/farm/${farm.id}/user/password`}
        icon={<ChangePasswordIcon />}
        id="user-menu-password"
        label={<FormattedMessage id="UserMenu.changePassword" />}
        mobile={mobile}
        onClick={onMenuItemClick}
      />
      {isAdmin && (
        <ContextMenuItem
          component="a"
          href={`/farm/${farm.id}/admin/farms`}
          icon={<AdminIcon />}
          id="user-menu-admin"
          label={<FormattedMessage id="UserMenu.admin" />}
          mobile={mobile}
          onClick={onMenuItemClick}
        />
      )}
      {isAdmin && (
        <ContextMenuItem
          icon={<ImpersonationIcon />}
          id="user-menu-impersonation"
          label={<FormattedMessage id="UserMenu.impersonation" />}
          mobile={mobile}
          onClick={() => {
            onMenuItemClick();
            ngImpersEnable();
          }}
        />
      )}
      {!mobile && (
        <Fragment>
          <hr
            className={
              mobile ? classes.mobileSeparator : classes.desktopSeparator
            }
          />
          <ContextMenuItem
            icon={<LogoutIcon />}
            id="user-menu-logout"
            label={<FormattedMessage id="UserMenu.logout" />}
            mobile={mobile}
            onClick={logout}
          />
        </Fragment>
      )}
    </div>
  );
});

UserMenu.propTypes = {
  onMenuItemClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  ngProps: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  updatePosition: PropTypes.func,
};

UserMenu.defaultProps = {
  onMenuItemClick: () => {},
  mobile: false,
  updatePosition: () => {},
};

export default withStyles(styles)(UserMenu);
