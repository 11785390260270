import { useState } from "react";

import { TableSortLabelProps } from "@mui/material";

const useTableHead = () => {
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState<TableSortLabelProps["direction"]>("asc");

  const onSort = (orderPar: string, colId: string) => {
    setOrderBy(colId);
    setOrder(orderPar as TableSortLabelProps["direction"]);
  };

  return {
    orderBy,
    order,
    onSort,
  };
};

export { useTableHead };
