import React, { Suspense } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  EquipmentTo,
  updateEquipment1,
} from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { stringDateToMoment } from "../../../../shared/misc/timeHelpers";
import { CfFormikErrors } from "../../../../types";
import { MESSAGES } from "../../../components/TelematicsAggregationDetailContent/validators";
import { TelematicsDetailEquipmentSelector } from "../../../components/TelematicsDetailSelectors/TelematicsDetailEquipmentSelector";
import { useCustomStyles } from "../components/styles/useCustomStyles";
import { telematicsDriversKey } from "../Drivers.api";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

const useEquipment = () => {
  const intl = useIntl();
  const updateEquipmentMutation = useBatchEquipmentMutation();
  const customClasses = useCustomStyles();
  const [selectedEquipment, setSelectedEquipment] = React.useState<
    EquipmentTo | null | undefined
  >(undefined);

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );
  const dateFrom = useSelector(
    (state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom,
  );
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers =
      dateFromMoment.toISOString() ?? moment().startOf("day").toISOString();
  }

  const initialValues = {
    equipmentCode: "",
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.equipmentCode) {
      errors.equipmentCode = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = () => {
    const payload = {
      drive: selectedRides.map((ride) => parseInt(ride, 10)),
      equipment: selectedEquipment?.id ?? -1,
    };

    // TODO use sync version of mutate when all the hooks are refactored
    return updateEquipmentMutation.mutateAsync(payload);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <CfFormControl>
      <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<CfLoader />}>
          <TelematicsDetailEquipmentSelector
            customClasses={customClasses}
            dateFrom={dateForClassifiers}
            dateTo={dateForClassifiers}
            disabled={formikProps.isSubmitting}
            hasError={!!formikProps.errors.equipmentCode}
            helperText={(formikProps.errors.equipmentCode as string) ?? ""}
            selected={{ code: formikProps.values.equipmentCode, name: "" }}
            onChange={(equipment: EquipmentTo | null) => {
              formikProps.setFieldValue("equipmentCode", equipment?.code ?? "");
              setSelectedEquipment(equipment);
            }}
            placeholder={intl.formatMessage({
              id: "TelematicsAggregations.list.bulk.secondary.placeholder.equipment",
            })}
          />
        </Suspense>
      </ErrorBoundary>
    </CfFormControl>
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

const useBatchEquipmentMutation = () => {
  const queryClient = useQueryClient();
  const { farmIds } = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateEquipment1>[0]) =>
      updateEquipment1(
        {
          drive: payload.drive,
          equipment: payload.equipment,
        },
        {
          farmIds,
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export { useEquipment };
