import React, { useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setFocusedRow } from "../../../../core/precision/actions/precision.actions";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { InternalCropTo, SeasonTo } from "../../../../generated/api/satellite";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../shared/misc/helper";
import { Thunk } from "../../../../types";

import { useTableFooter } from "../hooks/useTableFooter";
import { useTableHead } from "../hooks/useTableHead";
import TableRow from "./TableRow";
import { servicesQuery } from "../PrecisionServices.api";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { AdvancedFilterType } from "../../../components/AdvancedFilter";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";

const columns = {
  name: getColDesc(
    true,
    <FormattedMessage id="PrecisionServices.columns.parcel" />,
    {
      paddingLeft: 21,
    },
  ),
  blockNr: getColDesc(
    false,
    <FormattedMessage id="PrecisionServices.columns.lpisCode" />,
  ),
  area: getColDesc(
    true,
    <span>
      <FormattedMessage id="PrecisionServices.columns.area" />
    </span>,
  ),
  crop: getColDesc(
    true,
    <span>
      <FormattedMessage id="common.crop" />
    </span>,
  ),
  yield: getColDesc(
    true,
    <span style={{ width: "100px" }}>
      <FormattedMessage id="PrecisionServices.columns.yield" />
    </span>,
  ),
  services: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionServices.columns.activeServices" />
    </span>,
  ),
  actions: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionServices.columns.actions" />
    </span>,
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
  setTotalArea: (val: number) => void;
  textFilter: string;
  season?: SeasonTo;
  advancedFilter: AdvancedFilterType;
  cropsOptions: SelectObject<InternalCropTo>[];
};
type Props = ReduxProps & OwnProps;

export interface PrecisionTableProps {
  count: number;
  farmId: string;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
}

const Table = ({
  advancedFilter,
  farmId,
  season,
  setFocusedRow,
  setTotalArea,
  textFilter,
  cropsOptions,
}: Props) => {
  const { onSort, order, orderBy } = useTableHead();
  const { onPageChange, onRowsPerPageChange, page, rowsPerPage } =
    useTableFooter();

  const crops = advancedFilter?.crops?.map((c) => c.id);
  const { data: parcelsData, isPending } = useQuery(
    servicesQuery(season?.id ?? "", {
      farmIds: farmId,
      search: textFilter,
      crops,
      "sort-dir": order,
      "sort-col": orderBy,
      "per-page": rowsPerPage,
      page: page + 1,
    }),
  );

  const tableData = parcelsData?.data?.length
    ? parcelsData?.data[0].parcels
    : [];
  const count = parcelsData?.count ?? 0;

  useEffect(() => {
    const totalArea = parcelsData?.data[0]?.totalAreaHa ?? 0;
    setTotalArea(totalArea);
  }, [parcelsData, setTotalArea]);

  useEffect(
    () => () => {
      setFocusedRow(undefined);
    },
    [setFocusedRow],
  );

  return (
    <CfTableWrapper testId="precision-services-list">
      <CfTableHead
        columns={columns}
        items={tableData}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {isPending && <CfTableBodyLoader columns={columns} />}

      {tableData.length ? (
        <TableBody>
          {tableData.map((parcel) => (
            <TableRow
              cropsOptions={cropsOptions}
              farmId={farmId}
              isInit={!isPending && count > 0}
              key={parcel?.parcelId}
              parcel={parcel}
              season={season}
            />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps);

export default connector(Table);
