import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";

import {
  DashboardParcelTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import { useDelayedLoader } from "../../../hooks/useDelayedLoader";
import { useUpdateSeasonMutation } from "../../dashboard/ParcelDashboard.api";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import { satelliteServicesKey } from "../PrecisionServices.api";
import { YieldInput } from "./YieldInput";
import { isNil } from "lodash";
import { Theme } from "@mui/material";

type Props = {
  parcel: DashboardParcelTo;
  season?: SeasonTo;
};

const YieldCell = ({ parcel, season }: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();
  const [edit, setEdit] = useState(false);

  const queryClient = useQueryClient();
  const updateSeasonMutation = useUpdateSeasonMutation();

  const [mutatedParcel, setMutatedParcel] = useState<
    DashboardParcelTo | undefined
  >();

  const { isDelayed } = useDelayedLoader(updateSeasonMutation.isPending, 600);
  const parcelToUse = mutatedParcel ?? parcel;

  const handleChange = (e: React.SyntheticEvent, newYield?: number) => {
    e.stopPropagation();

    updateSeasonMutation.mutate(
      {
        cropInternalId: parcelToUse.cropInternalId ?? "",
        parcelId: parcelToUse.parcelId ?? "",
        seasonId: season?.id ?? "",
        yieldInTonnes: newYield,
      },
      {
        onSuccess: (data) => {
          const respParcels = data?.data?.parcels ?? [];
          if (respParcels.length) {
            setMutatedParcel(respParcels[0]);
          }
          queryClient.invalidateQueries({
            queryKey: satelliteServicesKey,
            refetchType: "inactive",
          });
        },
      },
    );
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else if (edit) {
    Jsx = (
      <YieldInput
        handleChange={handleChange}
        setEdit={setEdit}
        value={parcelToUse.yieldInTonnes}
      />
    );
  } else if (!edit) {
    Jsx = isNil(parcelToUse.yieldInTonnes) ? (
      "-"
    ) : (
      <span
        className={classes.placeholder}
        onClick={(e) => {
          e.stopPropagation();
          setEdit(true);
        }}
      >
        {parcelToUse.yieldInTonnes} ha
      </span>
    );
  }

  return <div className={classes.wrapper}>{Jsx}</div>;
};

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    maxWidth: 100,
  },
  placeholder: {
    padding: 8,
    paddingLeft: 0,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { YieldCell };
