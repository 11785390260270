import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";

import { seedApplicationAtDateQuery } from "../../../../shared/api/agroevidence/seedApplications/seedApplication.api";
import { useFarmIds } from "../../../../shared/api/client.utils";
import Localization from "../../../../shared/services/Localization.service";

import { ParcelTo } from "../../../../shared/api/agroevidence/agroevidence.types";
import { ActionOtherFormValues } from "../actionOther.types";

const useSeedApplications = () => {
  const { setFieldValue } = useFormikContext<ActionOtherFormValues>();

  const [actionDate] = useField("date");
  const [parcels] = useField("parcels");

  const parcelIds = parcels.value.map((parcel: ParcelTo) => parcel.id);

  const seedApplications = useQuery({
    ...seedApplicationAtDateQuery({
      params: {
        ...useFarmIds(),
        "parcel-ids": parcelIds.join(","),
        date: actionDate.value.format("YYYY-MM-DD"),
        locale: Localization.getLangShortCode(),
      },
    }),
    enabled: parcelIds.length > 0,
  });

  useEffect(() => {
    setFieldValue("seedApplications", seedApplications.data);
  }, [seedApplications.data, setFieldValue]);

  return {
    seedApplications,
  };
};

export { useSeedApplications };
