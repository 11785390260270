import React, { ReactNode, useEffect, useMemo } from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsZonesFetching,
  getZones,
} from "../../../../../../shared/api/agroevidence/zones/zones.selectors";

import { getZonesApi } from "../../../../../../shared/api/agroevidence/zones/zones.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { ZoneTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

export interface ZoneSelectorProps {
  defaultZones?: ZoneTo[];
  label: ReactNode;
  onChange: (items: ZoneTo[]) => void;
}

const ZoneSelector = ({
  defaultZones = [],
  label,
  onChange,
}: ZoneSelectorProps) => {
  const dispatch = useDispatch();
  const suggestions = useSelector(getZones);
  const isZoneFetching = useSelector(getIsZonesFetching);

  const intl = useIntl();

  useEffect(() => {
    dispatch(getZonesApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extendedSuggestions = useMemo(
    () => [
      {
        id: "NONE",
        name: intl.formatMessage({ id: "ParcelDetailOverview.noZoneOption" }),
      },
      ...suggestions,
    ],
    [suggestions, intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultZones}
      id="zone-selector"
      isFetching={isZoneFetching}
      isMultiple={true}
      label={label}
      loadOptions={getZonesApi}
      onChange={onChange}
      suggestions={extendedSuggestions}
      testId="zone-filter"
    />
  );
};

export { ZoneSelector };
