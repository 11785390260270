import React from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useFarmIds } from "../../../shared/api/client.utils";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { driversQuery } from "../TelematicsAdvancedFilter/DriverSelector/DriverSelector.api";

import { DriverTo } from "../../../shared/api/telematics/telematics.types";

type Props = {
  customClasses?: Record<string, string>;
  dateFrom: string;
  dateTo: string;
  disabled?: boolean;
  hasError: boolean;
  helperText?: React.ReactNode;
  label: React.ReactNode;
  onChange(item?: DriverTo | null): void;
  placeholder?: string;
  selectedDriverCode: string;
};

const TelematicsDetailDriverSelector = ({
  customClasses,
  dateFrom,
  dateTo,
  disabled,
  hasError,
  helperText,
  label,
  onChange,
  placeholder,
  selectedDriverCode,
}: Props) => {
  const classes = useStyles();
  const drivers = useSuspenseQuery(
    driversQuery({
      ...useFarmIds(),
      dateTo,
      dateFrom,
    }),
  );

  let selectedDrive = { code: "", name: "" };
  if (selectedDriverCode) {
    const option = drivers.data.data.find(
      (driver) => driver.code === selectedDriverCode,
    );
    selectedDrive = { code: option?.code ?? "", name: option?.name ?? "" };
  }

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={selectedDrive}
      disabled={disabled}
      error={hasError}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-driver-selector"
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      suggestions={drivers.data.data}
      testId="telematics-detail-driver-selector"
      renderOption={(option) => (
        <div>
          <div>{option.name ?? option.code}</div>
          <div className={classes.id}>{option.code}</div>
        </div>
      )}
    />
  );
};

const handleGetSelected = (option: DriverTo, value?: DriverTo | null) =>
  option.code === value?.code;

const handleGetLabel = (option?: DriverTo | null) => {
  if (!option) return "";
  if (option.name) return `${option.name} (${option.code})`;
  return option.code;
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

export { TelematicsDetailDriverSelector };
