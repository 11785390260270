import React, { FC } from "react";

import { DriveDetailTo } from "../../../generated/api/telematics";
import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import {
  getState,
  PostponedIconButton,
} from "../../routes/drivers/components/Table/PostponedIconButton";

import DeleteDriveButtonWithModal from "./DeleteDriveButtonWithModal";
import Heading from "./Heading";
import LinkArrows from "./linkArrows/LinkArrows";
import RedirectToAction from "./RedirectToAction";
import { useTelematicsAggregationDetailContentStyles } from "./styles";

import {
  Affiliation,
  State,
} from "../../../shared/api/telematics/telematics.types";

interface Props {
  driveDetail: DriveDetailTo;
  handleApproveClick: () => void;
  handleDeleteDrive?: () => void;
  handleGoBack: () => void;
  isApproved: boolean;
  isEditing: boolean;
  isPostponed: boolean;
}

const HeaderWithRelatedRides: FC<Props> = ({
  driveDetail,
  handleApproveClick,
  handleDeleteDrive,
  handleGoBack,
  isApproved,
  isEditing,
  isPostponed,
}) => {
  const classes = useTelematicsAggregationDetailContentStyles();
  return (
    <div className={classes.headerWrapper}>
      <PageHeader
        backButton={<CfBackButton onClick={handleGoBack} />}
        actionButtons={
          <div className={classes.headerActionButtons}>
            {!!driveDetail.actions?.length && (
              <RedirectToAction actions={driveDetail.actions} />
            )}
            {!isApproved && handleDeleteDrive && (
              <DeleteDriveButtonWithModal
                handleDeleteDrive={handleDeleteDrive}
              />
            )}
            {!isApproved &&
            driveDetail.machine?.affiliation === Affiliation.COMPANY ? (
              <PostponedIconButton
                state={getState({ postponed: isPostponed })}
                drive={{
                  driveId: driveDetail.id,
                  state: driveDetail.state,
                }}
              />
            ) : null}
          </div>
        }
        heading={
          <LinkArrows driveDetail={driveDetail}>
            <Heading
              handleApproveClick={handleApproveClick}
              isApproved={isApproved}
              isComplete={driveDetail.validation.isComplete}
              isEditing={isEditing}
              isPostponed={isPostponed}
              isSentToTos={driveDetail.state === State.THIRD_PARTY_ACKNOWLEDGED}
              type={driveDetail.type}
            />
          </LinkArrows>
        }
      />
    </div>
  );
};

export default HeaderWithRelatedRides;
