import React from "react";

import { Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getTelematicsMachinesFocusedRow } from "../../../../../shared/api/telematics/machines/machines.selectors";

import { TELEMATICS_URLS } from "../../../../telematics.constants";

import {
  Affiliation,
  LogbookAggregatedItemTo,
  State,
} from "../../../../../generated/api/telematics";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTextBadge from "../../../../../shared/components/misc/CfTextBadge/CfTextBadge";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import { useFocusedTableRowTarget } from "../../../../../shared/hooks/useFocusedTableRowTarget";
import { Thunk } from "../../../../../types";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../../../../components/TelematicsForeignWarning/TelematicsForeignWarning";
import TelematicsNotSendInfo from "../../../../components/TelematicsNotSendInfo/TelematicsNotSendInfo";
import { useTelematicsContext } from "../../../../containers/Telematics/Context";
import { getDriveBadgeProps, getDuration } from "../../../../helpers";

import { TelematicsState } from "../../../../../reducers/telematics.reducer.types";
import { SeedApplicationType } from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  firstCell: {
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
  },
  row: {
    cursor: "pointer",
  },
  approvals: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 58,
  },
  catchCropIcon: {
    marginRight: 5,
    height: 15,
  },
}));

type ReduxProps = ConnectedProps<typeof connector>;

interface OwnProps {
  data: LogbookAggregatedItemTo;
  isInit?: boolean;
}

type Props = ReduxProps & OwnProps;

const RideRow = ({ data, focusedRow, isInit }: Props) => {
  const isApprovedRide =
    data.state === State.APPROVED ||
    data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const isPostponedRide = data.state === State.DEFERRED;
  const isSentToTos = data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: data.id.toString(),
  });

  // todo zmen vsude v telematice
  const { farmId } = useTelematicsContext();

  const getOperationTooltip = () => {
    if (isPostponedRide)
      return intl.formatMessage({ id: "TelematicsList.postponedRide" });
    if (isApprovedRide)
      return intl.formatMessage({ id: "TelematicsList.approvedRide" });
    return intl.formatMessage({ id: "TelematicsList.unApprovedRide" });
  };

  const badgeProps = getDriveBadgeProps(data.type, intl);

  const rowClickRedirectionUrl = `/farm/${farmId}/${TELEMATICS_URLS.machines}/${data.id}`;
  const handleClick = () => history.push(rowClickRedirectionUrl);
  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 1) {
      window.open(rowClickRedirectionUrl, "_blank", "noopener noreferrer");
    }
  };

  return (
    <TableRow
      className={classes.row}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={targetElementRef}
    >
      <CfTableCell name="duration">
        <div className={classes.firstCell}>
          <CfTextBadge
            backgroundColor={badgeProps.backgroundColor}
            color={badgeProps.color}
            fontSize={badgeProps.fontSize}
            text={badgeProps.text}
            tooltipText={badgeProps.tooltip}
          />
          <span>{getDuration(data.duration)}</span>
        </div>
      </CfTableCell>
      <CfTableCell name="driver.name">
        <div>{data.driver?.name ?? "-"} </div>
        {data.driver?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={data.driver?.companyName}
            type={TelematicsForeignWarningType.Driver}
          />
        )}
        {data.driver?.affiliation !== Affiliation.EXTERNAL && (
          <div className={classes.secondaryTextLight}>
            {data.driver?.code ?? "-"}
          </div>
        )}
      </CfTableCell>
      <CfTableCell name="operation">
        <Tooltip title={getOperationTooltip()}>
          <div
            className={classNames({
              [classes.operation]: isApprovedRide,
              [classes.operationNotApproved]: !isApprovedRide,
              [classes.secondaryTextLight]: isPostponedRide,
            })}
          >
            {data.operation ?? "-"}
          </div>
        </Tooltip>
        {!isPostponedRide && isApprovedRide && !isSentToTos && (
          <TelematicsNotSendInfo />
        )}
      </CfTableCell>
      <CfTableCell name="productionOperation">
        <span>{data.productionOperation?.name ?? "-"}</span>
      </CfTableCell>
      <CfTableCell name="parcel">
        <div>
          {data.parcel?.blockNumber ?? "-"}
          {data.parcel?.blockNumber &&
            data.parcel?.affiliation !== Affiliation.EXTERNAL && (
              <span>{` ${data.parcel?.localName}` ?? "-"}</span>
            )}
        </div>

        {data.parcel?.affiliation !== Affiliation.EXTERNAL && (
          <CropName
            className={classes.secondaryText}
            cropType={data.cropType as SeedApplicationType}
          >
            {data.cropName ?? "-"}
          </CropName>
        )}
        {data.parcel?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={data.parcel?.subjectName}
            type={TelematicsForeignWarningType.Parcel}
          />
        )}
      </CfTableCell>
      <CfTableCell name="area">
        <div>
          {" "}
          {data?.cultivated ? (
            <span>
              <CfFormattedNumber decimalDigits={2} value={data.cultivated} />{" "}
              {" ha"}{" "}
            </span>
          ) : (
            "-"
          )}
        </div>
        {!!data?.parcelArea && (
          <div className={classes.secondaryText}>
            <CfFormattedNumber decimalDigits={2} value={data.parcelArea} />{" "}
            {" ha"}
          </div>
        )}
      </CfTableCell>
      <CfTableCell name="distance">
        <span>
          <CfFormattedNumber decimalDigits={2} value={data?.distance || 0} />{" "}
          {Number(data.distance) >= 0 ? " km" : ""}
        </span>
      </CfTableCell>
      <CfTableCell name="additionalEquipment">
        <div>{data.equipment?.name ?? "-"} </div>
        {data.equipment?.code && (
          <div className={classes.secondaryText}>{data.equipment?.code}</div>
        )}
        {data.equipment?.affiliation === Affiliation.EXTERNAL && (
          <TelematicsForeignWarning
            text={data.equipment?.companyName}
            type={TelematicsForeignWarningType.AdditionalEquipment}
          />
        )}
      </CfTableCell>
      <CfTableCell name="approvals">
        <div />
      </CfTableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  focusedRow: getTelematicsMachinesFocusedRow(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RideRow);
