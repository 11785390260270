import * as React from "react";

import { TableCell, TableContainer, TableRow, Theme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { ColumnData } from "../../../../../catalogues/helpers/tableHelpers";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { NO_CROP } from "../../../../../evidence/actions/shared/misc/action.helpers";
import { SelectObject } from "../../../../../shared/components/form/FormSelect/types";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import { CfTableBodyStaticLoader } from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyStaticLoader";
import { getColDesc } from "../../../../../shared/misc/helper";
import { ParcelCell } from "../parcel/ParcelCell";
import { dashboardCropsQuery } from "../../../../routes/dashboard/ParcelDashboard.api";

import { useFarmIds } from "../../../../../shared/api/client.utils";
import { TableHeaderCell } from "./TableHeaderCell";
import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";
import classNames from "classnames";
import { SeasonCell } from "../season/SeasonCell";

export const SEASONS_TABLE_ROW_HEIGHT = 92;

type Props = {
  isFetching: boolean;
  prevIcon: JSX.Element;
  nextIcon: JSX.Element;
  dashboardParcels: DashboardParcelTo[];
  dashboardSeasons: DashboardSeasonTo[];
  noParcelsForSeasons: boolean;
};

const SeasonsTable = ({
  isFetching,
  dashboardParcels,
  dashboardSeasons,
  prevIcon,
  nextIcon,
  noParcelsForSeasons,
}: Props) => {
  const classes = useStyles();

  const fetchedCrops = useQuery(dashboardCropsQuery(useFarmIds()));

  const hasTableData = dashboardSeasons.length && dashboardParcels.length;
  const noTableData = !dashboardSeasons.length;

  const cropsOptions = (() => {
    const sortedCrops: SelectObject<InternalCropTo>[] = [];
    let noCrop: SelectObject<InternalCropTo> | undefined;

    fetchedCrops.data?.data?.forEach((c) => {
      const crop = {
        key: c.id,
        value: c.name,
        obj: c,
      } as SelectObject<InternalCropTo>;

      if (c.id === NO_CROP.id) {
        noCrop = crop;
      } else {
        sortedCrops.push(crop);
      }
    });

    return noCrop ? [noCrop, ...sortedCrops] : sortedCrops;
  })();

  const headerCellStyle: React.CSSProperties = {
    fontSize: 12,
    background: "#fafafa",
    minWidth: 260,
    top: 60,
  };

  const columns = dashboardSeasons.length
    ? {
        parcel: getColDesc(
          false,
          <div className={classes.headerParcelCell}>
            <FormattedMessage id="PrecisionFarming.seasons.columns.parcel" />
            {prevIcon}
          </div>,
          {
            verticalAlign: "bottom",
            ...headerCellStyle,
          },
        ),
        ...dashboardSeasons.reduce(
          (acc, s, index) => {
            const isLastIteration = index === dashboardSeasons.length - 1;
            const columnName = `season${s.seasonId}`;
            acc[columnName] = getColDesc(
              false,
              <>
                <TableHeaderCell season={s} />
                {isLastIteration && nextIcon}
              </>,
              {
                ...headerCellStyle,
              },
            );
            return acc;
          },
          {} as Record<string, ColumnData>,
        ),
      }
    : {};

  let Jsx = <></>;
  if (isFetching) {
    Jsx = (
      <CfTableBodyStaticLoader
        columns={columns}
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      />
    );
  } else if (hasTableData) {
    Jsx = (
      <TableBody className={classes.tableBody}>
        {dashboardParcels.map((parcel, i) => (
          <TableRow className={classes.tableRow} key={parcel?.parcelId}>
            <TableCell
              className={classNames(classes.tableCell, classes.parcelCell)}
            >
              <ParcelCell data={parcel} />
            </TableCell>
            {dashboardSeasons.map((s) => (
              <TableCell
                className={classNames(classes.tableCell, classes.seasonCell)}
                key={`${s.seasonId}-${s.parcels[i]?.parcelId}`}
              >
                <SeasonCell
                  cropsOptions={cropsOptions}
                  parcel={{ ...s.parcels[i], parcelId: parcel.parcelId }}
                  season={s}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  } else if (noParcelsForSeasons) {
    Jsx = (
      <CfTableBodyEmpty
        colLength={Object.keys(columns).length}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <FormattedMessage id={"PrecisionFarming.seasons.noParcelsForSeasons"} />
      </CfTableBodyEmpty>
    );
  } else if (noTableData) {
    return (
      <div className={classes.isEmptyWrapper}>
        <CfTableBodyEmpty colLength={Object.keys(columns).length} />
      </div>
    );
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <CfTableHead
          columns={columns}
          wrapperStyle={{ position: "relative" }}
        />
        {Jsx}
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerParcelCell: {
    display: "flex",
    alignItems: "flex-end",
    minHeight: 54,
  },
  isEmptyWrapper: {
    background: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    overflowX: "initial",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "2px",
    "& .MuiTableCell-root": {
      border: "none",
    },
    "& thead .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  tableBody: {},
  tableRow: {},
  tableCell: {
    padding: "8px 16px",
    verticalAlign: "top",
    height: SEASONS_TABLE_ROW_HEIGHT,
    boxShadow: "0px 2px 8px 0px #0000001A",
  },
  seasonCell: {
    background: theme.palette.common.white,
  },
  parcelCell: {
    background: theme.palette.common.white,
    left: 0,
    zIndex: 1,
  },
}));

export { SeasonsTable as Table };
