import {
  FORM_TYPES,
  NOT_SOWN_PARCEL_SEED_NAME,
} from "../actionOther.constants";

import {
  ActionOtherDetailTo,
  SeedApplicationTo,
} from "../../../../generated/api/agroevidence";

import { InitialParcelToAdd } from "../actionOther.types";

export const getFormType = (action: ActionOtherDetailTo) => {
  switch (action.actionType) {
    case FORM_TYPES.HARVEST:
      return action.actionType;
    case FORM_TYPES.SOWING:
      return action.actionType;
    case FORM_TYPES.MOWING:
      return action.actionType;
    default:
      return FORM_TYPES.OTHER;
  }
};

export const isOtherAction = (action: ActionOtherDetailTo) => {
  if (!action || !action.id) return false;
  const formType = getFormType(action);
  return !!formType;
};

export const isParcelSown = (parcel: InitialParcelToAdd) => {
  if (parcel?.seedApplication) {
    return (
      parcel?.seedApplication?.seed.crop.name !== NOT_SOWN_PARCEL_SEED_NAME
    );
  }
  return false;
};

export const isAnyParcelSown = (parcels: InitialParcelToAdd[]) => {
  if (parcels && parcels.length) {
    return parcels.some((parcel) => isParcelSown(parcel));
  }
};

export const allParcelsHarvestable = (seedApplications: SeedApplicationTo[]) =>
  seedApplications.every((application) => application.isHarvestable);

export const anyParcelHarvestable = (seedApplications: SeedApplicationTo[]) =>
  seedApplications.some((application) => application.isHarvestable);

export const harvestableParcelsBySeedApplications = ({
  parcels,
  seedApplications,
}: {
  seedApplications: SeedApplicationTo[];
  parcels: InitialParcelToAdd[];
}) => {
  const harvestableSeedApplicationsParcels = seedApplications.filter(
    (application) => application.isHarvestable,
  );

  const harvestableParcelsBySeedApplications = parcels.filter((parcel) =>
    harvestableSeedApplicationsParcels.find(
      (application) => parcel.id === application.parcelId,
    ),
  );

  return harvestableParcelsBySeedApplications;
};

export const isAnyParcelNotSown = (parcels: InitialParcelToAdd[]) => {
  if (parcels && parcels.length) {
    return parcels.some((parcel) => !isParcelSown(parcel));
  }
};

export const filterOnlySownParcels = (parcels: InitialParcelToAdd[]) => {
  if (parcels && parcels.length) {
    return parcels.filter((parcel) => isParcelSown(parcel));
  }
};

export const allMainSowingHarvestableProductsSame = (
  seddApplications: SeedApplicationTo[],
) => {
  const referenceExternalId =
    seddApplications[0]?.sowingMainHarvestProduct?.externalId;

  const allSameExternalId = seddApplications.every(
    (item) => item.sowingMainHarvestProduct?.externalId === referenceExternalId,
  );

  return allSameExternalId;
};
