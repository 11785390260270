import { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { getMonitoringCropIntervalsIsFetching } from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import { getPrecisionParcel } from "../../../../shared/api/sentinel/precision/precision.selectors";
import {
  getCropIntervals,
  getIndex,
  getSelectedIntervalId,
} from "../../selectors/monitoring.selectors";

import {
  setMonitoringIndex,
  setMonitoringInterval,
} from "../../actions/monitoring.actions";

import { indicesList } from "../../selectors/indices";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

const useCommonBioMonitoring = () => {
  const dispatch = useDispatch();
  const parcel = useSelector(getPrecisionParcel);
  const index = useSelector(getIndex);
  const cropIntervals = useSelector(getCropIntervals);
  const cropIntervalsIsFetching = useSelector(
    getMonitoringCropIntervalsIsFetching,
  );
  const selectedIntervalId = useSelector(getSelectedIntervalId);

  const [isInit, setIsInit] = useState(false);

  const prevCropIntervalsRef = useRef(cropIntervals);

  const activeIndicesList = indicesList.filter((indexItem) =>
    parcel?.activeBiomonitoringIndices?.includes(indexItem.id),
  );

  useEffect(() => {
    if (!isInit && parcel?.activeBiomonitoringIndices?.length) {
      if (!parcel?.activeBiomonitoringIndices?.includes(index as IndexType)) {
        const newIndex = parcel.activeBiomonitoringIndices[0];
        dispatch(setMonitoringIndex(newIndex));
      }
      setIsInit((prev) => !prev);
    }
  }, [dispatch, index, isInit, parcel, parcel?.activeBiomonitoringIndices]);

  useEffect(() => {
    if (cropIntervals.length && !cropIntervalsIsFetching) {
      if (!_.isEqual(prevCropIntervalsRef.current, cropIntervals)) {
        const curr = cropIntervals.find((c) => c.id === selectedIntervalId);
        const toSelect = curr ?? cropIntervals[0];
        dispatch(
          setMonitoringInterval(toSelect.from, toSelect.to, toSelect.id),
        );
      }
      prevCropIntervalsRef.current = cropIntervals;
    }
  }, [cropIntervals, dispatch, cropIntervalsIsFetching, selectedIntervalId]);

  return {
    activeIndicesList,
    isInit,
  };
};

export { useCommonBioMonitoring };
