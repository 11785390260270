import { useState, useCallback } from "react";

import { useFormikContext } from "formik";

import { useTypedIntl } from "../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../shared/services/Localization.service";

import { ActionEphFormValues } from "../actionEph.types";

interface Props {
  dosageField: string;
  dosePerHectareField: string;
  actionTotalArea: number;
  storeAmount?: number;
}

const useStockOut = ({
  actionTotalArea,
  dosageField,
  dosePerHectareField,
  storeAmount,
}: Props) => {
  const [isStockOutActive, setIsStockOutActive] = useState(false);
  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const { locale } = useTypedIntl();

  const onStockOut = useCallback(() => {
    if (storeAmount === undefined) return;

    const dosage = storeAmount;
    const dosePerHectare =
      actionTotalArea !== 0 ? dosage / actionTotalArea : dosage;

    setFieldValue(dosageField, Localization.num2strNonFixed(dosage, locale));
    setFieldValue(
      dosePerHectareField,
      Localization.num2str(dosePerHectare, locale, 5),
    );
    setIsStockOutActive(true);
  }, [
    actionTotalArea,
    dosageField,
    dosePerHectareField,
    locale,
    setFieldValue,
    storeAmount,
  ]);

  return { isStockOutActive, onStockOut, setIsStockOutActive };
};

export { useStockOut };
