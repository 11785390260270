import React, { useEffect, useState } from "react";

import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import { FormattedMessage } from "react-intl";
import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { SeasonTo } from "../../../generated/api/satellite";
import { precisionSeasonsQuery } from "../dashboard/ParcelDashboard.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import { SelectObject } from "../../../shared/components/form/FormSelect/types";
import useWidth from "../../../shared/hooks/useWidth";
import { RsaaApiError } from "../../../types";
import { useIsPrecisionFarmingActive } from "../../../core/precision/hooks/useIsPrecisionFarmingActive";

import NoPrecision from "../../../core/precision/containers/NoPrecision";
import Table from "./table/Table";
import { usePrecisionListStyles } from "../../../core/precision/containers/styles";
import {
  AdvancedFilter,
  AdvancedFilterType,
} from "../../components/AdvancedFilter";

import { useCropOptions } from "./hooks/useCropOptions";
import { SeasonDropdown } from "./components/SeasonDropdown";
import { TotalArea } from "./components/TotalArea";

type Props = {
  error?: RsaaApiError;
  farmId: string;
  langId: LANGUAGE_ID;
  showDashboard: () => void;
};

const PrecisionServicesNew = ({
  error,
  farmId,
  langId,
  showDashboard,
}: Props) => {
  const width = useWidth();
  const isMobile = width === "xs" || width === "sm";

  const { crops, cropsOptions } = useCropOptions();

  const pzListClasses = usePrecisionListStyles();
  const classes = useStyles();
  const [totalArea, setTotalArea] = useState(0);
  const [season, setSeason] = useState<SelectObject<SeasonTo> | undefined>(
    undefined,
  );
  const [textFilter, setTextFilter] = useState("");
  const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilterType>({});

  const hasPrecision = useIsPrecisionFarmingActive({ isPageInitCall: false });

  const seasons = useQuery(
    precisionSeasonsQuery({
      farmIds: farmId,
      "sort-dir": "desc",
    }),
  );
  const fetchedSeasons = seasons?.data?.data;
  const seasonsOptions: SelectObject<SeasonTo>[] =
    fetchedSeasons?.map((s) => ({
      key: s.id,
      value: s.name,
      obj: s,
    })) ?? [];

  useEffect(() => {
    if (!season && seasons.isSuccess && seasonsOptions.length) {
      setSeason(seasonsOptions[0]);
    }
  }, [season, seasonsOptions, seasons.isSuccess]);

  let Jsx = <CfLoader />;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  }

  if (hasPrecision) {
    Jsx = (
      <div>
        <div className={classes.toolbar}>
          <div className={classes.season}>
            <SeasonDropdown
              seasonsOptions={seasonsOptions}
              selected={season}
              setSelected={setSeason}
            />
          </div>
          <div className={classes.toolbarSubrow}>
            <div className={classes.toolbarSubrowLeft}>
              <TotalArea totalArea={totalArea} />
              <CfTextFilter
                initialValue={textFilter}
                name="precision-list-text-filter"
                onChange={setTextFilter}
                translId="PrecisionServices.table.textFilter"
                style={
                  !isMobile
                    ? {
                        width: "100%",
                        minWidth: 340,
                      }
                    : undefined
                }
              />
              <AdvancedFilter
                crops={crops}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
              />
            </div>
            <div>
              <Button
                onClick={showDashboard}
                color="secondary"
                size="large"
                variant="outlined"
              >
                <span className={classes.button}>
                  <KeyboardArrowLeftIcon sx={{ mr: 1 }} />
                  <FormattedMessage id="PrecisionFarming.seasons.backToDashboard" />
                </span>
              </Button>
            </div>
          </div>
        </div>
        <Table
          advancedFilter={advancedFilter}
          farmId={farmId}
          season={season?.obj}
          setTotalArea={setTotalArea}
          textFilter={textFilter}
          cropsOptions={cropsOptions}
        />
      </div>
    );
  }

  return (
    <CfErrorPage error={error}>
      <div className={pzListClasses.wrapper}>{Jsx}</div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingBottom: theme.spacing(1),
    flexWrap: "wrap",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  toolbarSubrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    flexWrap: "wrap",
  },
  toolbarSubrowLeft: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    flexWrap: "wrap",
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
  season: {
    marginBottom: 20,
  },
}));

export { PrecisionServicesNew };
