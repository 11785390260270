import React, { PropsWithChildren } from "react";

import FilterList from "@mui/icons-material/FilterList";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage } from "react-intl";

import CfSwitcherButton from "../../components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../hooks/usePopover";

type Props = {
  advancedFilter: {
    [key: string]: unknown;
  };
  disabled?: boolean;
  filterState: object;
  onAccept?: () => void;
  onReset?: () => void;
  onClose?: () => void;
  setAdvancedFilterState: (val: object) => void;
  setAdvancedFilter: (val: object) => void;
};

const CfFilterV2 = ({
  advancedFilter = {},
  children,
  disabled = false,
  filterState = {},
  onAccept,
  onClose,
  onReset,
  setAdvancedFilter,
  setAdvancedFilterState,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const popover = usePopover();

  const getFilterCount = () => {
    let count = 0;
    Object.keys(advancedFilter).forEach((key) => {
      if (!isEmpty(advancedFilter[`${key}`])) {
        count += 1;
      }
    });
    return count;
  };

  const handleFilterAccept = () => {
    updateAdvancedFilter(filterState);
    popover.handlePopoverClose();
  };

  const handleFilterCancel = () => {
    updateAdvancedFilter({});
    setAdvancedFilterState({});
    popover.handlePopoverClose();
  };

  const updateAdvancedFilter = (values: object) => {
    setAdvancedFilter({ ...values });
  };

  const filterCount = getFilterCount();
  const filterValue = filterCount > 0 ? `(${filterCount})` : "";
  return (
    <div>
      <CfSwitcherButton
        className={filterCount > 0 ? classes.selectedFilter : ""}
        data-test="advanced-filter"
        disabled={disabled}
        onClick={popover.handlePopoverOpen}
        classes={{
          root: filterCount > 0 ? classes.selectedFilter : "",
        }}
      >
        <FilterList
          className={classes.filterIcon}
          style={filterCount > 0 ? { color: "#FFFFFF" } : {}}
        />
        <span className={classes.switcherButtonLabel}>
          <FormattedMessage
            id="CfFilter.placeholder"
            values={{ count: filterValue }}
          />
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={popover.anchorEl}
        classes={{ paper: classes.popoverPaper }}
        open={popover.isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          popover.handlePopoverClose();
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
        <div className={classes.buttons}>
          <Button
            id="cancel"
            onClick={() => {
              if (onReset) {
                onReset();
              }
              handleFilterCancel();
            }}
          >
            <FormattedMessage id="CfFilter.cancelFilter" />
          </Button>
          <Button
            color="primary"
            id="accept"
            onClick={() => {
              if (onAccept) {
                onAccept();
              }
              handleFilterAccept();
            }}
          >
            <FormattedMessage id="CfFilter.filter" />
          </Button>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    overflow: "visible",
    marginTop: 7,
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
  filterIcon: {
    color: theme.palette.grey[500],
    marginRight: 6,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttons: {
    float: "right",
    margin: "0px 8px 4px",
  },
}));

export { CfFilterV2 };
