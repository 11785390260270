import React, { ReactNode, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";

import { DriveSubjectTo } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { statusQuery } from "../../../containers/Telematics/telematics.api";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import { subjectsQuery } from "./ParcelSubjectSelector.api";

type Props = {
  defaultValues?: DriveSubjectTo[];
  label: ReactNode;
  onChange: (items: DriveSubjectTo[]) => void;
};

const handleGetSelected = (
  option: DriveSubjectTo,
  value?: DriveSubjectTo | null,
) => option.id === value?.id;

const handleFilterOptions = createFilterOptions({
  stringify: ({ id, name }) => `${name} ${id}`,
});

const ParcelSubjectSelector = ({
  defaultValues = [],
  label,
  onChange,
}: Props) => {
  const classes = useAdvancedFilterStyles();
  const intl = useIntl();
  const subjects = useSuspenseQuery(
    subjectsQuery({ search: "", ...useFarmIds() }),
  );
  const status = useSuspenseQuery(statusQuery(useFarmIds()));
  const suggestions = [
    {
      id: status.data.data.externalId,
      name: intl.formatMessage({
        id: "TelematicsList.filter.parcelUser.ownFarm",
      }),
    },
    ...subjects.data.data,
  ];

  const handleRenderOption = useCallback(
    (option: DriveSubjectTo) => (
      <div>
        <div>{option.name}</div>
        <div className={classes.subtext}>{option.id}</div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: DriveSubjectTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id}
            label={
              <>
                {value.name}
                <span className={classes.subtext}>{value.id}</span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="drive-subject-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={suggestions}
      testId="drive-subject-filter"
    />
  );
};

export default ParcelSubjectSelector;
