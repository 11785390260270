import React, { Fragment, useMemo, useState } from "react";

import { Stack, Theme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import CfDialogTabs from "../../../../../../shared/components/common/CfDialogTabs/CfDialogTabs";
import CfSimpleDialog from "../../../../../../shared/components/common/CfSimpleDialog/CfSimpleDialog";
import { WarningMessageBox } from "../../../../shared/containers/WarningMessageBox";

import {
  ParcelEagriInfoTo,
  EagriNitrateRestrictionWithDescriptionTo,
  EagriSubsidyTo,
} from "../../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  restrictionCode: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  restrictionCodeWrapper: {
    margin: "20px 0 12px 0",
  },
  restrictionMessage: {
    fontSize: 16,
  },
  restrictionMessageEmpty: {
    fontSize: 16,
    fontWeight: 500,
  },
  content: {
    margin: "24px 0px",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
  restrictionDates: {
    color: theme.palette.grey[400],
  },
}));

const RESTRICTION_TYPES = {
  nitrate: "nitrate",
  aeko: "aeko",
};

interface ParcelDetailRestrictionsProps {
  restrictions: ParcelEagriInfoTo;
}

const ParcelDetailRestrictions = ({
  restrictions,
}: ParcelDetailRestrictionsProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const { aeko, nitrate } = restrictions;

  const [dialogOpened, setDialogOpened] = useState(false);

  const NITRATE_TABS = restrictions.nitrate.map((nitrate) => ({
    id: `nitrate-${nitrate.year}`,
    labelId: intl.formatMessage(
      { id: `ParcelDetailRestrictions.nitrate` },
      { year: `${nitrate.year ?? ""}` },
    ),
    visible: !!nitrate.nitrateRestrictions.length,
    year: nitrate.year,
    type: RESTRICTION_TYPES.nitrate,
  }));

  const TABS = useMemo(() => {
    const showTabs = [
      ...NITRATE_TABS,
      {
        id: `aeko`,
        labelId: "ParcelDetailRestrictions.aeko",
        visible: !!aeko.length,
        year: "",
        type: RESTRICTION_TYPES.aeko,
      },
    ];
    return showTabs.filter((t) => t.visible);
  }, [aeko, NITRATE_TABS]);

  const RESTRICTIONS_GROUPS = NITRATE_TABS.map((tab) => tab.id);

  const [tabValue, setTabValue] = useState(
    RESTRICTIONS_GROUPS.length > 0 ? RESTRICTIONS_GROUPS[0] : "",
  );

  const ACTUAL_TAB = TABS.find((tab) => tabValue === tab.id);

  const renderTabContent = () => {
    if (ACTUAL_TAB?.type === RESTRICTION_TYPES.aeko) {
      return renderRestrictions(aeko);
    }

    if (ACTUAL_TAB?.type === RESTRICTION_TYPES.nitrate) {
      const currentNitrate = nitrate.find(
        (nit) => nit.year === ACTUAL_TAB.year,
      );

      if (currentNitrate) {
        if (currentNitrate.isOldNitrate) {
          return (
            <Fragment>
              <div className={classes.content}>
                <WarningMessageBox
                  title=""
                  text={
                    <FormattedMessage id="restrictions.nitrate.isOld.warning" />
                  }
                />
              </div>
              {renderRestrictions(currentNitrate?.nitrateRestrictions)}
            </Fragment>
          );
        }

        return renderRestrictions(currentNitrate?.nitrateRestrictions);
      }
    }

    return null;
  };

  const renderRestrictions = (
    restrictions: EagriNitrateRestrictionWithDescriptionTo[] | EagriSubsidyTo[],
  ) =>
    restrictions.map((r, index) => {
      const isNitrateRestriction = (
        r: EagriNitrateRestrictionWithDescriptionTo | EagriSubsidyTo,
      ): r is EagriNitrateRestrictionWithDescriptionTo =>
        (r as EagriNitrateRestrictionWithDescriptionTo).validFrom !== undefined;

      return (
        <div data-test="restrictions-content" key={r.code}>
          <div className={classes.content}>
            <Stack
              className={classes.restrictionCodeWrapper}
              direction="row"
              gap={1}
            >
              <span className={classes.restrictionCode}>{r.code}</span>
              {isNitrateRestriction(r) && (
                <span>
                  <FormattedMessage id={`parcels.nitrate.source.${r.source}`} />
                </span>
              )}
            </Stack>
            {r.description?.length === 0 ? (
              <div className={classes.restrictionMessageEmpty}>-</div>
            ) : (
              <div>
                {isNitrateRestriction(r) && (
                  <Stack
                    className={classes.restrictionDates}
                    direction="row"
                    gap={1}
                  >
                    <span>
                      <FormattedDate value={r?.validFrom} />
                    </span>
                    <span>-</span>
                    <span>
                      {!!r?.validTo && <FormattedDate value={r?.validTo} />}
                    </span>
                  </Stack>
                )}
                <div
                  className={classes.restrictionMessage}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: r.description }}
                />
              </div>
            )}
          </div>
          {index < restrictions.length - 1 && (
            <Divider className={classes.divider} variant="fullWidth" />
          )}
        </div>
      );
    });

  return (
    <div>
      <a href="#" onClick={() => setDialogOpened(true)}>
        <FormattedMessage id="ParcelDetailOverview.restrictionsButton" />
      </a>
      <CfSimpleDialog
        data-test="parcel-restrictions"
        onDialogClose={() => setDialogOpened(false)}
        open={dialogOpened}
        heading={
          <FormattedMessage id="ParcelDetailOverview.restrictionsButton" />
        }
      >
        <CfDialogTabs
          activateTab={(val) => setTabValue(val as string)}
          tabsInfo={TABS}
          tabValue={tabValue}
        />
        {renderTabContent()}
      </CfSimpleDialog>
    </div>
  );
};

export { ParcelDetailRestrictions };
