import React from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import analyticsDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/analytics_disabled.svg";
import analyticsIcon from "../../../../assets/img/icons/satellite/precision/dashboard/analytics.svg";
import planetDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/planet_disabled.svg";
import planetIcon from "../../../../assets/img/icons/satellite/precision/dashboard/planet.svg";
import requestQuoteDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/request-quote_disabled.svg";
import requestQuoteIcon from "../../../../assets/img/icons/satellite/precision/dashboard/request_quote.svg";
import testTubeDisabledIcon from "../../../../assets/img/icons/satellite/precision/dashboard/test-tube_disabled.svg";
import testTubeIcon from "../../../../assets/img/icons/satellite/precision/dashboard/test-tube.svg";
import {
  DashboardParcelTo,
  PrecisionFarmingProduct,
} from "../../../../generated/api/satellite";
import { PRECISION_TABS } from "../../../../core/precision/containers/PrecisionDetail";

type Props = {
  products: PrecisionFarmingProduct[];
  parcel: DashboardParcelTo;
  farmId: string;
};

const ActiveProductsCell = ({ farmId, parcel, products }: Props) => {
  const classes = useStyles();
  const disabledColor = "#F6F6F6";

  const planetIsActive = products.includes(
    "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
  );
  const soilIsActive = products.includes("INTERNAL_SOIL_SAMPLES");
  const monitoringIsActive =
    products.includes("PLANET_BIOMONITORING_SAVI") ||
    products.includes("GISAT_BIOPHYSICAL_MONITORING");
  const yieldIsActive = !!parcel.yieldInTonnes;

  const rootUrl = `/farm/${farmId}/precision-farming/services/${parcel.externalParcelId}`;

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.managementZones" />}
      >
        {planetIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            to={`${rootUrl}/${PRECISION_TABS.zoneManagement.path}`}
            style={{
              background: "#E0F5F0",
            }}
          >
            <img alt="planet" src={planetIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="planet_disabled" src={planetDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.soilSamples" />}
      >
        {soilIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            to={`${rootUrl}/${PRECISION_TABS.soilSamples.path}`}
            style={{
              background: "#F8E6DB",
            }}
          >
            <img alt="test-tube" src={testTubeIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="test-tube_disabled" src={testTubeDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.monitoring" />}
      >
        {monitoringIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            to={`${rootUrl}/${PRECISION_TABS.monitoring.path}`}
            style={{
              background: "#CCE7F5",
            }}
          >
            <img alt="analytics" src={analyticsIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="analytics_disabled" src={analyticsDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.yieldMaps" />}
      >
        {yieldIsActive ? (
          <Link
            className={classes.icon}
            onClick={(e) => e.stopPropagation()}
            to={`${rootUrl}/${PRECISION_TABS.yieldMaps.path}`}
            style={{
              background: "#FFE1FC",
            }}
          >
            <img alt="request-quote" src={requestQuoteIcon} />
          </Link>
        ) : (
          <div
            className={classNames(classes.icon, classes.disabled)}
            onClick={(e) => e.stopPropagation()}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="request-quote-disabled" src={requestQuoteDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 32,
    width: 32,
    borderRadius: "100%",
  },
  disabled: {
    cursor: "default",
  },
}));

export { ActiveProductsCell };
