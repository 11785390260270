import React, { useContext } from "react";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isFetchingDriverAggregations } from "../../../../shared/api/telematics/aggregations/aggregations.selectors";
import { getTelematicsAggregationsSelectedRides } from "../../../selectors/telematicsAggregations.selectors";

import {
  setTableSubrowSelected,
  setTableSelected,
} from "../../../../shared/actions/table.actions";
import {
  fetchAggregationsSaga,
  setBulkEditMode,
} from "../../../actions/telematicsAggregations.actions";

import { NAMESPACE as namespace } from "../../../reducer/telematicsAggregations.reducer";

import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { Thunk } from "../../../../types";
import { useBonus } from "../hooks/useBonus";
import { useClient } from "../hooks/useClient";
import { useCustomerKey } from "../hooks/useCustomerKey";
import { useDriver } from "../hooks/useDriver";
import { useEquipment } from "../hooks/useEquipment";
import { useProductionOperation } from "../hooks/useProductionOperation";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

const useStyle = makeStyles(() => ({
  form: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 5,
  },
  buttons: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 5,
    gap: 10,
  },
  input: {
    width: "100%",
    paddingTop: 8,
    "& > .MuiFormControl-root": {
      marginBottom: 0,
    },
  },
  shortInput: {
    maxWidth: 78,
  },
  longInput: {
    maxWidth: 270,
  },
  submitButton: {
    whiteSpace: "nowrap",
  },
}));

type Props = {
  getAggregations: () => void;
  mainSelected: number;
  selectedRecords: string[];
  setBulkEditMode: (val: boolean) => void;
  setTableSelected: (selected: string[], namespace: string) => void;
  setTableSubrowSelected: (selected: string[], namespace: string) => void;
  tableIsFetching: boolean;
};

const _SecondarySelectorForm = ({
  getAggregations,
  mainSelected,
  selectedRecords,
  setBulkEditMode,
  setTableSelected,
  setTableSubrowSelected,
  tableIsFetching,
}: Props) => {
  const classes = useStyle();
  const showSnackbar = useContext(SnackbarContext);

  const hookData = {
    0: useDriver(),
    1: useBonus(),
    2: useProductionOperation(),
    3: useEquipment(),
    4: useCustomerKey(),
    5: useClient(),
  };

  const { component, initialValues, onSubmit, validateContentForm } =
    // @ts-ignore
    hookData[mainSelected];

  const resetBulk = (formikProps: FormikHelpers<typeof initialValues>) => {
    formikProps.resetForm();
    setBulkEditMode(false);
    setTableSelected([], namespace);
    setTableSubrowSelected([], namespace);
  };

  return (
    <Formik<typeof initialValues>
      enableReinitialize
      initialValues={initialValues}
      validate={validateContentForm}
      onSubmit={(
        values: typeof initialValues,
        formProps: FormikHelpers<typeof initialValues>,
      ) =>
        onSubmit(values)
          .then(() => {
            showSnackbar({
              message: <FormattedMessage id="common.changesSaved.success" />,
              isSuccess: true,
            });
            resetBulk(formProps);
            getAggregations();
          })
          .catch(() =>
            showSnackbar({
              message: <FormattedMessage id="common.changesSaved.error" />,
              isError: true,
            }),
          )
          .finally(() => formProps.setSubmitting(false))
      }
    >
      {(formikProps) => (
        <Form className={classes.form}>
          <span
            className={classnames(
              classes.input,
              mainSelected === 1 ? classes.shortInput : classes.longInput,
            )}
          >
            {component(formikProps)}
          </span>
          <div className={classes.buttons}>
            <Button
              aria-label="Reset filter"
              disabled={formikProps.isSubmitting}
              variant="contained"
              onClick={() => {
                resetBulk(formikProps);
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              aria-label="Submit filter"
              className={classes.submitButton}
              color="primary"
              type={formikProps.isSubmitting ? undefined : "submit"}
              variant="contained"
              disabled={
                formikProps.isSubmitting ||
                selectedRecords.length === 0 ||
                tableIsFetching
              }
            >
              <FormattedMessage id="TelematicsAggregations.list.bulk.save" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  selectedRecords: getTelematicsAggregationsSelectedRides(state),
  tableIsFetching: isFetchingDriverAggregations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setBulkEditMode,
      getAggregations: fetchAggregationsSaga,
      setTableSubrowSelected,
      setTableSelected,
    },
    dispatch,
  );

const SecondarySelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_SecondarySelectorForm);

export { SecondarySelector };
