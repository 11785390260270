import React from "react";
import { useToggle } from "../../shared/hooks/useToggle";
import { PrecisionServicesNew } from "./services/PrecisionServicesNew";
import { Dashboard } from "./dashboard/Dashboard";
import { AnyTodo } from "../../types";

const DashboardTab = (props: AnyTodo) => {
  const {
    on: visibleDashboard,
    setOff: showListing,
    setOn: showDashboard,
  } = useToggle();

  return (
    <>
      <div style={!visibleDashboard ? { display: "none" } : undefined}>
        <Dashboard {...props} showListing={showListing} />
      </div>
      <div style={visibleDashboard ? { display: "none" } : undefined}>
        <PrecisionServicesNew {...props} showDashboard={showDashboard} />
      </div>
    </>
  );
};

export { DashboardTab };
