import React from "react";

import { useField } from "formik";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import FormCheckbox from "../../../../shared/components/form/FormCheckbox/FormCheckbox";
import ListSelectorError from "../../../../shared/components/form/ListSelectorError/ListSelectorError";
import { getError } from "../../../../shared/misc/formHelpers";
import { Thunk } from "../../../../types";
import {
  GroupByType,
  GroupsType,
  ReportCounts,
} from "../../containers/Reports/types";
import Report from "../Report/Report";

import { useReportTypesStyles } from "./style";

import { ReportsState } from "../../../../reducers/reports.reducer.types";

const reportTypes: GroupByType[] = [
  {
    id: "actions",
    category: "common",
  },
  {
    id: "seededAreas",
    category: "common",
  },
  {
    id: "seedApplications",
    category: "common",
  },
  {
    id: "revenues",
    category: "common",
  },
  {
    id: "plantProtectionUsage",
    category: "chemistry",
  },
  {
    id: "plantProtectionConsumption",
    category: "chemistry",
  },
  {
    id: "plantProtectionConsumptionCrop",
    category: "chemistry",
  },
  {
    id: "fertilizerUsage",
    category: "fertilizers",
  },
  {
    id: "fertilizerConsumption",
    category: "fertilizers",
  },
  {
    id: "fertilizerConsumptionCrop",
    category: "fertilizers",
  },
  {
    id: "fertilizerOrganic",
    category: "fertilizers",
  },
];

type OwnProps = {
  hasSubmitted: boolean;
  name: string;
};

type ReduxProps = ConnectedProps<typeof connector>;

type Props = OwnProps & ReduxProps;

const ReportTypes = ({ hasSubmitted, name }: Props) => {
  const classes = useReportTypesStyles();
  const [field, meta] = useField(name);

  const groupBy = (key: keyof GroupByType) => (array: GroupByType[]) => {
    const objectsByKeyValue: GroupsType = {};

    array.forEach((obj) => {
      const value = obj[key];
      if (value !== undefined) {
        if (!objectsByKeyValue[value]) {
          objectsByKeyValue[value] = [];
        }
        objectsByKeyValue[value].push(obj);
      }
    });

    return objectsByKeyValue;
  };

  const renderCheckBox = (categoryName: string, item: GroupByType) => (
    <FormCheckbox
      key={item.id}
      label={<FormattedMessage id={`Report.${item.id}`} />}
      name={`categories.${categoryName}.${item.id}`}
    />
  );
  const groups = groupBy("category")(reportTypes);

  const getCounts = () => {
    const reports = field.value as ReportCounts;
    if (reports) {
      return Object.entries(reports).map((report) => ({
        category: report[0],
        count: Object.values(report[1])?.filter((val) => val === true).length,
      }));
    }
    return [];
  };

  const isError = !!getError(meta);

  return (
    <>
      <div
        data-testid="report-types-error"
        className={
          hasSubmitted && isError ? classes.errorSubheading : classes.subheading
        }
      >
        <FormattedMessage id="Reports.type" />
      </div>
      <div className={classes.reports} data-testid="report-types-counts">
        {Object.values(groups)?.map((category) => {
          const categoryName = category[0]?.category;
          return (
            <Report
              header={<FormattedMessage id={`Report.${categoryName}`} />}
              id={categoryName}
              key={categoryName}
              count={
                getCounts().find((c) => c.category === categoryName)?.count
              }
            >
              {category?.map((item) => renderCheckBox(categoryName, item))}
            </Report>
          );
        })}
      </div>
      {hasSubmitted && isError && (
        <ListSelectorError error={<FormattedMessage id="Report.typeError" />} />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Thunk<ReportsState>) =>
  bindActionCreators({}, dispatch);

const connector = connect(null, mapDispatchToProps);

export default connector(ReportTypes);
