import React from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useFarmIds } from "../../../shared/api/client.utils";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { machinesQuery } from "../TelematicsAdvancedFilter/MachineSelector/MachineSelector.api";

import { MachineTo } from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

type Props = {
  dateFrom: string;
  dateTo: string;
  disabled: boolean;
  hasError: boolean;
  helperText?: React.ReactElement | string;
  label: React.ReactNode;
  onChange(item: MachineTo): void;
  selectedGpsUnit: string;
};

const handleGetSelected = (option: MachineTo, value?: MachineTo | null) =>
  option.gpsUnit === value?.gpsUnit;

const handleGetLabel = (option?: MachineTo | null) => {
  if (!option) return "";
  const { gpsUnit, licencePlate, name } = option;
  if (name && licencePlate) {
    return `${name} (${licencePlate})`;
  } else if (name && !licencePlate) {
    return `${name}`;
  } else {
    return `${gpsUnit}`;
  }
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ licencePlate, name }) => `${name} (${licencePlate})`,
});

const TelematicsDetailMachineSelector = ({
  dateFrom,
  dateTo,
  disabled,
  hasError,
  helperText,
  label,
  onChange,
  selectedGpsUnit,
}: Props) => {
  const classes = useStyles();
  const machines = useSuspenseQuery(
    machinesQuery({
      dateFrom,
      dateTo,
      ...useFarmIds(),
    }),
  );

  const selectedMachine = (selectedGpsUnit &&
    machines.data.data.find(
      (option) => option.gpsUnit === selectedGpsUnit,
    )) || {
    gpsUnit: selectedGpsUnit,
  };

  return (
    <CfAutocomplete
      defaultValues={selectedMachine}
      disabled={disabled}
      error={hasError}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-machine-selector"
      isMultiple={false}
      label={label}
      onChange={onChange}
      suggestions={machines.data.data}
      testId="telematics-detail-machine-selector"
      renderOption={(option) => (
        <div>
          <div>{option.name ?? option.gpsUnit}</div>
          <div className={classes.id}>
            {option.licencePlate ?? option.gpsUnit}
          </div>
        </div>
      )}
    />
  );
};

export { TelematicsDetailMachineSelector };
