import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import { getParcelIds } from "../selectors/reports.selectors";

import * as types from "./reports.constants";

import {
  getParcelsApi,
  resetParcelsApi,
  addParcels,
} from "../../../shared/api/agroevidence/parcels/parcels.api";
import {
  postFertilizerUsageReportApi,
  postPorUsageReportApi,
  getPorUsageReportApi,
  FertilizerUsageReportParams,
  PorUsageReportParams,
  getReportApi,
  getFarmNitrogenReportApi,
  getSowingPlanReportApi,
} from "../../../shared/api/agroevidence/reports/reports.api";
import {
  getZonesApi,
  resetZonesApi,
} from "../../../shared/api/agroevidence/zones/zones.api";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import FileService from "../../../shared/services/File.service";
import { AnyTodo } from "../../../types";
import {
  ReportCountsCategory,
  ReportsFormValues,
} from "../containers/Reports/types";

import {
  ParcelTo,
  ZoneDetailTo,
} from "../../../shared/api/agroevidence/agroevidence.types";
import { PorTableState } from "../../../shared/api/agroevidence/reports/reports.types";

export const fetchParcelsAndZonesSuggestions =
  (
    searchInput?: string,
    parcelsOnly = false,
    zonesOnly = false,
    sown?: boolean,
  ) =>
  (dispatch: (action: RSAAAction) => void) => {
    if (parcelsOnly) {
      return dispatch(
        getParcelsApi({ context: "suggestions", search: searchInput, sown }),
      );
    }

    if (zonesOnly) {
      return dispatch(getZonesApi(searchInput));
    }

    dispatch(
      getParcelsApi({ context: "suggestions", search: searchInput, sown }),
    );
    return dispatch(getZonesApi(searchInput));
  };

export const clearParcelsAndZonesSuggestions =
  (parcelsOnly = false, zonesOnly = false) =>
  (dispatch: (action: AnyAction) => void) => {
    if (parcelsOnly) {
      return dispatch(resetParcelsApi("suggestions"));
    }

    if (zonesOnly) {
      return dispatch(resetZonesApi());
    }

    dispatch(resetParcelsApi("suggestions"));
    dispatch(resetZonesApi());
  };

export const addParcelOrZoneParcelsToAdd =
  (parcelOrZone: ParcelTo | ZoneDetailTo) =>
  (dispatch: (action: RSAAAction | AnyAction) => void) => {
    const isZone = "parcelCount" in parcelOrZone;
    if (isZone && parcelOrZone.parcelCount > 0) {
      dispatch(
        getParcelsApi({
          context: "additions",
          zones: parcelOrZone.id,
          "per-page": 200,
        }),
      );
    } else if (!isZone) {
      dispatch(addParcels("additions", parcelOrZone));
    }
  };

export const clearParcelsToAdd =
  () => (dispatch: (action: AnyAction) => void) => {
    dispatch(resetParcelsApi("additions"));
  };

type GetReportParamsType = {
  from: string;
  to: string;
  format: string;
  parcelIds: string[];
  id: string;
};

export const getReports =
  (values: ReportsFormValues, format: string) =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, unknown>>,
  ) => {
    let params: GetReportParamsType = {
      from: getShortDateString(values.from),
      to: getShortDateString(values.to),
      format,
      parcelIds: [],
      id: "",
    };
    if (!values.allParcels) {
      params = {
        ...params,
        parcelIds: getParcelIds(values),
      };
    }

    const promises: Promise<Record<string, AnyTodo>>[] = [];
    if (values.categories) {
      Object.entries(values.categories).forEach(([, report]) => {
        const category = report as unknown as ReportCountsCategory;
        const picked = pickBy(category, (r) => r === true);
        const reportsToExport = keys(picked);
        reportsToExport.forEach((toExport) => {
          params = {
            ...params,
            id: toExport,
          };
          const promise = exportReport(params)(dispatch);
          promises.push(promise);
        });
      });
    }

    return Promise.all(promises).then((actions) => {
      const noContentReports = actions
        .filter((action) => action.payload.status === 204)
        .map((action) => action.payload.id);
      dispatch(setNoContentReports(noContentReports));
    });
  };

/*
 * This is the place to resolve discrepancies between old and new endpoints.
 */
const exportReport =
  (_params: AnyTodo) =>
  (dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>) => {
    const params = { ..._params };

    const reportsMap: Record<string, string> = {
      actions: "actions",
      seededAreas: "seeded-areas",
      seedApplications: "seed-applications",
      revenues: "revenues",
      plantProtectionUsage: "plant-protection-usage",
      plantProtectionConsumption: "plant-protection-consumption",
      plantProtectionConsumptionCrop: "plant-protection-consumption-crop",
      fertilizerUsage: "fertilizer-usage",
      fertilizerConsumption: "fertilizer-consumption",
      fertilizerConsumptionCrop: "fertilizer-consumption-crop",
      fertilizerOrganic: "fertilizer-organic",
    };

    params.reportType = reportsMap[params.id];
    params.format = params.format === "xls" ? "xlsx" : params.format;

    return dispatch(getReportApi(params) as unknown as RSAAAction).then(
      (res) => {
        FileService.processFileResponse(res);
        return res;
      },
    );
  };

export const exportFarmNitrogen =
  (dateFrom: string, format: "pdf" | "xlsx") =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, AnyTodo>>,
  ) =>
    dispatch(
      getFarmNitrogenReportApi(dateFrom, format) as unknown as RSAAAction,
    ).then((res) => {
      const noContentReports: (string | number)[] =
        res.payload.status === 204 ? [res.payload.id] : [];
      dispatch(setNoContentReports(noContentReports));
      FileService.processFileResponse(res);
      return res;
    });

export const exportSowingPlan =
  () =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, AnyTodo>>,
  ) =>
    dispatch(getSowingPlanReportApi() as unknown as RSAAAction).then((res) => {
      const noContentReports: (string | number)[] =
        res.payload.status === 204 ? [res.payload.id] : [];
      dispatch(setNoContentReports(noContentReports));
      FileService.processFileResponse(res);
      return res;
    });

export const setNoContentReports = (noContentReports: (string | number)[]) => ({
  type: types.SET_NO_CONTENT_REPORTS,
  noContentReports,
});

export const clearNoContentReport = () => ({
  type: types.CLEAR_NO_CONTENT_REPORTS,
});

export const sendFertilizerUsageReport =
  (params: FertilizerUsageReportParams) =>
  (dispatch: (action: RSAAAction) => void) =>
    dispatch(postFertilizerUsageReportApi(params) as unknown as RSAAAction);

export const sendPorUsageReport =
  (params: PorUsageReportParams) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(postPorUsageReportApi(params) as unknown as RSAAAction);

export const getPorUsageReport =
  (params: PorTableState) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(getPorUsageReportApi(params) as unknown as RSAAAction);
