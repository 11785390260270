export const LANG_KEYS = {
  CZ: "cs-CZ",
  EL: "el-GR",
  EN: "en-US",
  ES: "es-ES",
  HU: "hu-HU",
  PL: "pl-PL",
  RO: "ro-RO",
} as const;

export type LANGUAGE_ID = (typeof LANG_KEYS)[keyof typeof LANG_KEYS];
