import { createSelector } from "reselect";

import {
  getItemById,
  getError as getStoresError,
} from "../../../../shared/api/stores/stores/stores.selectors";
import { getError as getTransactionsError } from "../../../../shared/api/stores/transactions/transactions.selectors";

export const getStoreItemById = (state, id) => getItemById(state, id);

export const getStorePage = (state) => state.ui.store.page;
export const getStoreRowsPerPage = (state) => state.ui.store.rowsPerPage;
export const getStoreFromFilter = (state) => state.ui.store.from;
export const getStoreToFilter = (state) => state.ui.store.to;
export const getStoreUnitId = (state) => state.ui.store.unitId;
export const getStoreAmount = (state) => state.ui.store.amount;

export const getApiError = createSelector(
  getStoresError,
  getTransactionsError,
  (storesError, transactionsError) => {
    const errors = [storesError, transactionsError];
    return errors.find((err) => err.isError === true) || errors[0];
  },
);
