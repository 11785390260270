import React from "react";

import { InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const SelectLabel = ({ children }: React.PropsWithChildren<unknown>) => {
  const classes = useStyles();

  return <InputLabel className={classes.wrapper}>{children}</InputLabel>;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 5,
    "&.MuiInputLabel-root": {
      transform: "translate(0px, 16px) scale(1)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translateX(0px) translateY(-5px) scale(0.75)",
    },
  },
}));

export { SelectLabel };
