import { alpha, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useReportsStyles = makeStyles((theme: Theme) => ({
  content: {
    maxWidth: 600,
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
  },
  switchLabelRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: 0,
    marginBottom: 20,
  },
  button: {
    margin: 10,
  },
  progress: {
    marginLeft: 10,
  },
  buttons: {
    marginTop: 45,
    marginBottom: 30,
  },
  reportButton: {
    position: "absolute",
    right: 10,
    top: 15,
  },
  labelDateRangeSelector: {
    fontSize: 16,
    fontWeight: 400,
    margin: "5px 0px 25px 0px",
    color: alpha(theme.palette.common.black, 0.6),
  },
}));
