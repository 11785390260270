import React, { FC } from "react";

import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";

import { useTelematicsAggregationDetailContentStyles } from "../styles";

type Props = {
  isSubmitting?: boolean;
  onCancelClick?: () => void;
  onSubmitClick?: () => void;
};

const Buttons: FC<Props> = ({ isSubmitting, onCancelClick, onSubmitClick }) => {
  const classes = useTelematicsAggregationDetailContentStyles();
  return (
    <Grid
      alignItems="center"
      className={classes.buttons}
      container
      justifyContent="center"
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={isSubmitting}
          onClick={onCancelClick}
          type="reset"
          variant="contained"
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          color="primary"
          disabled={isSubmitting}
          id="create"
          onClick={onSubmitClick}
          type="submit"
          variant="contained"
        >
          <FormattedMessage id="common.save" />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Buttons;
