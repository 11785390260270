import React from "react";

import { BioMonitoring } from "../../BioMonitoring";
import { IndexTypeDataProps } from "../types";
import { useSaviBioMonitoring } from "../useSaviBioMonitoring";

const BiomonitoringIndexTypeSection = ({
  index,
  parcelId,
  setCropHarvestedError,
}: IndexTypeDataProps) => {
  const hookData = useSaviBioMonitoring(parcelId);

  React.useEffect(() => {
    if (hookData && !hookData?.isFetching) {
      if (hookData.cropHarvestedError) {
        setCropHarvestedError(hookData.cropHarvestedError);
      }
    }
  }, [hookData, hookData?.isFetching, setCropHarvestedError]);

  return (
    <BioMonitoring
      hookData={hookData}
      index={index}
      lastUpdate={hookData.lastImageDateTo}
      parcelId={parcelId}
    />
  );
};

export { BiomonitoringIndexTypeSection };
