import React, { Suspense } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import { ErrorBoundary } from "react-error-boundary";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  DriveKeyTo,
  KeyType,
  updateCustomerKey,
} from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import { CfFormikErrors } from "../../../../types";
import { DriveKey } from "../../../components/TelematicsAggregationDetailContent/formComponents";
import { MESSAGES } from "../../../components/TelematicsAggregationDetailContent/validators";
import { useCustomStyles } from "../components/styles/useCustomStyles";
import { telematicsDriversKey } from "../Drivers.api";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

const useCustomerKey = () => {
  const intl = useIntl();
  const customClasses = useCustomStyles();
  const updateCustomerKeyMutation = useBatchCustomerKeyMutation();

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );

  const initialValues: { customerKey?: DriveKeyTo } = {
    customerKey: undefined,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.customerKey) {
      errors.customerKey = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      drive: selectedRides.map((ride) => parseInt(ride, 10)),
      customerKeyId: values.customerKey?.id ?? -1,
    };
    return updateCustomerKeyMutation.mutateAsync(payload);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<CfLoader />}>
        <DriveKey
          customClasses={customClasses}
          disabled={formikProps.isSubmitting}
          fieldName="customerKey"
          helperText={(formikProps.errors.customerKey as string) ?? ""}
          keyType={KeyType.CUSTOMER}
          showApprovalWarning={false}
          placeholder={intl.formatMessage({
            id: "TelematicsAggregations.list.bulk.secondary.placeholder.customerKey",
          })}
        />
      </Suspense>
    </ErrorBoundary>
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

const useBatchCustomerKeyMutation = () => {
  const queryClient = useQueryClient();
  const { farmIds } = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateCustomerKey>[0]) =>
      updateCustomerKey(payload, {
        farmIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export { useCustomerKey };
