import React from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { ReactNodeLike } from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  DriveValidationType,
  WinfasClientTo,
} from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import { DriveKeysFormValues } from "../DriveKeysForm";

import ApprovalWarning from "./ApprovalWarning";
import { winfasClientCombinationsQuery } from "./WinfasClient.api";

export const EMPTY_NAME = "-";
type Props = {
  customClasses?: Record<string, string>;
  date: string;
  disabled?: boolean;
  fieldName: keyof DriveKeysFormValues;
  helperText?: NonNullable<ReactNodeLike> | null;
  placeholder?: string;
  showApprovalWarning?: boolean;
  tooltipCode?: DriveValidationType;
  hasEmptyOption?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
}));

const WinfasClient = ({
  customClasses,
  date,
  disabled = false,
  fieldName,
  hasEmptyOption = false,
  helperText,
  placeholder,
  showApprovalWarning = false,
  tooltipCode,
}: Props) => {
  const { errors, setFieldValue, values } =
    useFormikContext<DriveKeysFormValues>();
  const classes = useStyles();
  const shortDate = getShortDateString(date);
  const emptyOption = { name: EMPTY_NAME, idNumber: "" };
  const clients = useSuspenseQuery(
    winfasClientCombinationsQuery({
      ...useFarmIds(),
      disabled,
      dateFrom: shortDate,
      dateTo: shortDate,
    }),
  );

  const handleFilterOptions = createFilterOptions({
    stringify: ({ idNumber, name }) => `${name} (${idNumber})`,
  });

  const extendedSuggestions = hasEmptyOption
    ? [emptyOption, ...(clients.data?.data || [])]
    : clients.data?.data || [];

  return (
    <div className={classes.container}>
      <ApprovalWarning show={showApprovalWarning} tooltipCode={tooltipCode} />
      <CfFormControl>
        <CfAutocomplete
          classes={customClasses}
          defaultValues={values[fieldName]}
          disabled={disabled}
          error={!!errors[fieldName]}
          filterOptions={handleFilterOptions}
          helperText={helperText}
          isMultiple={false}
          multilineInput
          onChange={(value) => setFieldValue(fieldName, value)}
          placeholder={placeholder}
          suggestions={extendedSuggestions}
          getLabel={(option: WinfasClientTo) =>
            option.name !== EMPTY_NAME && option.name
              ? `${option.name} ${option.idNumber ? `(${option.idNumber})` : EMPTY_NAME}`
              : EMPTY_NAME
          }
          label={
            placeholder ? undefined : (
              <FormattedMessage id="TelematicsAggregations.detail.keys.client" />
            )
          }
          renderOption={(option: WinfasClientTo) => (
            <div>
              <div>{option.name ?? EMPTY_NAME}</div>
              <div className={classes.secondaryText}>
                {option.idNumber ?? EMPTY_NAME}
              </div>
            </div>
          )}
        />
      </CfFormControl>
    </div>
  );
};

export { WinfasClient };
