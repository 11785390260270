import React from "react";

import { makeStyles } from "@mui/styles";

import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../shared/services/Localization.service";

import { SubtractableAreaFromMaterialType } from "../../../ActionOthers/actionOther.types";

interface Props {
  sa: SubtractableAreaFromMaterialType;
}

const SubtractableArea = ({ sa }: Props) => {
  const classes = useStyles();
  const { locale } = useTypedIntl();

  const localizedRestrictionValue = Localization.num2strNonFixed(
    sa.value as number,
    locale,
  );
  const localizedRestrictionAreaAbsolute = Localization.num2str(
    sa.value,
    locale,
    2,
  );
  return (
    <span className={sa.isMaterial ? classes.fromMaterial : undefined}>
      {sa.type === "Absolute"
        ? `${localizedRestrictionAreaAbsolute} ha`
        : `${localizedRestrictionValue} m`}
      {sa.isMaterial && <div className={classes.fromMaterial}>{sa.name}</div>}
    </span>
  );
};

export default SubtractableArea;
const useStyles = makeStyles(() => ({
  fromMaterial: {
    color: "#6AA8EC",
  },
}));
