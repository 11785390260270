// TODO delete next line after temporarily simplifying the LpisDialog will be cancelled
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useState } from "react";

// TODO delete next line after TextField will use
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import CfDatePicker from "../../../shared/components/common/CfDatePicker/CfDatePicker";
import { getShortDateString } from "../../../shared/misc/timeHelpers";

import { EagriFullFarmUpdateMode } from "../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    minWidth: "300px",
  },
  contentContainer: {
    padding: "5px 0px",
  },
  textField: {
    display: "flex",
    justifyContent: "left",
    marginBottom: 20,
    color: theme.palette.grey[500],
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "21px",
  },
  grid: {
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  button: {
    marginLeft: 8,
  },
}));

interface UpdateLpisDialogDialogProps {
  availableUpdateDate?: Date;
  lastUpdateDate?: string;
  onAccept: (wsKey: string, date: string, updateType: string) => void;
  onClose: () => void;
}

const UpdateLpisDialog: FC<UpdateLpisDialogDialogProps> = ({
  availableUpdateDate,
  lastUpdateDate,
  onAccept,
  onClose,
}) => {
  const classes = useStyles();
  const [wsKey, setWsKey] = useState<string>("");
  const [date, setDate] = useState<Moment>(
    availableUpdateDate ? moment(availableUpdateDate) : moment().startOf("day"),
  );
  const [updateType, setUpdateType] = useState<string>(
    EagriFullFarmUpdateMode.ALL,
  );

  const handleUpdateLPIS = () => {
    onAccept(wsKey, getShortDateString(date), updateType);
  };

  // TODO delete next line after wsKey will use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeWsKey = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setWsKey(evt.target.value);
  };

  const onDateChange = (value: Moment) => {
    const newValue = moment(value).startOf("day");
    setDate(newValue);
  };

  const handleRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUpdateType((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="update-lpis-dialog"
      open
    >
      <DialogTitle>
        <FormattedMessage id="UpdateLPIS.title" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentContainer}>
          <Grid className={classes.grid} item>
            {/* TODO not BE endpoint for wsKey */}
            {/* <TextField
                className={classes.textField}
                label={<FormattedMessage id="UpdateLPIS.dialog.wsKey" />}
                name="wsKey"
                onChange={onChangeWsKey}
                value={wsKey}
                variant="standard"
              /> */}
          </Grid>
          <Grid className={classes.grid} item>
            <CfDatePicker
              label={<FormattedMessage id="UpdateLPIS.dialog.updateDate" />}
              maxDate={moment().endOf("day")}
              maxDateMessage=""
              minDateMessage=""
              name="date"
              input={{
                value: date,
                onChange: onDateChange,
              }}
              minDate={
                lastUpdateDate ? moment(lastUpdateDate).endOf("day") : undefined
              }
            />
          </Grid>
          <Grid className={classes.grid} item>
            <DialogContentText className={classes.label}>
              <FormattedMessage id="UpdateLPIS.dialog.updateLayers" />
            </DialogContentText>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              className={classes.radioGroupWrapper}
              name="radio-buttons-group"
              onChange={handleRadioGroupChange}
              value={updateType}
            >
              <FormControlLabel
                control={<Radio />}
                value={EagriFullFarmUpdateMode.BLOCKS_AND_PARCELS}
                label={
                  <FormattedMessage id="UpdateLPIS.dialog.updateLayers.notAll" />
                }
              />
              <FormControlLabel
                control={<Radio />}
                value={EagriFullFarmUpdateMode.ALL}
                label={
                  <FormattedMessage id="UpdateLPIS.dialog.updateLayers.all" />
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="send"
            onClick={handleUpdateLPIS}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.update" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLpisDialog;
