export const GET_STORE_ITEMS = "GET_STORE_ITEMS";
export const GET_STORE_ITEMS_SUCCESS = "GET_STORE_ITEMS_SUCCESS";
export const GET_STORE_ITEMS_ERROR = "GET_STORE_ITEMS_ERROR";

export const DELETE_STORE_ITEM = "DELETE_STORE_ITEM";
export const DELETE_STORE_ITEM_SUCCESS = "DELETE_STORE_ITEM_SUCCESS";
export const DELETE_STORE_ITEM_ERROR = "DELETE_STORE_ITEM_ERROR";

export const GET_STORE_ITEM = "GET_STORE_ITEM";
export const GET_STORE_ITEM_SUCCESS = "GET_STORE_ITEM_SUCCESS";
export const GET_STORE_ITEM_ERROR = "GET_STORE_ITEM_ERROR";

export const RESET_STORES = "RESET_STORES";

export const EXPORT_STORE_ITEMS = "EXPORT_STORE_ITEMS";
export const EXPORT_STORE_ITEMS_SUCCESS = "EXPORT_STORE_ITEMS_SUCCESS";
export const EXPORT_STORE_ITEMS_ERROR = "EXPORT_STORE_ITEMS_ERROR";

export const EXPORT_STORE_ITEM = "EXPORT_STORE_ITEM";
export const EXPORT_STORE_ITEM_SUCCESS = "EXPORT_STORE_ITEM_SUCCESS";
export const EXPORT_STORE_ITEM_ERROR = "EXPORT_STORE_ITEM_ERROR";

export const GET_STORE_BY_MATERIAL_REQUEST = "GET_STORE_BY_MATERIAL_REQUEST";
export const GET_STORE_BY_MATERIAL_SUCCESS = "GET_STORE_BY_MATERIAL_SUCCESS";
export const GET_STORE_BY_MATERIAL_ERROR = "GET_STORE_BY_MATERIAL_ERROR";

export const GET_STORE_BY_MATERIAL_CONVERTED_REQUEST =
  "GET_STORE_BY_MATERIAL_CONVERTED_REQUEST";
export const GET_STORE_BY_MATERIAL_CONVERTED_SUCCESS =
  "GET_STORE_BY_MATERIAL_CONVERTED_SUCCESS";
export const GET_STORE_BY_MATERIAL_CONVERTED_ERROR =
  "GET_STORE_BY_MATERIAL_CONVERTED_ERROR";
