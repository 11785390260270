import React, { useState, useEffect } from "react";

import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getParcelsStatistics,
  getIsFetchingParcelsStatistics,
} from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getZonesStatistics } from "../../../../../shared/api/agroevidence/zones/zones.selectors";

import {
  getParcelsStatisticsApi,
  resetParcelsStatisticsApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";
import { getZonesApi } from "../../../../../shared/api/agroevidence/zones/zones.api";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import CfPrimaryTab from "../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import NameEditDialog from "../../../../../shared/components/common/NameEditDialog/NameEditDialog";

import { CropParcelStats } from "./CropParcelStats";
import { useParcelZoneStatisticsStyles } from "./zoneStatistics.styles";
import { ZoneStats } from "./ZoneStats";

import { FarmStatisticsTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  ZoneStatistics,
  StatisticsItemType,
} from "../../../../../shared/api/agroevidence/zones/zones.types";

const PARCEL_STATS_TAB = 0;
const ZONE_STATS_TAB = 1;

interface Props {
  onCreateZone: (name: string) => void;
  onDeleteZone: (zone: StatisticsItemType) => void;
  onFilterCropInParcels: (crops: StatisticsItemType) => void;
  onFilterZoneInParcels: (zones: StatisticsItemType) => void;
  onUpdateZone: (zone: StatisticsItemType) => void;
  shouldReloadData: boolean;
}

const ParcelZoneStatistics = ({
  onCreateZone,
  onDeleteZone,
  onFilterCropInParcels,
  onFilterZoneInParcels,
  onUpdateZone,
  shouldReloadData,
}: Props) => {
  const classes = useParcelZoneStatisticsStyles();

  const dispatch = useDispatch();
  const parcelsStats: FarmStatisticsTo = useSelector(getParcelsStatistics);
  const isFetchingParcelsStatistics = useSelector(
    getIsFetchingParcelsStatistics,
  );
  const zonesStats: ZoneStatistics = useSelector(getZonesStatistics);

  const [tabIndex, setTabIndex] = useState<number>(PARCEL_STATS_TAB);
  const [editZone, setEditZone] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState<StatisticsItemType>();
  const [createNewZone, setCreateNewZone] = useState(false);

  useEffect(
    () => () => {
      dispatch(resetParcelsStatisticsApi());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (tabIndex === PARCEL_STATS_TAB) {
      dispatch(getParcelsStatisticsApi());
    } else if (tabIndex === ZONE_STATS_TAB) {
      dispatch(getZonesApi());
    }
  }, [dispatch, tabIndex, shouldReloadData]);

  const onCreateZoneConfirm = (zoneName: string) => {
    onCreateZone(zoneName);
    setCreateNewZone(false);
  };

  const onEditZone = (zone: StatisticsItemType) => {
    setEditZone(true);
    setZoneToEdit(zone);
  };

  const onEditZoneClose = () => {
    setEditZone(false);
    setZoneToEdit(undefined);
  };

  const onEditZoneAccept = (zone: StatisticsItemType) => {
    onUpdateZone(zone);
    onEditZoneClose();
  };

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <CfPrimaryTabs
              centered
              onChange={handleChange}
              tabValue={tabIndex}
              variant="fullWidth"
            >
              <CfPrimaryTab
                data-test="parcel-list-crops-tab"
                label={<FormattedMessage id="common.crops" />}
              />
              <CfPrimaryTab
                data-test="parcel-list-zones-tab"
                label={<FormattedMessage id="common.zones" />}
              />
            </CfPrimaryTabs>
            {isFetchingParcelsStatistics ? (
              <CfLoader classes={{ wrapper: classes.wrapper }} />
            ) : (
              <>
                {tabIndex === PARCEL_STATS_TAB && parcelsStats?.crops && (
                  <CropParcelStats
                    onFilterCropInParcels={onFilterCropInParcels}
                    parcelsStats={parcelsStats}
                  />
                )}
                {tabIndex === ZONE_STATS_TAB && (
                  <ZoneStats
                    createNewZone={createNewZone}
                    onCreateZoneConfirm={onCreateZoneConfirm}
                    onDeleteZone={onDeleteZone}
                    onEditZone={onEditZone}
                    onFilterZoneInParcels={onFilterZoneInParcels}
                    setCreateNewZone={setCreateNewZone}
                    zonesStats={zonesStats}
                  />
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {editZone && (
        <NameEditDialog
          item={zoneToEdit}
          onAccept={onEditZoneAccept}
          onClose={onEditZoneClose}
          opened={editZone}
          title={<FormattedMessage id="ParcelZone.edit" />}
        />
      )}
    </>
  );
};

export { ParcelZoneStatistics };
