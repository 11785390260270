import React, { Component, Fragment, Suspense } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFarm } from "../../../../shared/api/agroevidence/farms/farms.selectors";
import {
  getParcelSowingPlan,
  getSeasonDetailSowingPlan,
} from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";
import { getIsEditing } from "../../selectors/editor.selectors";
import {
  getParcelDetail,
  isParcelDetailFetching,
  getContext,
  getIsFetching,
  getMainMap,
  getLayers,
  getSelectedSeasonIdSowingPlan,
} from "../../selectors/map.selectors";
import { getWeatherType } from "../../selectors/weather.selectors";

import { setLangId } from "../../../../shared/actions/lang.actions";
import { resetDashboardDrivesHistory } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";
import { assignCrop } from "../../../parcels/shared/actions/parcels.actions";
import { resetMachineDrivesHistory } from "../../actions/mainMapTelematics/mainMapTelematics.actions";
import {
  clearParcelDetail,
  updateParcelName,
  zoomToFarm,
  initMapSaga,
  fetchParcelDetail,
  resetMap,
} from "../../actions/map/map.actions";
// import { setWeatherType } from '../../actions/weather/weather.actions';
import "./MainMap.css";

import {
  PREVIEW,
  TELEMATICS,
  // WEATHER
} from "../../constants/contexts.constants";

import { updateSeasonsSowingPlanApi } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import SectionWrapper from "../../../../shared/components/specific/SectionWrapper/SectionWrapper";
import withConfig from "../../../../shared/hocs/context/withConfig";
import withWidth from "../../../../shared/hocs/withWidth";
import {
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../parcels/shared/services/Parcels.service";
import { HomeControl } from "../../components/HomeControl/HomeControl";
import { LayersCropsControl } from "../../components/LayersCropsControl/LayersCropsControl";
import { MapHoverHint } from "../../components/MapHoverHint/MapHoverHint";
import MapLoader from "../../components/MapLoader/MapLoader";
import MapParcelDetail from "../../components/MapParcelDetail/MapParcelDetail";
import { TelematicsToolbar } from "../../components/TelematicsToolbar/TelematicsToolbar";
// import WeatherSwitcher from '../../components/WeatherSwitcher/WeatherSwitcher';
import ContextSwitcher from "../ContextSwitcher/ContextSwitcher";
import MainMapTelematicsDetail from "../MainMapTelematicsDetail/MainMapTelematicsDetail";
import MapSearch from "../MapSearch/MapSearch";
import MeasurementToolbar from "../MeasurementToolbar/MeasurementToolbar";
import { PreviewToolbar } from "../PreviewToolbar/PreviewToolbar";
import { SnackbarHint } from "../SnackbarHint/SnackbarHint";
import SowingPlanStatistics from "../SowingPlanStatistics/SowingPlanStatistics";
// import WeatherControl from '../WeatherControl/WeatherControl';

export const MAP_ID = "main-map";
export const MAP_SRID_ID = "3857";
export const DATA_SRID_ID = "4326";

export const transformOptions = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};

export class MainMap extends Component {
  componentDidMount() {
    const { config, countryCode, farmId, langId, parcelId } = this.props;
    this.props.initMapSaga(
      farmId,
      langId,
      countryCode,
      config.api,
      config.environment,
      parcelId,
    );
    this.props.updateSeasonsSowingPlanApi(
      START_YEAR_FIRST_SOWING_SEASONS,
      SHOWN_NUMBER_SOWING_SEASONS,
    );
  }

  componentWillUnmount() {
    this.props.resetMachineDrivesHistory();
    this.props.resetDashboardDrivesHistory();
    this.props.resetMap();
  }

  componentDidUpdate(prevProps) {
    const { langId } = this.props;

    if (prevProps.mainMap !== this.props.mainMap) {
      this.main = this.props.mainMap;
    }
    if (prevProps.layers !== this.props.layers) {
      this.layers = this.props.layers;
    }

    if (prevProps.langId !== langId) {
      this.props.setLangId(langId);
    }
  }

  onAssignCrop = (cropId, seasonId, parcelId) => {
    this.props.assignCrop(cropId, seasonId, parcelId).then(() => {
      this.props.fetchParcelDetail(parcelId);
    });
  };

  render() {
    const {
      classes,
      context,
      countryCode,
      farm,
      farmId,
      initDateFrom,
      initDateTo,
      initDriverFilter,
      initMachineFilter,
      isEditing,
      isFetching,
      langId,
      ngRedirectToTelematics,
      parcelDetail,
      parcelDetailFetching,
      parcelSowingPlan,
      seasonDetailSowingPlan,
      // weatherType,
      selectedSeasonId,
      width,
    } = this.props;

    const isCurrentStateSowingPlanSelected = selectedSeasonId === "0";

    return (
      <div className={classes.mainMap} id={MAP_ID}>
        <HomeControl width={width} zoomToHome={this.props.zoomToFarm} />

        <SectionWrapper right={15} top={15}>
          <MapSearch langId={this.props.langId} />
        </SectionWrapper>

        <SectionWrapper
          bottom={15}
          classes={{ custom: classes.contextSwitcherSectionWrapper }}
          left={15}
          right="unset"
        >
          <ContextSwitcher langId={this.props.langId} />
        </SectionWrapper>

        <SectionWrapper left={14} top={17}>
          <LayersCropsControl farmCountryCode={farm?.countryCode} />
        </SectionWrapper>

        <SectionWrapper
          classes={{ custom: classes.editorSectionWrapper }}
          left={width === "xs" || width === "sm" ? 15 : 59}
          top={width === "xs" || width === "sm" ? 59 : 17}
        >
          {context === PREVIEW && <PreviewToolbar />}
          {context === TELEMATICS && (
            <Suspense fallback={<CfLoader />}>
              <TelematicsToolbar
                initDateFrom={initDateFrom}
                initDateTo={initDateTo}
                initDriverFilter={initDriverFilter}
                initMachineFilter={initMachineFilter}
                langId={langId}
              />
            </Suspense>
          )}
          {/* Hide weather service on MainMap
          {context === WEATHER &&
            <WeatherSwitcher
              setWeatherType={this.props.setWeatherType}
              weatherType={weatherType}
            />
          } */}
        </SectionWrapper>

        {/* Hide weather service on MainMap
        {context === WEATHER && <WeatherControl langId={langId} />} */}

        {context === TELEMATICS && (
          <MainMapTelematicsDetail
            langId={langId}
            ngRedirectToTelematics={ngRedirectToTelematics}
          />
        )}

        {context === PREVIEW && (
          <Fragment>
            {!isEditing && parcelDetail && (
              <MapParcelDetail
                assignCropSowingPlan={this.onAssignCrop}
                clearParcelDetail={this.props.clearParcelDetail}
                countryCode={countryCode}
                farmId={farmId}
                isFetching={parcelDetailFetching}
                parcel={parcelDetail}
                parcelSowingPlan={parcelSowingPlan}
                updateParcelName={this.props.updateParcelName}
                isCurrentStateSowingPlanSelected={
                  isCurrentStateSowingPlanSelected
                }
              />
            )}
            {!isCurrentStateSowingPlanSelected && seasonDetailSowingPlan && (
              <SectionWrapper right={15} top={70}>
                <SowingPlanStatistics
                  seasonDetailSowingPlan={seasonDetailSowingPlan}
                />
              </SectionWrapper>
            )}
          </Fragment>
        )}

        {((context === PREVIEW && isCurrentStateSowingPlanSelected) ||
          context === TELEMATICS) && (
          <SectionWrapper right={15} top={59} zIndex={8}>
            <MeasurementToolbar />
          </SectionWrapper>
        )}

        <MapLoader isFetching={isFetching} />
        <SnackbarHint />
        <MapHoverHint />
      </div>
    );
  }
}

MainMap.propTypes = {
  updateParcelName: PropTypes.func.isRequired,
  clearParcelDetail: PropTypes.func.isRequired,
  zoomToFarm: PropTypes.func.isRequired,
  parcelDetail: PropTypes.object,
  parcelDetailFetching: PropTypes.bool,
  config: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  setLangId: PropTypes.func.isRequired,
  // weatherType: PropTypes.string.isRequired,
  // setWeatherType: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  selectedSeasonId: PropTypes.string.isRequired,
  parcelId: PropTypes.string,
  parcelSowingPlan: PropTypes.array,
  seasonDetailSowingPlan: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  initDateFrom: PropTypes.string,
  initDateTo: PropTypes.string,
  initDriverFilter: PropTypes.array,
  initMachineFilter: PropTypes.array,

  resetMachineDrivesHistory: PropTypes.func.isRequired,
  resetDashboardDrivesHistory: PropTypes.func.isRequired,
  ngRedirectToTelematics: PropTypes.func.isRequired,
  initMapSaga: PropTypes.func.isRequired,
  updateSeasonsSowingPlanApi: PropTypes.func.isRequired,
  assignCrop: PropTypes.func.isRequired,
  fetchParcelDetail: PropTypes.func.isRequired,

  mainMap: PropTypes.object,
  layers: PropTypes.object,
};

MainMap.defaultProps = {
  parcelDetail: undefined,
  parcelDetailFetching: false,
  layersConfig: [],
  parcelId: null,
  parcelSowingPlan: null,
  seasonDetailSowingPlan: undefined,
};

const mapStateToProps = (state) => ({
  isEditing: getIsEditing(state),
  isFetching: getIsFetching(state),
  parcelDetail: getParcelDetail(state),
  parcelSowingPlan: getParcelSowingPlan(state),
  parcelDetailFetching: isParcelDetailFetching(state),
  weatherType: getWeatherType(state),
  context: getContext(state),
  mainMap: getMainMap(state),
  layers: getLayers(state),
  selectedSeasonId: getSelectedSeasonIdSowingPlan(state),
  seasonDetailSowingPlan: getSeasonDetailSowingPlan(state),
  farm: getFarm(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateParcelName,
      clearParcelDetail,
      zoomToFarm,
      setLangId,
      // setWeatherType,
      resetMachineDrivesHistory,
      resetDashboardDrivesHistory,
      initMapSaga,
      updateSeasonsSowingPlanApi,
      assignCrop,
      fetchParcelDetail,
      resetMap,
    },
    dispatch,
  );

const styles = (theme) => ({
  mainMap: {
    height: "100%",
    position: "relative",
  },
  editorSectionWrapper: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "calc(100% - 350px)",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  contextSwitcherSectionWrapper: {
    flexDirection: "column",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withConfig(), withStyles(styles), withWidth())(MainMap));
