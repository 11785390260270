import React, { useContext, useEffect, useState } from "react";

import { Button, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage, useIntl, FormattedDate } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getAvailableUpdatesError,
  getFarm,
  getFarmAvailableUpdates,
  getUpdateLPISResults,
} from "../../../shared/api/agroevidence/farms/farms.selectors";

import {
  putFarmUpdateLpisApi,
  getFarmApi,
  resetFarmApi,
  getFarmUpdateLpisResultsApi,
  resetFarmUpdateLpisResultsApi,
  getFarmAvailableUpdatesApi,
  createFarmAvailableUpdatesApi,
  resetFarmAvailableUpdatesApi,
} from "../../../shared/api/agroevidence/farms/farms.api";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { UpdateLpisIcon } from "../../../shared/icons/navbar/UpdateLpisIcon";
import { AsyncFn, Thunk } from "../../../types";

import { UpdateLpisAvailableUpdatesDialog } from "./UpdateLpisAvailableUpdatesDialog";
import UpdateLpisDialog from "./UpdateLpisDialog";
import UpdateLpisErrorDialog from "./UpdateLpisErrorDialog";
import { UpdateLpisNewParcelsDialog } from "./UpdateLpisNewParcelsDialog";
import { UpdateLpisRenderStatusAvailableUpdates } from "./UpdateLpisRenderStatusAvailableUpdates";

import { UserState } from "../../../reducers/user.reducer.types";
import {
  FarmUpdateChange,
  FarmUpdateChangeRequestStatus,
  FarmUpdateFlatResponse,
  FarmUpdateState,
} from "../../../shared/api/agroevidence/agroevidence.types";

enum DIALOG_OPEN {
  UPDATE_DIALOG_LPIS,
  ERROR_DIALOG,
  AVAILABLE_UPDATES_DIALOG,
  SUCCESSFUL_UPDATE_DIALOG,
  NONE,
}

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  createFarmAvailableUpdatesApi: () => Promise<void>;
  farmId: string;
  getFarmAvailableUpdatesApi: () => Promise<void>;
  putFarmUpdateLpisApi: (
    farmId: string,
    date: string,
    updateType: string,
  ) => Promise<void>;
};

type UpdateLpisProps = OwnProps & ReduxProps;

const UpdateLpis = ({
  availableUpdates,
  availableUpdatesError,
  createFarmAvailableUpdatesApi,
  farmId,
  getFarmApi,
  getFarmAvailableUpdatesApi,
  getFarmUpdateLpisResultsApi,
  putFarmUpdateLpisApi,
  resetFarmApi,
  resetFarmAvailableUpdatesApi,
  resetFarmUpdateLpisResultsApi,
  updateLpisResults = [],
}: UpdateLpisProps) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const intl = useIntl();

  const [currentOpenDialog, setCurrentOpenDialog] = useState<DIALOG_OPEN>(
    DIALOG_OPEN.NONE,
  );

  const [availableUpdatesDate, setAvailableUpdatesDate] = useState<Date>();
  const [filteredChanges, setFilteredChanges] = useState<FarmUpdateChange[]>(
    [],
  );
  const { on, setOff, setOn } = useToggle(false);

  useEffect(() => {
    if (
      updateLpisResults[0]?.newAutomaticParcels &&
      updateLpisResults[0]?.newAutomaticParcels?.length !== 0 &&
      updateLpisResults[0]?.state === FarmUpdateState.SUCCESS
    ) {
      setCurrentOpenDialog(DIALOG_OPEN.SUCCESSFUL_UPDATE_DIALOG);
    }
  }, [updateLpisResults]);

  useEffect(() => {
    getFarmApi(farmId);
    getFarmUpdateLpisResultsApi();

    const interval = setInterval(() => {
      getFarmUpdateLpisResultsApi();
    }, 100000);

    return () => {
      resetFarmApi();
      resetFarmUpdateLpisResultsApi();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (on) {
      getFarmAvailableUpdatesApi();
      interval = setInterval(() => {
        getFarmAvailableUpdatesApi();
      }, 100000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [on, getFarmAvailableUpdatesApi]);

  useEffect(() => {
    if (
      availableUpdates !== null &&
      availableUpdates.status === FarmUpdateChangeRequestStatus.AVAILABLE &&
      availableUpdates.changes
    ) {
      const oldestDate = availableUpdates.changes.reduce(
        (oldest, change) => {
          if (
            change.changeDate &&
            (!oldest || new Date(change.changeDate) < new Date(oldest))
          ) {
            return change.changeDate;
          }
          return oldest;
        },
        undefined as string | undefined,
      );
      if (oldestDate) {
        const availableUpdatesFilteredChanges = availableUpdates.changes.filter(
          (change) => change.changeDate === oldestDate,
        );
        setFilteredChanges(availableUpdatesFilteredChanges);
        setAvailableUpdatesDate(new Date(oldestDate));
        setCurrentOpenDialog(DIALOG_OPEN.AVAILABLE_UPDATES_DIALOG);
      } else if (moment().diff(availableUpdates.validityDate, "days") === 0) {
        setCurrentOpenDialog(DIALOG_OPEN.AVAILABLE_UPDATES_DIALOG);
        setAvailableUpdatesDate(new Date(availableUpdates.validityDate));
      } else {
        handleDialogOpen();
      }
    } else if (
      availableUpdates === null &&
      availableUpdatesError &&
      availableUpdatesError.isError &&
      availableUpdatesError.code !== 404 &&
      availableUpdatesError.code !== 422
    ) {
      handleDialogOpen();
    }

    if (availableUpdatesError.code === 422) {
      showSnackbar({
        message: (
          <FormattedMessage
            id={`error.${availableUpdatesError?.response?.error}`}
          />
        ),
        isError: true,
      });
    }
  }, [availableUpdates, availableUpdatesError]);

  const handleDialogOpen = () => {
    setCurrentOpenDialog(DIALOG_OPEN.UPDATE_DIALOG_LPIS);
  };
  const handleDialogClose = () => {
    setCurrentOpenDialog(DIALOG_OPEN.NONE);
    setOff();
  };

  const handleErrorDialogOpen = () => {
    setCurrentOpenDialog(DIALOG_OPEN.ERROR_DIALOG);
  };
  const handleErrorDialogClose = () => {
    setCurrentOpenDialog(DIALOG_OPEN.NONE);
  };

  const handleAvailableUpdateDialogClose = () => {
    setOff();
    handleDialogOpen();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDialogAccept = (
    wsKey: string,
    date: string,
    updateType: string,
  ) => {
    resetFarmAvailableUpdatesApi();
    // TODO add date as parametrs into putFarmUpdateLpisApi.
    // wsKey will send to BE with new endpoint that will be create
    (putFarmUpdateLpisApi as AsyncFn<string, string, string>)(
      farmId,
      date,
      updateType,
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: res.payload.response?.message,
          isError: true,
        });
      }
      getFarmUpdateLpisResultsApi();
      setAvailableUpdatesDate(undefined);
      handleDialogClose();
    });
  };

  const getAvailableUpdates = () => {
    (getFarmAvailableUpdatesApi as AsyncFn)().then((res) => {
      if (res.payload.changes) {
        setOn();
      } else if (res.payload.status === 404) {
        (createFarmAvailableUpdatesApi as AsyncFn)().then((res) => {
          if (res.error && res.payload?.status === 409) {
            showSnackbar({
              message: res.payload.response.message,
              isError: true,
            });
          } else {
            setOn();
          }
        });
      }
    });
  };

  const renderStatus = (data: FarmUpdateFlatResponse[]) => {
    if (data.length === 0 || data[0].state === FarmUpdateState.SUCCESS) {
      return "";
    }
    if (data[0].state === FarmUpdateState.IN_PROGRESS) {
      return (
        <>
          <div className={classes.loading}>
            <CfLoader />
          </div>
          <div>
            {intl.formatMessage({
              id: "UpdateLPIS.lastUpdateState.inprogress",
            })}
          </div>
        </>
      );
    }
    const date = <FormattedDate value={data[0].startedAt} />;

    return (
      <div>
        {intl.formatMessage(
          { id: "UpdateLPIS.lastUpdateState.failure" },
          { date },
        )}{" "}
        <button className={classes.errorButton} onClick={handleErrorDialogOpen}>
          <FormattedMessage id="UpdateLPIS.lastUpdateState.failure_button" />
        </button>
      </div>
    );
  };

  const renderSuccessUpdateTime = (data: FarmUpdateFlatResponse[]) =>
    data[0]?.lastSuccessUpdateDate ? (
      <span>
        <FormattedDate value={updateLpisResults[0]?.lastSuccessUpdateDate} />
      </span>
    ) : (
      "-"
    );

  const isUpdateInProgress =
    updateLpisResults[0]?.state === FarmUpdateState.IN_PROGRESS;
  const isAvailableUpdatesInProgress =
    availableUpdates?.status === FarmUpdateChangeRequestStatus.IN_PROGRESS;

  return (
    <div className={classes.wrapper}>
      {currentOpenDialog === DIALOG_OPEN.UPDATE_DIALOG_LPIS && (
        <UpdateLpisDialog
          availableUpdateDate={availableUpdatesDate}
          lastUpdateDate={updateLpisResults[0]?.lastSuccessUpdateDate}
          onAccept={handleDialogAccept}
          onClose={handleDialogClose}
        />
      )}
      {currentOpenDialog === DIALOG_OPEN.ERROR_DIALOG && (
        <UpdateLpisErrorDialog
          farmId={farmId}
          onClose={handleErrorDialogClose}
          updateLpisResult={updateLpisResults[0]}
        />
      )}
      {currentOpenDialog === DIALOG_OPEN.AVAILABLE_UPDATES_DIALOG &&
        availableUpdates?.status === FarmUpdateChangeRequestStatus.AVAILABLE &&
        availableUpdatesDate && (
          <UpdateLpisAvailableUpdatesDialog
            availableUpdatesNewestDate={availableUpdatesDate}
            availableUpdatesValidityDate={availableUpdates.validityDate}
            filteredChanges={filteredChanges}
            onClose={handleAvailableUpdateDialogClose}
          />
        )}
      {currentOpenDialog === DIALOG_OPEN.SUCCESSFUL_UPDATE_DIALOG &&
        availableUpdates?.status !==
          FarmUpdateChangeRequestStatus.IN_PROGRESS && (
          <UpdateLpisNewParcelsDialog
            farmId={farmId}
            newParcels={updateLpisResults[0]?.newAutomaticParcels ?? []}
            onClose={handleDialogClose}
          />
        )}
      <PageHeader
        classes={{ header: classes.header }}
        heading={
          <PageHeading value={<FormattedMessage id="UpdateLPIS.title" />} />
        }
      />
      <div className={classes.info}>
        <div className={classes.lastSuccessUpdateDate}>
          <FormattedMessage id="UpdateLPIS.lastSuccessUpdateDate" />
          {renderSuccessUpdateTime(updateLpisResults)}
        </div>
        {renderStatus(updateLpisResults)}
        <UpdateLpisRenderStatusAvailableUpdates
          availableUpdates={availableUpdates}
        />
      </div>
      <Stack alignItems="center" gap={6}>
        <div className={classes.button}>
          <Button
            color="primary"
            data-test="update-lpis"
            disabled={isUpdateInProgress || isAvailableUpdatesInProgress}
            onClick={getAvailableUpdates}
            variant="contained"
          >
            <UpdateLpisIcon className={classes.icon} />
            <FormattedMessage id="UpdateLPIS.button" />
          </Button>
        </div>
        <Stack gap={2}>
          <span className={classes.manualHeader}>
            <FormattedMessage id="UpdateLPIS.manual.header" />
          </span>
          <Stack className={classes.manualItemsContainer}>
            <span>
              - <FormattedMessage id="UpdateLPIS.manual.item1" />
            </span>
            <span>
              - <FormattedMessage id="UpdateLPIS.manual.item2" />
            </span>
            <span>
              - <FormattedMessage id="UpdateLPIS.manual.item3" />
            </span>
            <span>
              - <FormattedMessage id="UpdateLPIS.manual.item4" />
            </span>
          </Stack>
          <span>
            <FormattedMessage id="UpdateLPIS.manual.suggestion" />
          </span>
        </Stack>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state: UserState) => ({
  farm: getFarm(state),
  availableUpdatesError: getAvailableUpdatesError(state),
  updateLpisResults: getUpdateLPISResults(state),
  availableUpdates: getFarmAvailableUpdates(state),
});

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      putFarmUpdateLpisApi,
      getFarmApi,
      resetFarmApi,
      getFarmUpdateLpisResultsApi,
      resetFarmUpdateLpisResultsApi,
      getFarmAvailableUpdatesApi,
      createFarmAvailableUpdatesApi,
      resetFarmAvailableUpdatesApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UpdateLpis);

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
  info: {
    marginTop: 48,
    textAlign: "center",
  },
  icon: {
    marginRight: 10,
    color: "#FFFFF",
  },
  errorButton: {
    color: theme.palette.error.main,
    background: "none",
    border: "none",
    padding: 0,
    textDecoration: "underline",
    cursor: "pointer",
  },
  lastSuccessUpdateDate: {
    marginBottom: 10,
  },
  loading: {
    margin: "10px 0px",
  },
  manualHeader: {
    fontWeight: 500,
  },
  manualItemsContainer: {
    marginLeft: 20,
  },
}));
