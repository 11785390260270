import React, { MouseEvent, ReactNode } from "react";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

interface Props {
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  tooltipTitle?: string | ReactNode;
}

const CfTableIconButton = ({
  children,
  disabled = false,
  onClick,
  tooltipTitle = "",
}: Props) => {
  const classes = useStyles();
  return (
    <TableCell>
      <Tooltip placement="bottom" title={tooltipTitle || ""}>
        <div className={classes.wrapper}>
          <IconButton
            disabled={disabled}
            onClick={onClick}
            size="large"
            classes={{
              root: classes.button,
            }}
          >
            {children}
          </IconButton>
        </div>
      </Tooltip>
    </TableCell>
  );
};

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: 6,
  },
});

export { CfTableIconButton };
