import { queryOptions, useMutation } from "@tanstack/react-query";

import {
  createSeason,
  getCrops,
  GetCropsParams,
  getDashboardPaged,
  GetDashboardPagedParams,
  getSeasons,
  GetSeasonsParams,
  updateSeason1,
} from "../../../generated/api/satellite";
import { useFarmIds } from "../../../shared/api/client.utils";
import { staleTime } from "../../../shared/query-client";
import { getCountedData } from "./helper";

const satelliteDashboardKey = ["satellite", "dashboard"];

const parcelDashboardQuery = (payload: GetDashboardPagedParams) =>
  queryOptions({
    queryKey: [...satelliteDashboardKey, ...Object.values(payload)],
    queryFn: async () => {
      const data = await getDashboardPaged(payload);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const dashboardCropsQuery = (payload: GetCropsParams) =>
  queryOptions({
    queryKey: [...satelliteDashboardKey, "crops", ...Object.values(payload)],
    queryFn: () => getCrops(payload),
    staleTime: staleTime.Infinity,
  });

const useCreateSeasonMutation = () =>
  useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      payload: Parameters<typeof createSeason>[0];
      params: Parameters<typeof createSeason>[1];
    }) => createSeason(payload, params),
  });

const useUpdateSeasonMutation = () => {
  const farmIds = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateSeason1>[0]) =>
      updateSeason1(payload, farmIds),
  });
};

const precisionSeasonsQuery = (payload: GetSeasonsParams) =>
  queryOptions({
    queryKey: [...satelliteDashboardKey, "seasons", payload.farmIds, payload],
    queryFn: () => getSeasons(payload),
    staleTime: staleTime.Infinity,
  });

export {
  parcelDashboardQuery,
  satelliteDashboardKey,
  dashboardCropsQuery,
  useCreateSeasonMutation,
  useUpdateSeasonMutation,
  precisionSeasonsQuery,
};
