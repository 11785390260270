import React, { useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { CfFilterV2 } from "../../shared/containers/CfFilter/CfFilterV2";
import CfAutocomplete from "../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { InternalCropTo } from "../../generated/api/satellite";

export type AdvancedFilterType = {
  crops?: InternalCropTo[];
};

type Props = {
  advancedFilter: AdvancedFilterType;
  setAdvancedFilter: (val: AdvancedFilterType) => void;
  crops: InternalCropTo[];
};

const AdvancedFilter = ({
  advancedFilter,
  setAdvancedFilter,
  crops,
}: Props) => {
  const classes = useStyles();

  const [filter, setFilter] = useState(advancedFilter);

  const onClose = () => {
    setFilter(advancedFilter);
  };

  return (
    <CfFilterV2
      advancedFilter={advancedFilter}
      filterState={filter}
      setAdvancedFilter={setAdvancedFilter}
      setAdvancedFilterState={setFilter}
      onClose={onClose}
    >
      <Grid className={classes.container} container>
        <Grid className={classes.selectItem} item xs={12}>
          <CfAutocomplete
            defaultValues={filter?.crops || []}
            id="pz-services-filter"
            isFetching={false}
            isMultiple
            suggestions={crops}
            testId="node-type-filter"
            label={<FormattedMessage id="common.crops" />}
            onChange={(values) => {
              setFilter({ ...filter, crops: values });
            }}
          />
        </Grid>
      </Grid>
    </CfFilterV2>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  selectItem: {
    marginBottom: 10,
  },
}));

export { AdvancedFilter };
