import { queryOptions, useMutation } from "@tanstack/react-query";

import {
  deleteParcelFromSeason,
  getSeasonDashboard,
  GetSeasonDashboardParams,
} from "../../../generated/api/satellite";
import { getCountedData } from "../../../shared/api/client.utils";
import { staleTime } from "../../../shared/query-client";

const satelliteServicesKey = ["satellite", "services"];

const servicesQuery = (seasonId: string, params: GetSeasonDashboardParams) =>
  queryOptions({
    queryKey: [...satelliteServicesKey, ...Object.values(params), seasonId],
    queryFn: async () => {
      const data = await getSeasonDashboard(seasonId, params);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const useServicesDelete = () =>
  useMutation({
    mutationFn: (params: Parameters<typeof deleteParcelFromSeason>) =>
      deleteParcelFromSeason(...params),
  });

export { satelliteServicesKey, servicesQuery, useServicesDelete };
