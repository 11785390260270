import React, { useEffect, useRef } from "react";
import { useCurrentWidth } from "../../../../../shared/hooks/useWidth";

const useTablePaging = () => {
  const [seasonsPerPage, setSeasonsPerPage] = React.useState(0);
  const [seasonsOffset, setSeasonsOffset] = React.useState(0);
  const [parcelsPage, setParcelsPage] = React.useState(0);
  const [parcelsPerPage, setParcelsPerPage] = React.useState(25);

  const width = useCurrentWidth();
  const previousWidth = useRef<number>(width);

  const CELL_WIDTH = 260;
  const MAX_WIDTH = 1880;

  useEffect(() => {
    if (width >= MAX_WIDTH) setSeasonsPerPage(6);
    else if (width >= MAX_WIDTH - 1 * CELL_WIDTH) setSeasonsPerPage(5);
    else if (width >= MAX_WIDTH - 2 * CELL_WIDTH) setSeasonsPerPage(4);
    else if (width >= MAX_WIDTH - 3 * CELL_WIDTH) setSeasonsPerPage(3);
    else if (width >= MAX_WIDTH - 4 * CELL_WIDTH) setSeasonsPerPage(2);
    else if (width >= MAX_WIDTH - 5 * CELL_WIDTH) setSeasonsPerPage(1);
    else setSeasonsPerPage(1);

    const isWider = width > previousWidth.current;
    if (isWider) {
      setParcelsPage(0);
    }
    previousWidth.current = width;
  }, [width]);

  return {
    seasonsPerPage,
    seasonsOffset,
    setSeasonsOffset,
    parcelsPage,
    parcelsPerPage,
    setParcelsPage,
    setParcelsPerPage,
  };
};

export { useTablePaging };
