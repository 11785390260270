import { IndiceType } from "../selectors/indices";

type IndexObject = {
  id: number;
  color: string;
  borderColor?: string;
};

type IndexZoneColors = {
  [key in IndiceType]: IndexObject[];
};

type ZoneColors = IndexZoneColors & {
  SAVI: Array<
    IndexObject & {
      min: number;
      max: number;
    }
  >;
};

export const zonesColors: ZoneColors = {
  CAB: [
    { id: 1, color: "#FFFFD9", borderColor: "#ABABAB" },
    { id: 2, color: "#EFF9B6", borderColor: "#ABABAB" },
    { id: 3, color: "#BBE098" },
    { id: 4, color: "#93C56E" },
    { id: 5, color: "#72AA45" },
    { id: 6, color: "#5A912F" },
    { id: 7, color: "#4A7D27" },
    { id: 8, color: "#3A6920" },
    { id: 9, color: "#274F16" },
    { id: 10, color: "#16390E" },
  ],
  CW: [
    { id: 1, color: "#FFFFD9", borderColor: "#ABABAB" },
    { id: 2, color: "#EFF9B6", borderColor: "#ABABAB" },
    { id: 3, color: "#C9E9AF" },
    { id: 4, color: "#A6D0B9" },
    { id: 5, color: "#88B9C6" },
    { id: 6, color: "#669EC5" },
    { id: 7, color: "#4081B4" },
    { id: 8, color: "#23649B" },
    { id: 9, color: "#154474" },
    { id: 10, color: "#052652" },
  ],
  LAI: [
    { id: 1, color: "#FFFFD9", borderColor: "#ABABAB" },
    { id: 2, color: "#EFF9B6", borderColor: "#ABABAB" },
    { id: 3, color: "#CAE9AF" },
    { id: 4, color: "#9DD6A6" },
    { id: 5, color: "#6FC398" },
    { id: 6, color: "#45AC84" },
    { id: 7, color: "#2A8F6C" },
    { id: 8, color: "#247150" },
    { id: 9, color: "#1B5534" },
    { id: 10, color: "#163914" },
  ],
  SAVI: [
    { color: "#F45F00", min: -1.5, max: 0 },
    { color: "#FF7C04", min: 0.0, max: 0.2 },
    { color: "#FF9B1C", min: 0.2, max: 0.3 },
    { color: "#FFC148", min: 0.3, max: 0.4 },
    { color: "#FFD37E", min: 0.4, max: 0.5 },
    { color: "#E5FF9F", min: 0.5, max: 0.6 },
    { color: "#B0FF55", min: 0.6, max: 0.7 },
    { color: "#92F520", min: 0.7, max: 0.8 },
    { color: "#58DA00", min: 0.8, max: 0.9 },
    { color: "#2FC400", min: 0.9, max: 1.0 },
    { color: "#17A500", min: 1.0, max: 1.1 },
    { color: "#00810A", min: 1.1, max: 1.2 },
    { color: "#006107", min: 1.2, max: 1.3 },
    { color: "#01410D", min: 1.3, max: 1.5 },
  ].map((item, i) => ({ ...item, id: i + 1 })),
  HIST: [],
  BSI: [],
  RGB: [],
  SAMPLES: [],
};

const hasMinMax = (
  zoneColor: (typeof zonesColors)[keyof typeof zonesColors][number],
): zoneColor is typeof zoneColor & { min: number; max: number } =>
  "min" in zoneColor && "max" in zoneColor;

export { hasMinMax };
