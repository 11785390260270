import React, { useCallback, useEffect, useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Theme,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import { debounce } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsFetchingActiveSubstances,
  getPlantProtection,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchPlantProtectionByName } from "../../../shared/actions/actions.actions";

import { getSourceId } from "../../../../../catalogues/helpers/selectorHelpers";
import { resetPlantProtectionsApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../../../../shared/hooks/usePopover";
import {
  getLocalizedDateString,
  getShortDateString,
} from "../../../../../shared/misc/timeHelpers";
import { COLOR_SECONDARY } from "../../../../../theme";

import { PlantProtectionSuggestionTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultValues: unknown;
  disabled: boolean;
  error?: boolean;
  label: React.ReactNode;
  onChange: (item: unknown) => void;
}

const PlantProtectionSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  label,
  onChange,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const popover = usePopover();

  const plantProtections = useSelector(getPlantProtection);
  const isFetching = useSelector(getIsFetchingActiveSubstances);

  const [targetCrop] = useField("targetCrop");

  const [showUnregisteredProducts, setShowUnregisteredProducts] =
    useState(false);
  const [showHistorical, setShowHistorical] = useState(false);

  const handleGetPlantProtections = useCallback(
    (search: string) => {
      const usableUntil = !showHistorical ? getShortDateString() : undefined;
      const targetCropId =
        !showUnregisteredProducts && targetCrop.value
          ? targetCrop.value.id
          : undefined;

      dispatch(fetchPlantProtectionByName(search, usableUntil, targetCropId));
    },
    [dispatch, showHistorical, showUnregisteredProducts, targetCrop.value],
  );

  useEffect(() => {
    handleGetPlantProtections("");

    return () => {
      dispatch(resetPlantProtectionsApi());
    };
  }, [dispatch, handleGetPlantProtections, targetCrop.value]);

  const debouncedOptions = debounce(handleGetPlantProtections, 500);

  const handleRenderOption = (option: PlantProtectionSuggestionTo) => {
    const catalogueType = option.catalogue?.type
      ? intl.formatMessage({ id: getSourceId(option.catalogue.type) })
      : null;

    const usableUntil = option.validUntil
      ? getLocalizedDateString(option.validUntil)
      : "-";

    const optionValue = [option.name, catalogueType, usableUntil].join(", ");

    if (option.isFavorite) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StarRoundedIcon
            style={{ color: COLOR_SECONDARY.cleverfarm.main, marginRight: 5 }}
          />{" "}
          {optionValue}
        </div>
      );
    }
    return optionValue;
  };

  const someFilterSelected = showUnregisteredProducts || showHistorical;

  return (
    <Grid
      alignItems="flex-end"
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={11}>
        <CfAutocomplete
          clearInputOnSelect
          defaultValues={defaultValues}
          disabled={disabled}
          error={error}
          id="plant-protection-selector"
          isFetching={isFetching}
          label={label}
          loadOptions={debouncedOptions}
          onChange={onChange}
          renderOption={handleRenderOption}
          suggestions={plantProtections}
          testId="aplant-protection-selector"
          helperText={
            error ? <FormattedMessage id="validation.required" /> : ""
          }
        />
      </Grid>
      <Grid item xs={1}>
        <Tooltip
          disableInteractive
          title={<FormattedMessage id="Por.selector.placeholder.tooltip" />}
        >
          <span>
            <CfSwitcherButton
              className={someFilterSelected ? classes.selectedFilter : ""}
              onClick={popover.handlePopoverOpen}
              withArrowDropDownIcon={false}
            >
              <FilterListIcon />
            </CfSwitcherButton>
          </span>
        </Tooltip>
        <Popover
          anchorEl={popover.anchorEl}
          classes={{ paper: classes.popoverPaper }}
          onClose={popover.handlePopoverClose}
          open={popover.isOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <FormGroup>
            <FormControlLabel
              checked={showUnregisteredProducts}
              control={<Checkbox />}
              data-test="unregistred-ppp"
              label={
                <FormattedMessage id="Por.selector.filter.showUnregisteredProducts" />
              }
              onChange={() =>
                setShowUnregisteredProducts(!showUnregisteredProducts)
              }
            />
            <FormControlLabel
              checked={showHistorical}
              control={<Checkbox />}
              data-test="invalid-ppp"
              onChange={() => setShowHistorical(!showHistorical)}
              label={
                <FormattedMessage id="common.selector.filter.showHistory" />
              }
            />
          </FormGroup>
        </Popover>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    overflow: "hidden",
    padding: 10,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export { PlantProtectionSelector };
