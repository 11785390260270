import { toNumber } from "lodash";
import moment, { Moment } from "moment";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import {
  ActionEPHDetailTo,
  ActionExpenseFertilizerDetailTo,
  ActionExpensePlantProtectionDetailTo,
  ActionParcelDetailTo,
  ActiveSubstanceTo,
  ParcelPlantProtectionEffectivenessTo,
  PlantProtectionApplicationTo,
  PlantProtectionEffectiveness,
  PlantProtectionRegistrationApplicationBufferTo,
  RestrictionType,
  SubtractionResponse,
  TargetSeedApplicationDetailTo,
  UnitType,
} from "../../../generated/api/agroevidence";
import {
  getLocalizedDateString,
  getShortDateString,
} from "../../../shared/misc/timeHelpers";
import Localization from "../../../shared/services/Localization.service";
import { AnyTodo } from "../../../types";

import {
  ActionEphFormValues,
  EphPlantProtectionsType,
  MappedActivePest,
  PlantProtectionApplicationRestrictionType,
  PlantProtectionApplicationDataByParcelsType,
  PlantProtectionApplicationDataType,
  PlantProtectionRestrictionWarningsType,
  EphFertilizersType,
} from "./actionEph.types";
import { FormPlantProtectionApplications } from "../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.types";
import {
  ExtInitialParcelToAdd,
  InitialParcelToAdd,
  SubtractableAreasType,
} from "../ActionOthers/actionOther.types";

export const initialEphFormEmptyValues: ActionEphFormValues = {
  date: moment().startOf("day"),
  parcels: [],
  isStrawDecay: false,
  targetCrop: undefined,
  description: "",
  plantProtections: [],
  fertilizers: [],
};

export const mapEphFormInitialValues = (
  eph: ActionEPHDetailTo,
  locale: LANGUAGE_ID,
) => {
  const standardFields = {
    parcels: mapParcelsFrom(eph.parcels),
    date: moment(eph.date).startOf("day"),
    isStrawDecay: eph.isStrawDecay || initialEphFormEmptyValues.isStrawDecay,
    description: eph.note || initialEphFormEmptyValues.description,
    plantProtections: eph.isDraft
      ? initialEphFormEmptyValues.plantProtections
      : mapPlantProtectionInitValues(
          eph.plantProtections ?? [],
          eph.parcels,
          locale,
        ),
    fertilizers: eph.isDraft
      ? initialEphFormEmptyValues.fertilizers
      : mapFertilizersInitValues(eph.fertilizers ?? [], locale),
  };

  return {
    ...standardFields,
    ...(eph.targetCrop && { targetCrop: eph.targetCrop }),
  };
};

const mapParcelsFrom = (parcels: ActionParcelDetailTo[]) =>
  parcels
    ?.map((p: ActionParcelDetailTo) => ({
      ...p.parcel,
      subtractableAreas: mapSubtractableAreasFrom(p.restrictions),
      actionParcelTotalArea: toNumber(p.actionArea.toFixed(5)),
      restrictedArea: p.restrictedArea,
    }))
    .sort((a: AnyTodo, b: AnyTodo) => a.id - b.id);

const mapSubtractableAreasFrom = (p: SubtractionResponse[]) => {
  const areas: SubtractableAreasType = {
    absolute: p.filter((sa: SubtractionResponse) => sa.type === "Absolute"),
    boundary: p.filter((sa: SubtractionResponse) => sa.type === "Boundary"),
    water: p.filter((sa: SubtractionResponse) => sa.type === "Water"),
    boundaryChecked:
      p
        .filter((sa: SubtractionResponse) => sa.type === "Boundary")
        .find((sa: SubtractionResponse) => sa.isUsed === true)?.value || 0,
    waterChecked:
      p
        .filter((sa: SubtractionResponse) => sa.type === "Water")
        .find((sa: SubtractionResponse) => sa.isUsed === true)?.value || 0,
  };
  return areas;
};

const mapPlantProtectionInitValues = (
  plantProtections: ActionExpensePlantProtectionDetailTo[],
  parcels: ActionParcelDetailTo[],
  locale: LANGUAGE_ID,
) =>
  plantProtections.map((plantProtection) => ({
    id: plantProtection.material.id,
    name: plantProtection.material.name,
    pests: plantProtection.targetOrganisms,
    driftClass: plantProtection.actionMaterial.driftClass,
    dosePerHectare: Localization.num2strNonFixed(
      plantProtection.actionExpense.dosePerHa,
      locale,
      5,
    ),
    doseUnit: plantProtection.actionExpense.unit,
    dosage: Localization.num2strNonFixed(
      plantProtection.actionExpense.amount,
      locale,
      5,
    ),
    note: plantProtection.actionExpense.notes,
    effectiveness: plantProtection.parcelPlantProtectionEffectiveness.map(
      (effectivenessItem: ParcelPlantProtectionEffectivenessTo) => ({
        parcelName: parcels.find(
          (item: ActionParcelDetailTo) =>
            item.parcel.id === effectivenessItem.parcelId,
        )?.parcel.localName,
        parcelId: effectivenessItem.parcelId,
        value: effectivenessItem.effectiveness,
      }),
    ),
  }));

const mapFertilizersInitValues = (
  fertilizers: ActionExpenseFertilizerDetailTo[],
  locale: LANGUAGE_ID,
) =>
  fertilizers.map((fertilizer) => ({
    id: fertilizer.material.id,
    name: fertilizer.material.name,
    dosePerHectare: Localization.num2strNonFixed(
      fertilizer.actionExpense.dosePerHa,
      locale,
      5,
    ),
    doseUnit: fertilizer.actionExpense.unit,
    dosage: Localization.num2strNonFixed(
      fertilizer.actionExpense.amount,
      locale,
      5,
    ),
    note: fertilizer.actionExpense.notes,
    defaultUnit: fertilizer.actionExpense?.unit,
    dateSoilIncorporation: fertilizer.actionMaterial.dateSoilIncorporation,
    preferredUnitType:
      fertilizer.actionExpense?.unit === "kg"
        ? UnitType.WEIGHT
        : UnitType.VOLUME,
  }));

// ==================================================================
// ======================== MAP REQUEST BODY ========================
// ==================================================================

export const mapRequestBodyEphActionTo = (
  values: ActionEphFormValues,
  locale: LANGUAGE_ID,
  actionId?: string,
) => {
  const payload: AnyTodo = {
    date: getShortDateString(values.date),
    note: values.description,
    parcels: mapParcels(values.parcels),
    targetCropId: values.targetCrop?.id,
    isStrawDecay: values.isStrawDecay,
    plantProtections: mapPlantProtection(
      values.plantProtections,
      values.parcels,
      locale,
    ),
    fertilizers: mapFertilizers(values.fertilizers, locale),
  };

  if (actionId !== undefined) {
    payload.id = actionId;
  }

  return payload;
};

const mapParcels = (parcels: InitialParcelToAdd[]) =>
  parcels.map((parcel) => ({
    parcelId: parcel.id,
    restrictions: mapParcelSubtractableAreas(parcel),
  }));

const mapParcelSubtractableAreas = (parcel: InitialParcelToAdd) => {
  const areas = parcel.subtractableAreas.absolute
    .filter((sa: SubtractionResponse) => sa.isUsed === true)
    .map((sa: SubtractionResponse) => ({
      type: sa.type,
      value: sa.value,
    }));
  if (
    !(
      toNumber(parcel.subtractableAreas.boundaryChecked) === 0 ||
      parcel.subtractableAreas.boundaryChecked === undefined
    )
  ) {
    areas.push({
      type: RestrictionType.Boundary,
      value: toNumber(parcel.subtractableAreas.boundaryChecked),
    });
  }
  if (
    !(
      toNumber(parcel.subtractableAreas.waterChecked) === 0 ||
      parcel.subtractableAreas.waterChecked === undefined
    )
  ) {
    areas.push({
      type: RestrictionType.Water,
      value: toNumber(parcel.subtractableAreas.waterChecked),
    });
  }
  return areas;
};

const mapPlantProtection = (
  expenses: AnyTodo,
  parcels: InitialParcelToAdd[],
  locale: LANGUAGE_ID,
) => {
  const mapPlantProtection = expenses.map((exp: AnyTodo) => ({
    actionMaterial: {
      materialId: exp.id,
      driftClass: exp?.driftClass?.code,
    },
    actionExpense: {
      amount: Localization.str2numNonFixed(exp.dosage ?? 0, locale),
      unit: exp.doseUnit?.id ?? exp.doseUnit,
      dosePerHa: Localization.str2numNonFixed(exp.dosePerHectare ?? 0, locale),
      notes: exp.note,
    },
    targetOrganismsIds: exp.pests?.map(
      (item: MappedActivePest) => item.organism?.id ?? item?.id,
    ),
    parcelPlantProtectionEffectiveness: exp.effectiveness
      ? exp.effectiveness.map((item: AnyTodo) => ({
          parcelId: item.parcelId,
          effectiveness: item.value,
        }))
      : parcels.map((item: InitialParcelToAdd) => ({
          parcelId: item.id,
          effectiveness: PlantProtectionEffectiveness.YES,
        })),
  }));
  return mapPlantProtection || [];
};

const mapFertilizers = (expenses: AnyTodo, locale: LANGUAGE_ID) => {
  const mapFertilizers = expenses.map((exp: AnyTodo) => ({
    actionMaterial: {
      materialId: exp.id,
      driftClass: exp?.driftClass?.code,
      dateSoilIncorporation: exp.dateSoilIncorporation,
    },
    actionExpense: {
      amount: Localization.str2numNonFixed(exp.dosage ?? 0, locale),
      unit: exp.doseUnit?.id ?? exp.doseUnit,
      dosePerHa: Localization.str2numNonFixed(exp.dosePerHectare ?? 0, locale),
      notes: exp.note,
    },
  }));
  return mapFertilizers || [];
};

export const mapRequestBodyEphAccountableNitrogenTo = (
  parcels: ExtInitialParcelToAdd[],
  fertilizer: EphFertilizersType[],
  isStrawDecay: boolean,
  locale: LANGUAGE_ID,
  ephTargetSeedApplication: TargetSeedApplicationDetailTo[],
) => {
  const fertilizerExpenses = fertilizer.map((exp) => ({
    dosePerHa: Localization.str2numNonFixed(
      exp.dosePerHectare ?? 0,
      locale,
    ) as number,
    unit: typeof exp.doseUnit === "object" ? exp.doseUnit.id : exp.doseUnit,
    fertilizerId: exp.id,
  }));

  const parcelFertilizerExpenses = parcels
    .map((parcel) => {
      const targetSeedForParcel = ephTargetSeedApplication.find(
        (item) => item.parcelId === parcel.id,
      );

      if (!targetSeedForParcel) {
        return null;
      }

      return {
        parcelId: parcel.id,
        actionArea: parcel.actionParcelTotalArea,
        targetSeedAppSownArea: targetSeedForParcel.seedApplication.sownArea,
        isStrawDecay,
        fertilizerExpenses,
      };
    })
    .filter((item) => item !== null);

  return { parcelFertilizerExpenses };
};
// ==================================================================
// ============================ HELPERS  ============================
// ==================================================================

export const checkIsAllowedForCrop = ({
  activePestId,
  applications,
  targetCropId,
}: {
  applications: PlantProtectionApplicationTo[];
  targetCropId: string;
  activePestId: string;
}) =>
  applications.some(
    (application) =>
      application.crop?.id === targetCropId &&
      application.organism?.id === activePestId,
  );

export const mapActivePest = ({
  activeSubstance,
  applications,
  targetCropId,
}: {
  activeSubstance: ActiveSubstanceTo;
  applications: PlantProtectionApplicationTo[];
  targetCropId: string;
}): MappedActivePest => {
  const currentApplication = applications.find(
    (application) =>
      application.crop?.id === targetCropId &&
      application.organism?.id === activeSubstance.id,
  );

  return {
    id: activeSubstance.id,
    organism: activeSubstance,
    name: activeSubstance.name,
    buffer: currentApplication?.buffers ?? [],
    minDose: currentApplication?.minDose,
    maxDose: currentApplication?.maxDose,
    recommendedDoseUnit: currentApplication?.unit,
    allowedApplications: currentApplication?.allowedApplications,
    minInterval: currentApplication?.minInterval,
    maxInterval: currentApplication?.maxInterval,
    isAllowedForCrop: !!currentApplication,
  };
};

export const filterBufferByDriftClass = (
  driftClass: number,
  buffers: PlantProtectionRegistrationApplicationBufferTo[],
) => {
  const buffersByDriftClass = buffers.filter(
    (buffer) => buffer.driftReduction === driftClass,
  );

  return buffersByDriftClass;
};

export const driftClassValue = {
  DRIFT_NONE: 0,
  DRIFT_50: 50,
  DRIFT_75: 75,
  DRIFT_90: 90,
};

export const createSoilIncorporationDate = ({
  actionDate,
  option,
}: {
  option: "NO" | "SAME_DAY" | "NEXT_DAY";
  actionDate: Moment;
}) => {
  switch (option) {
    case "SAME_DAY":
      return getShortDateString(actionDate);
    case "NEXT_DAY":
      return getShortDateString(actionDate.clone().add(1, "days"));
    default:
      return null;
  }
};

// Parses application data for plant protection actions and organizes it by parcel and plant protection IDs.
export const parsePlantProtectionApplicationData = (
  seedApplications: TargetSeedApplicationDetailTo[],
  actionDate: string,
) => {
  const selectedDate = moment(actionDate);

  // Helper function to calculate the difference in days between two dates.
  function differenceInDays(date1: Moment, date2: Moment): number {
    return Math.abs(date1.diff(date2, "days"));
  }

  const results: PlantProtectionApplicationDataByParcelsType = {};

  seedApplications?.forEach((sa) => {
    const parcelId = sa.parcelId;

    sa.actionsByPlantProtection.forEach((plantProtection) => {
      const plantProtectionId = plantProtection.plantProtectionId;

      plantProtection.actions.forEach((action) => {
        const previousActionDate = moment(action.date);

        if (!results[parcelId]) {
          results[parcelId] = {};
        }

        if (!results[parcelId][plantProtectionId]) {
          results[parcelId][plantProtectionId] = {
            numberApplications: 0,
            previousDate: "",
            previousDateDiff: 0,
            name: action.plantProtection.name,
          };
        }

        // Increment the number of applications for the given plant protection.
        results[parcelId][plantProtectionId].numberApplications += 1;

        const dateDiff = differenceInDays(selectedDate, previousActionDate);

        // Update the previous application date and date difference
        // if no previous date is set or if the new date difference is smaller.
        if (
          !results[parcelId][plantProtectionId].previousDate ||
          dateDiff < results[parcelId][plantProtectionId].previousDateDiff
        ) {
          results[parcelId][plantProtectionId].previousDate = action.date;
          results[parcelId][plantProtectionId].previousDateDiff = dateDiff;
        }
      });
    });
  });
  return results;
};

// Parses plant protection application restrictions
export const parsePlantProtectionsApplicationRestriction = (
  data: EphPlantProtectionsType[],
) => {
  const result: PlantProtectionApplicationRestrictionType = {};

  data.forEach((item) => {
    const plantProtectionId = item.id;
    // filters pests allowed for crops
    const allowedPests = item.pests?.filter(
      (pest: MappedActivePest) => pest.isAllowedForCrop,
    );

    if (allowedPests?.length > 0) {
      // Find the minimum number of allowed applications among the allowed pests, excluding undefined values
      const allowedApplicationsArray = allowedPests
        .map((pest) => pest.allowedApplications)
        .filter(
          (application): application is number => application !== undefined,
        );

      const minAllowedApplications =
        allowedApplicationsArray.length > 0
          ? Math.min(...allowedApplicationsArray)
          : undefined;

      // Find the minimum interval among allowed pests, excluding undefined values
      const allowedIntervals = allowedPests
        .map((pest) => pest.minInterval)
        .filter((interval): interval is number => interval !== undefined);

      const minInterval =
        allowedIntervals.length > 0 ? Math.min(...allowedIntervals) : undefined;

      result[plantProtectionId] = {
        minInterval,
        allowedApplications: minAllowedApplications,
      };
    }
  });

  return result;
};

// Checks compliance of application data against application restrictions,
// identifying warnings for application intervals and counts.
export const analyzePlantProtectionApplication = (
  applicationData: PlantProtectionApplicationDataType,
  applicationRestrictions: PlantProtectionApplicationRestrictionType,
) => {
  const results: PlantProtectionRestrictionWarningsType[] = [];

  // Iterate over each plant protection application data entry.
  Object.entries(applicationData).forEach(([id, appData]) => {
    const restriction = applicationRestrictions[id];
    if (restriction) {
      const { allowedApplications, minInterval } = restriction;
      const { name, numberApplications, previousDate, previousDateDiff } =
        appData;

      const isDatePreviousApplicationWarning = minInterval
        ? previousDateDiff <= minInterval
        : false;

      let isNumberOfApplicationsWarning = false;
      if (allowedApplications) {
        // check if the number of applications has not exceeded the allowed number
        isNumberOfApplicationsWarning =
          numberApplications + 1 > allowedApplications;
      }

      // Determine if there are warnings based on the interval and number of applications.
      results.push({
        isDatePreviousApplicationWarning,
        isNumberOfApplicationsWarning,
        previousDate: getLocalizedDateString(previousDate),
        allowedApplications,
        minInterval,
        name,
      });
    }
  });

  return results;
};

export const analyzLegislationCheckByParcels = (
  targetSeedApplication: TargetSeedApplicationDetailTo[],
  parcelsInForm: InitialParcelToAdd[],
) => {
  const result: Record<string, boolean> = {};

  parcelsInForm.forEach((item) => {
    const existsInParcelsInForm = targetSeedApplication.some(
      (sa) => sa.parcelId === item.id,
    );
    result[item.id] = existsInParcelsInForm;
  });

  return result;
};

export const isCropInApplications = ({
  applications,
  cropId,
}: {
  applications?: FormPlantProtectionApplications;
  cropId: string;
}) => {
  if (!cropId || !applications) {
    return false;
  }

  return applications.applications.some(
    (application) => application.crop?.id === cropId,
  );
};
