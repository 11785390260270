import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useFarmIds } from "../../../shared/api/client.utils";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { productionOperationsQuery } from "../TelematicsAdvancedFilter/ProductionOperationSelector/ProductionOperationSelector.api";

import { ProductionOperationTo } from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface Props {
  customClasses?: Record<string, string>;
  defaultValue?: ProductionOperationTo;
  disabled?: boolean;
  hasError: boolean;
  handwork?: boolean;
  helperText: React.ReactElement | string;
  label: ReactNode;
  onChange: (value: ProductionOperationTo) => void;
  placeholder?: string;
}

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const ProductionOperationSelector = ({
  customClasses,
  defaultValue,
  disabled,
  handwork,
  hasError,
  helperText,
  label,
  onChange,
  placeholder,
}: Props) => {
  const classes = useStyles();
  const operations = useSuspenseQuery(
    productionOperationsQuery({
      ...useFarmIds(),
      handwork,
    }),
  );

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={defaultValue}
      disabled={disabled}
      error={hasError}
      filterOptions={handleFilterOptions}
      helperText={helperText}
      id="telematics-dialog-operation-selector"
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      suggestions={operations.data.data}
      testId="telematics-dialog-operation-selector"
      renderOption={(option) => (
        <div>
          <div>{option.name}</div>
          <div className={classes.id}>{option.code}</div>
        </div>
      )}
    />
  );
};

export { ProductionOperationSelector };
