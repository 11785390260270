import ErrorService from "../../../../services/Error.service";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { StoresState } from "../../../../../reducers/stores.reducer.types";

export const getFertilizers = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.fertilizers.items;
export const getIsFetchingFertilizers = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.fertilizers.isFetching;
export const getFertilizersCount = (state: CataloguesState) =>
  state.api.agroevidence.fertilizers.totalCount;
export const getFertilizersError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.agroevidence.fertilizers.error);

export const getFertilizer = (state: CataloguesState) =>
  state.api.agroevidence.fertilizers.item;

export const getFertilizerDose = (state: ActionsState) =>
  state.api.agroevidence.fertilizers.dose;
export const getIsFetchingFertilizerDose = (state: ActionsState) =>
  state.api.agroevidence.fertilizers.isFetchingDose;
