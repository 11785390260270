import angular from "angular";

import TelematicsController from "./telematics.controller";

import { PositionMachineTo } from "../shared/api/telematics/telematics.types";

type StateProvider = {
  state: (arg0: string, arg1: Record<string, unknown>) => StateProvider;
};

type StateParams = {
  dateFrom: string;
  dateTo: string;
  machine: PositionMachineTo;
};

const telematicsComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
    dateFrom: "<",
    dateTo: "<",
    machine: "<",
  },
  controller: TelematicsController,
};

export default angular
  .module("app.telematics", [])
  .config(config)
  .component("telematicsComponent", telematicsComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider: StateProvider) {
  $stateProvider.state("farm.active.telematics", {
    url: "/telematics{path:any}",
    views: {
      farm: {
        component: "telematicsComponent",
      },
    },
    params: {
      dateFrom: null,
      dateTo: null,
      machine: null,
    },
    resolve: {
      dateFrom: [
        "$stateParams",
        function ($stateParams: StateParams) {
          return $stateParams.dateFrom;
        },
      ],
      dateTo: [
        "$stateParams",
        function ($stateParams: StateParams) {
          return $stateParams.dateTo;
        },
      ],
      machine: [
        "$stateParams",
        function ($stateParams: StateParams) {
          return $stateParams?.machine;
        },
      ],
    },
  });
}
