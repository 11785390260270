import React from "react";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { State } from "../../../../../generated/api/telematics";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import { usePostponeDriveMutation } from "../../Drivers.api";

type Props = {
  drive: {
    driveId: number;
    state: State;
  };
  state: "postponed" | "postponable";
};

const PostponedIconButton = ({ drive, state }: Props) => {
  const classes = useStyles();
  const postponeDriveMutation = usePostponeDriveMutation();

  const handleEventPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const handlePostponeClick = () => {
    postponeDriveMutation.mutate({
      state: drive.state,
      drive: [drive.driveId],
    });
  };

  if (postponeDriveMutation.status === "pending") {
    return (
      <div onClick={handleEventPropagation}>
        <CfLoader
          classes={{ wrapper: classes.wrapper }}
          color="inherit"
          size={20}
        />
      </div>
    );
  }

  return (
    <div onClick={handleEventPropagation}>
      {state === "postponed" || state === "postponable" ? (
        <Tooltip title={<FormattedMessage id={getLabelFromState(state)} />}>
          <span>
            <IconButton
              aria-label="Postponed ride"
              color={state === "postponed" ? "primary" : undefined}
              onClick={handlePostponeClick}
              size="large"
            >
              {state === "postponed" ? (
                <BookmarkIcon />
              ) : (
                <BookmarkBorderOutlinedIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      ) : null}
    </div>
  );
};

const getLabelFromState = (state: Exclude<Props["state"], "pending">) => {
  switch (state) {
    case "postponable":
      return "TelematicsAggregations.postponed.ride.acceptTooltip";
    case "postponed":
      return "TelematicsAggregations.postponed.ride.cancelTooltip";
    default:
      throw new Error(`Unexpected state: ${state}`);
  }
};

const getState = ({ postponed }: { postponed?: boolean }): Props["state"] => {
  if (postponed) {
    return "postponed";
  }

  return "postponable";
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "14px",
  },
}));

export { getState, PostponedIconButton };
