import React, { Suspense, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";

import { LANG_KEYS } from "../../../shared/lang/lang.constants";

import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../shared/containers/BaseContainer/BaseContainer";
import { AnyTodo } from "../../../types";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsPL from "../../lang/locale-pl-PL.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import { Telematics } from "../Telematics/Telematics";

import { MachineTo } from "../../../shared/api/telematics/telematics.types";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

const TelematicsWrapper = (props: AnyTodo) => {
  const { initMachineFilter: ngInitMachineFilter, ...remainingProps } = props;
  const [initMachineFilter, setInitMachineFilter] = useState<
    MachineTo | undefined
  >(ngInitMachineFilter);

  return (
    <BaseContainer langId={props.langId} translations={translations}>
      <ErrorBoundary
        fallbackRender={({ error }) => <CfErrorPage error={error} />}
      >
        <Suspense fallback={<CfLoader />}>
          <Telematics
            initMachineFilter={initMachineFilter}
            setInitMachineFilter={setInitMachineFilter}
            {...remainingProps}
          />
        </Suspense>
      </ErrorBoundary>
    </BaseContainer>
  );
};

export { TelematicsWrapper };
