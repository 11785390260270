import { Dispatch } from "redux";

import { setTablePage } from "../../shared/actions/table.actions";

import { NAMESPACE as aggregationsNamespace } from "../reducer/telematicsAggregations.reducer";
import { NAMESPACE as listNamespace } from "../reducer/telematicsList.reducer";
import { NAMESPACE as machinesNamespace } from "../reducer/telematicsMachines.reducer";
import { Action } from "../reducer/telematicsTabs.reducer";

import { Tab } from "../containers/TelematicsTabs/TelematicsTabs";
import { saveToCache } from "../containers/TelematicsTabs/useTelematicsLogger";

const setVisitedTab = (tab: Tab): Action => ({
  payload: tab,
  type: "TELEMATICS_TABS/SET_VISITED_TAB",
});

// TODO setTable and setDateRange should be part of the same state
const setDateRange =
  (dateFrom: string, dateTo: string) => (dispatch: Dispatch) => {
    saveToCache("dateFilter", { dateFrom, dateTo });
    dispatch({ type: "TELEMATICS_TABS/SET_DATE_RANGE", dateFrom, dateTo });
    dispatch(setTablePage(0, aggregationsNamespace));
    dispatch(setTablePage(0, listNamespace));
    dispatch(setTablePage(0, machinesNamespace));
  };

export { setDateRange, setVisitedTab };
