import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import { getApiError } from "../../../shared/api/telematics/machines/machines.selectors";
import { getTelematicsMachinesTextFilter } from "../../selectors/telematicsMachines.selectors";

import { NAMESPACE } from "../../reducer/telematicsMachines.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import { TelematicsNgProps } from "../../containers/Telematics/Telematics";

import { Table } from "./components/Table/Table";

const Machines = ({ ngRedirectToMainMapWithFilters }: TelematicsNgProps) => {
  const { onChange } = useCfTextFilter(NAMESPACE);
  const textFilter = useSelector(getTelematicsMachinesTextFilter);
  const apiError = useSelector(getApiError);

  const classes = useStyles();

  return (
    <CfErrorPage error={apiError}>
      <div className={classes.wrapper}>
        <div className={classes.filters}>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              onChange={onChange}
              translId="TelematicsMachines.textFilterPlaceholder"
            />
          </div>
        </div>
        <Table
          ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
        />
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  filters: {
    display: "flex",
    height: 40,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto auto",
      gridTemplateRows: "40px",
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . toggle'
      `,
      height: "auto",
    },
  },
  date: {
    gridArea: "date",
    justifySelf: "flex-start",
    alignSelf: "end",
  },
  textFilter: {
    gridArea: "text-filter",
    justifySelf: "center",
    maxWidth: 400,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      maxWidth: "unset",
      marginLeft: 0,
    },
  },
  toggle: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    gridArea: "toggle",
    justifySelf: "flex-end",
  },
  switch: {
    marginLeft: "auto",
  },
  switchLabel: {
    fontSize: 14,
  },
}));

export { Machines };
