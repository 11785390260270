import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import moment from "moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { updateDriver1 } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { stringDateToMoment } from "../../../../shared/misc/timeHelpers";
import { CfFormikErrors } from "../../../../types";
import { DriverField } from "../../../components/TelematicsAggregationDetailContent/formComponents";
import { MESSAGES } from "../../../components/TelematicsAggregationDetailContent/validators";
import { useCustomStyles } from "../components/styles/useCustomStyles";
import { telematicsDriversKey } from "../Drivers.api";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import { DriverTo } from "../../../../shared/api/telematics/telematics.types";

const useDriver = () => {
  const intl = useIntl();
  const customClasses = useCustomStyles();
  const updateDriverMutation = useBatchDriverMutation();
  const [selectedDriver, setSelectedDriver] = React.useState<
    DriverTo | null | undefined
  >(undefined);

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );
  const dateFrom = useSelector(
    (state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom,
  );
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers =
      dateFromMoment.toISOString() ?? moment().startOf("day").toISOString();
  }

  const initialValues = {
    driverCode: "",
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.driverCode) {
      errors.driverCode = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = () => {
    const payload = {
      drive: selectedRides.map((ride) => parseInt(ride, 10)),
      driver: selectedDriver?.id ?? -1,
    };
    // TODO use sync version of mutate when all the hooks are refactored
    return updateDriverMutation.mutateAsync(payload);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <DriverField
      customClasses={customClasses}
      date={dateForClassifiers}
      disabled={formikProps.isSubmitting}
      setSelectedDriver={setSelectedDriver}
      placeholder={intl.formatMessage({
        id: "TelematicsAggregations.list.bulk.secondary.placeholder.driver",
      })}
      {...formikProps}
    />
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

const useBatchDriverMutation = () => {
  const queryClient = useQueryClient();
  const { farmIds } = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateDriver1>[0]) =>
      updateDriver1(payload, {
        farmIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export { useDriver };
