import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import analyticsDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/analytics_disabled.svg";
import analyticsIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/analytics.svg";
import requestQuoteDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/request-quote_disabled.svg";
import requestQuoteIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/request_quote.svg";
import {
  DashboardParcelTo,
  PrecisionFarmingProduct,
} from "../../../../../generated/api/satellite";
import { useFarmIds } from "../../../../../shared/api/client.utils";

type Props = {
  products: PrecisionFarmingProduct[];
  data: DashboardParcelTo;
};

const SeasonCellIconBar = ({ data, products }: Props) => {
  const classes = useStyles();
  const disabledColor = "#F6F6F6";
  const yieldIsActive = !!data.yieldInTonnes;

  const monitoringIsActive =
    products.includes("PLANET_BIOMONITORING_SAVI") ||
    products.includes("GISAT_BIOPHYSICAL_MONITORING");

  const { farmIds } = useFarmIds();
  const rootUrl = `/farm/${farmIds}/precision-farming/services/${data.externalParcelId}`;

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.monitoring" />}
      >
        {monitoringIsActive ? (
          <Link
            className={classes.icon}
            to={`${rootUrl}/bio-monitoring`}
            style={{
              background: "#CCE7F5",
            }}
          >
            <img alt="analytics" src={analyticsIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="analytics_disabled" src={analyticsDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.yieldMaps" />}
      >
        {yieldIsActive ? (
          <Link
            className={classes.icon}
            to={`${rootUrl}/todo-page-doesnt-exist-yet`}
            style={{
              background: "#FFE1FC",
            }}
          >
            <img alt="request-quote" src={requestQuoteIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="request-quote-disabled" src={requestQuoteDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 24,
    width: 24,
    borderRadius: "100%",
  },
}));

export { SeasonCellIconBar };
