import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import { getTelematicsAggregationsSelectedRides } from "../../../selectors/telematicsAggregations.selectors";

import { BulkMainSelector } from "./BulkMainSelector";
import { SecondarySelector } from "./SecondarySelector";

const useStyles = makeStyles(() => ({
  filters: {
    display: "flex",
    alignItems: "flex-start",
    minHeight: 68,
  },
}));

const TableBulkFilters = () => {
  const classes = useStyles();
  const selectedRecords = useSelector(getTelematicsAggregationsSelectedRides);

  const [mainSelected, setMainSelected] = useState<number>(0);

  return (
    <div className={classes.filters}>
      <BulkMainSelector
        selected={mainSelected}
        selectedRecords={selectedRecords}
        setMainSelected={setMainSelected}
      />
      <SecondarySelector mainSelected={mainSelected} />
    </div>
  );
};

export { TableBulkFilters };
