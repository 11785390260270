import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsZonesFetching,
  getZones,
} from "../../../../../../shared/api/agroevidence/zones/zones.selectors";

import { assignZones } from "../../../../shared/actions/parcels.actions";

import {
  ParcelDetailTo,
  ZoneTo,
} from "../../../../../../generated/api/agroevidence";
import { getParcelApi } from "../../../../../../shared/api/agroevidence/parcels/parcels.api";
import { getZonesApi } from "../../../../../../shared/api/agroevidence/zones/zones.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../../types";

import { ParcelsState } from "../../../../../../reducers/parcels.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type Props = {
  isZoneFetching: boolean;
  onClose: () => void;
  open: boolean;
  parcel: ParcelDetailTo;
  suggestions: ZoneTo[];
};

const EditParcelZonesModal = ({
  assignZones,
  getParcelApi,
  getZonesApi,
  isZoneFetching,
  onClose,
  open,
  parcel,
  suggestions,
}: Props & ReduxProps) => {
  const intl = useIntl();

  const [zones, setZones] = useState(parcel.zones ?? []);

  const extendedSuggestions = useMemo(() => [...suggestions], [suggestions]);

  const handleChange = (selectedZones: ZoneTo[]) => {
    setZones(selectedZones);
  };

  const handleParcelZonesAssign = async () => {
    const zoneIds = zones.map((zone) => zone.id);
    await assignZones(zoneIds, parcel.id);
    await getParcelApi(parcel.id);
    onClose();
  };

  useEffect(() => {
    getZonesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id="ParcelDetailOverview.assignZonesModal.title" />
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <CfAutocomplete
          defaultValues={zones}
          id="zone-selector"
          isFetching={isZoneFetching}
          isMultiple={true}
          loadOptions={getZonesApi}
          onChange={handleChange}
          suggestions={extendedSuggestions}
          testId="zone-filter"
          label={intl.formatMessage({
            id: "ParcelDetailOverview.assignZonesSuggest.label",
          })}
        />
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button id="reset" onClick={onClose} type="reset" variant="text">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            color="primary"
            id="send"
            onClick={handleParcelZonesAssign}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.save" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getZones(state),
  isZoneFetching: getIsZonesFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getZonesApi,
      assignZones,
      getParcelApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EditParcelZonesModal);
