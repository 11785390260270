import React from "react";

import { FormattedMessage, useIntl } from "react-intl";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

export type TransactionType = {
  id: string;
  name: string;
  sign: string;
};

interface Props {
  defaultValues: TransactionType;
  onChange: (transactionType: TransactionType) => void;
  transactionTypes: TransactionType[];
}

const StoreTransactionTypeSelector = ({
  defaultValues,
  onChange,
  transactionTypes,
}: Props) => {
  const intl = useIntl();

  const handleRenderLabelAndOption = (option: TransactionType) =>
    intl.formatMessage({ id: option.name });

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disableClearable
      getLabel={handleRenderLabelAndOption}
      groupBy={(option) => option.sign}
      id="zone-selector"
      label={<FormattedMessage id="Stores.transaction-type" />}
      onChange={onChange}
      renderOption={handleRenderLabelAndOption}
      suggestions={transactionTypes}
      testId="transactionType-selector"
    />
  );
};

export { StoreTransactionTypeSelector };
