import React, { CSSProperties } from "react";

import { Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";

interface Props {
  columns: Record<
    string,
    {
      align?: TableCellProps["align"];
      hideOnMobile?: boolean;
      label?: (() => React.ReactNode) | React.ReactNode;
      rightPadding?: boolean;
      sortable?: boolean;
      style?: React.CSSProperties;
      superRightPadding?: boolean;
    }
  >;
  heightDiff?: string;
  top?: string;
  style?: CSSProperties;
}

const CfTableBodyStaticLoader = ({
  columns,
  heightDiff,
  style: propStyle,
  top,
}: Props) => {
  const classes = useStyles();
  const style = {
    top: top || "57px",
    height: `calc(100% - ${heightDiff || "114px"}`,
  };
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={Object.keys(columns).length} style={{ padding: 0 }}>
          <div className={classes.spinner} style={propStyle ?? style}>
            <CircularProgress className={classes.circularProgress} />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100% - 114px)",
    borderRadius: "2px",
    backgroundColor: theme.palette.common.white,
  },
  circularProgress: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export { CfTableBodyStaticLoader };
